<template>
  <div ref="target" class="burger-menu">
    <div
      class="burger-menu__icon"
      :class="{ closed }"
      @click="
        () => {
          $tours['mobile-tour']?.isRunning && toggleDropdown(true);
          $tours['mobile-tour']?.nextStep(true);
        }
      "
    >
      <icon
        v-if="closed && !isMobile"
        name="burgerref"
        width="25"
        height="26"
        :stroke-color="'currentColor'"
        @click="() => toggleDropdown(true)"
      />
      <icon
        v-else-if="closed && isMobile"
        name="burgerref"
        width="25"
        height="26"
        :stroke-color="'currentColor'"
        @click="
          () => {
            tourNextButtonClick();
            toggleDropdown(true);
          }
        "
      />
      <icon
        v-else
        name="ico_cross_burg"
        width="20"
        height="20"
        @click="() => toggleDropdown(false)"
        class="cross-burg"
      />
    </div>
    <transition name="burger-menu__dropdown">
      <div
        v-if="!closed"
        ref="dropdown"
        class="burger-menu__dropdown box"
        :class="{
          'burger-menu__dropdown--mobile': mobile,
          isFiorinNullableBalance,
        }"
      >
        <div
          class="burger-menu__item"
          :class="{ 'burger-menu__item--disabled': disabledItem(item, index) }"
          v-for="(item, index) in items"
          :key="index"
        >
          <MenuItem
            class="menu-item--burger"
            :title="item.title"
            :value="item.value"
            :translate="item.translate"
            :action="item.action"
            :nested-items="item.nestedItems"
            @close-menu="toggleDropdown(false)"
            @toggle-nested="onToggleNested(index, $event)"
            @close-nested="onCloseNested"
            :key="index"
            :expanded="expandedNested === index"
            :disabled="disabledItem(item, index)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import MenuItem from '@/components/ui/MenuItem.vue';

import { computed, defineComponent, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { scope } from '@/breakpoints';
import { useStore } from 'vuex';

import '@/assets/icons/burgerref';
import '@/assets/icons/ico_burger';
import '@/assets/icons/ico_cross_burg';
import { useScope } from '@/compositions/useScope';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';

export default defineComponent({
  name: 'Burger',
  components: { MenuItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    closed: {
      type: Boolean,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle-dropdown'],
  setup(props, { emit }) {
    const store = useStore();
    const { isNotDesktop } = useScope();
    const { isFiorinNullableBalance } = useFiorinBalanceNull();

    const target = ref(null);
    const dropdown = ref(null);
    const expandedNested = ref(-1);

    const disabledItem = computed(() => (item, index) => {
      return (
        item.disabled ||
        (expandedNested.value >= 0 && expandedNested.value !== index)
      );
    });

    const isMobile = computed(
      () => scope.isSmall || scope.isMedium || scope.noMatch
    );

    onClickOutside(target, () => toggleDropdown(false));

    const toggleDropdown = (value) => {
      emit('toggle-dropdown', !value);

      expandedNested.value = -1;
    };
    const onToggleNested = (index, itemNode) => {
      if (index === expandedNested.value) {
        expandedNested.value = -1;
      } else {
        expandedNested.value = index;
        if (isNotDesktop.value) {
          autoScrollOnOpenNested(itemNode);
        }
      }
    };
    const autoScrollOnOpenNested = (openedItem) => {
      setTimeout(() => {
        if (!dropdown.value || !openedItem) {
          return;
        }
        if (dropdown.value.clientHeight >= dropdown.value.scrollHeight) {
          return;
        }

        const heightFromStartToEndOpenedItem =
          openedItem.offsetTop + openedItem.clientHeight;

        if (
          dropdown.value.clientHeight + dropdown.value.scrollTop >=
          heightFromStartToEndOpenedItem
        ) {
          return;
        }

        dropdown.value.scroll({
          top: openedItem.offsetTop,
          behavior: 'smooth',
        });
      }, 500);
    };
    const onCloseNested = () => (expandedNested.value = -1);

    const tourNextButtonClick = () => {
      const tourNextBtn = document.querySelector('.v-step__button-next');
      tourNextBtn && tourNextBtn.click();
    };

    watch(
      () => props.closed,
      (val) => {
        store.commit('burger/SET_BURGER_STATUS', !val);
      }
    );

    return {
      isMobile,
      target,
      expandedNested,
      dropdown,
      onToggleNested,
      onCloseNested,
      disabledItem,
      toggleDropdown,
      tourNextButtonClick,
      isFiorinNullableBalance,
    };
  },
});
</script>
<style lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.burger-menu {
  user-select: none;
  // position: relative;

  .cross-burg {
    margin-right: 5px;
  }

  &__icon {
    position: absolute;
    top: 0px;
    right: 0;
    cursor: pointer;

    &.closed {
      top: -4px;
    }

    @media screen and (max-width: 1024px) {
      top: 18px;
      right: 20px;

      &.closed {
        top: 18px;
      }
    }

    svg {
      g {
        stroke: none;
      }
    }
  }

  // &__icon {
  //   svg {
  //     g {
  //       stroke: none;
  //     }
  //   }
  //   // width: 24px;
  //   // height: 19px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   cursor: pointer;
  //   pointer-events: all !important;

  //   @media screen and (max-width: 1024px) {
  //     height: 24px;

  //     [aria-labelledby='burger'] {
  //       path {
  //         stroke-width: 4px;
  //       }
  //     }
  //   }
  // }

  &__dropdown {
    width: max-content;
    min-width: 256px;
    right: -10px;
    top: 15px;
    position: absolute;
    z-index: 20;
    display: flex;
    flex-direction: column;
    margin-top: 20px; //15
    align-items: baseline;
    border-width: 1px;

    @media screen and (max-width: 1024px) {
      margin-top: -23px;
    }

    &--mobile {
      right: 0;
      width: 100%;
      max-height: calc(100vh - 124px);
      height: auto;
      overflow-y: auto;

      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 100vh;
        background: transparent;
      }

      &.isFiorinNullableBalance {
        max-height: calc(100vh - 172px);
      }
    }

    @media screen and (max-width: 1024px) {
      border-width: 0px;
      top: unset;
    }
    @media screen and (min-width: 1024px) {
      max-width: 276px;
      width: 276px;
    }
  }
  &__item {
    flex-shrink: 1;
    border-bottom-width: 1px;
    &:last-of-type {
      border-bottom-width: 0px;

      @media screen and (max-width: 1024px) {
        border-bottom-width: 1px;
      }
    }
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-around;
    &--disabled {
      cursor: initial;
    }
  }
}
.burger-menu__dropdown-enter-from,
.burger-menu__dropdown-leave-to {
  right: -375px;
}
.burger-menu__dropdown-enter-active,
.burger-menu__dropdown-leave-active {
  transition: right 0.25s ease-in-out;
}
</style>
<style lang="scss" scoped>
.extend {
  padding: 0 8px;
}
</style>
