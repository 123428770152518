<template>
  <div class="app-menu">
    <img
      v-if="!hideLogo && isMobile"
      :src="logo"
      @mouseup="openLanding"
      class="land"
    />
    <!-- <div v-if="!activeConnect.provider" class="app-menu__getapp" @click="login">
      GET APP
    </div> -->
    <div
      v-if="
        rendredTopMenu &&
        ((!activeConnect.provider && !isMobile) ||
          (!isMobile && isFiorinNullableBalance))
      "
      :class="{
        demo:
          !activeConnect.accessToken ||
          disableBountyProgram ||
          uiSettings.turnBountyPoints,
      }"
      class="app-menu__demo"
      @click="login"
    >
      <TabButton
        long
        reverse-color
        :title="isFiorinNullableBalance ? 'ADD FUNDS' : 'CONNECT WALLET'"
      />
    </div>
    <Burger
      v-if="!showLogoOnly && !activeConnect.accessToken"
      :items="menu"
      :mobile="mobile"
      :closed="closed"
      @toggle-dropdown="(val) => toggleDropdown(val)"
    />
    <template v-if="activeConnect.accessToken">
      <div
        v-if="
          rendredTopMenu &&
          !uiSettings.turnBountyPoints &&
          !hideLogo &&
          !isMobile &&
          !disableBountyProgram &&
          (notStartProgram ||
            startProgramAndNullBalance ||
            noStartProgramAndBountyBalance ||
            startProgramAndMainBalance)
        "
        class="bounty-points-wrap"
      >
        <BountyPoints />
      </div>
      <img
        v-if="
          !isMobile &&
          !(
            (!activeConnect.provider && !isMobile) ||
            (!isMobile && isFiorinNullableBalance)
          ) &&
          !(
            !uiSettings.turnBountyPoints &&
            !hideLogo &&
            !isMobile &&
            !disableBountyProgram &&
            (notStartProgram ||
              startProgramAndNullBalance ||
              noStartProgramAndBountyBalance ||
              startProgramAndMainBalance)
          )
        "
        :src="logo"
        @mouseup="openLanding"
        class="land"
      />
      <Burger
        v-if="!showLogoOnly"
        :items="menu"
        :mobile="mobile"
        :closed="closed"
        @toggle-dropdown="(val) => toggleDropdown(val)"
      />
    </template>
    <HelloDXS
      v-if="modalStatus(modalsByName.preLogin)"
      @close="hideModal(modalsByName.preLogin)"
    />

    <CookiesPolicy
      v-if="modalStatus(modalsByName.cookiesPolicy)"
      @close="hideModal(modalsByName.cookiesPolicy)"
    />
    <UpdateMarkets
      v-if="modalStatus('UpdateMarkets')"
      @close="hideModal('UpdateMarkets')"
    />
    <KYCPolicy
      v-if="modalStatus(modalsByName.KYC)"
      @close="hideModal(modalsByName.KYC)"
    />
    <WhatUsersSay
      v-if="modalStatus(modalsByName.reviews)"
      @close="hideModal(modalsByName.reviews)"
    />

    <Modal
      v-if="modalStatus(modalsByName.logout)"
      @close="hideModal(modalsByName.logout)"
    >
      <Logout @close="emit('close')" />
    </Modal>
    <!-- <Modal
      v-if="modalStatus(modalsByName.landing)"
      :modal-class="'landing-modal'"
      :width="landingWidth"
      @close="hideModal(modalsByName.landing)"
    >
      <Landing @close="hideModal(modalsByName.landing)" />
    </Modal> -->
    <Announcements
      v-if="modalStatus(modalsByName.announcements)"
      @close="hideModal(modalsByName.announcements)"
    />
    <!-- <BountyWelcome
      v-if="modalStatus(modalsByName.bountyTrading)"
      @close="hideModal(modalsByName.bountyTrading)"
    /> -->
    <ProfitRecovery
      v-if="modalStatus(modalsByName.profitRecovery)"
      @close="hideModal(modalsByName.profitRecovery)"
    />
    <AccountDeletion
      v-if="modalStatus(modalsByName.accountDeletion)"
      @close="hideModal(modalsByName.accountDeletion)"
    />
    <app-overlay
      :is-visible="!closed && mobile"
      class="app-menu__overlay"
      bottom="-50px"
      top="54px"
      max-height="calc(100% - 54px)"
    ></app-overlay>
  </div>
</template>
<script>
// vue
import { defineComponent, computed, ref, onMounted } from 'vue';
import Burger from '@/components/ui/Burger.vue';
import logoWhite from '@/assets/dxs_lg_wht.png';
import logoBlack from '@/assets/dxs_lg_blk.png';
// Login
import Modal from '@/modules/modals/Modal.vue';
import HelloDXS from '@/modules/user/HelloDXS.vue';
import Logout from '@/modules/user/Logout.vue';
// import Landing from '@/components/modals/Landing';

import CookiesPolicy from '@/components/modals/CookiesPolicy';
import KYCPolicy from '@/components/modals/KYCPolicy';
import WhatUsersSay from '@/components/modals/WhatUsersSay';

import UpdateMarkets from '@/components/modals/UpdateMarkets';

import Announcements from '@/components/modals/Announcements.vue';
// import BountyWelcome from '@/components/modals/BountyWelcome';
import ProfitRecovery from '@/components/modals/ProfitRecovery';
import AccountDeletion from '@/components/modals/AccountDeletion';

import AppOverlay from '@/components/ui/Overlay';

import { scope } from '@/breakpoints';
// store
import { useStore } from 'vuex';

// route
import { useRoute } from 'vue-router';

// api
import { useModals } from '@/modules/modals/api';
import { useBountyPrograms } from '@/compositions/useBountyPrograms';

import { TABS_ROUTE_NAMES } from '@/helpers/enums';
import { useScope } from '@/compositions/useScope';
import useTheme, { Theme } from '@/compositions/useTheme';
import TabButton from '@/components/ui/TabButton.vue';
import { openLandingDXS } from '@/helpers/landing';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';
import BountyPoints from '@/components/BountyPoints';

export default defineComponent({
  name: 'MainBurgerMenu',
  components: {
    Burger,
    Modal,
    HelloDXS,
    Logout,
    Announcements,
    BountyPoints,
    // BountyWelcome,
    ProfitRecovery,
    AccountDeletion,
    CookiesPolicy,
    KYCPolicy,
    WhatUsersSay,
    UpdateMarkets,
    AppOverlay,
    TabButton,

    // Landing,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    showLogoOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const { isFiorinNullableBalance } = useFiorinBalanceNull();
    const closed = ref(true);
    const store = useStore();
    const route = useRoute();
    const { storeTheme } = useTheme();
    const { isNotDesktop } = useScope();
    const rendredTopMenu = ref(false);

    const userSettings = computed(() => store.getters['settings/settings']);
    const disableBountyProgram = computed(
      () => store.getters['settings/settings']?.disableBountyProgram
    );
    console.log('POINTS USER SETTINGS', userSettings.value);

    const {
      notStartProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
    } = useBountyPrograms();

    const isMobile = computed(
      () => scope.isSmall || scope.isMedium || scope.noMatch
    );

    const login = () => {
      if (isFiorinNullableBalance.value) {
        const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;
        frames.fiorin.postMessage({ event: 'VIEW:DEPOSIT' }, FIORIN_HOST);
        return;
      }
      store.dispatch('burger/login');
    };

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const openLanding = () => {
      openLandingDXS();
    };

    const toggleDropdown = (value) => {
      closed.value = value;
      if (isNotDesktop.value) {
        if (value) {
          document.body.classList.remove('disable-account-scroll');
          return;
        }
        document.body.classList.add('disable-account-scroll');
      }
    };
    const { showModal, hideModal, modalStatus, modalsByName } = useModals();

    const burgerItems = computed(() => store.getters['burger/menuItems']);

    const menu = computed(() => {
      let filteredList = filterMenuItems(burgerItems.value);

      filteredList.forEach((item) => {
        if (item.nestedItems?.length) {
          item.nestedItems = filterMenuItems(item.nestedItems);
        }
      });

      return filteredList;
    });

    const filterMenuItems = (menuList) => {
      return menuList.filter((item) => {
        if (scope.isSmall || scope.isMedium || scope.noMatch) {
          return !item.desktop;
        } else {
          return !item.mobile;
        }
      });
    };

    const landingWidth = computed(() => {
      if (scope.isXlarge) {
        return '1200px';
      } else if (scope.isLarge) {
        return '1024px';
      } else {
        return '100%';
      }
    });

    const logo = computed(() => {
      return storeTheme.value === Theme.light ? logoBlack : logoWhite;
    });

    const hideLogo = computed(() => {
      return props.mobile && route.name !== TABS_ROUTE_NAMES.markets;
    });

    const newBountyTasks = computed(
      () => store.getters['bounty/newBountyTasks']
    );

    const successTasksOfPoint = computed(() => {
      return newBountyTasks?.value.find(
        (item) => item.name === 'ReferralCode' && item.status === 'Paid'
      );
    });

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    onMounted(() => {
      if (!isLoggedIn.value) {
        rendredTopMenu.value = true;
        return;
      }
      setTimeout(() => {
        rendredTopMenu.value = true;
      }, 4000);
    });

    return {
      rendredTopMenu,
      uiSettings,
      successTasksOfPoint,
      activeConnect,
      login,
      menu,
      closed,
      toggleDropdown,
      modalStatus,
      hideModal,
      showModal,
      openLanding,
      modalsByName,
      store,
      logo,
      landingWidth,
      hideLogo,
      isMobile,
      isFiorinNullableBalance,

      notStartProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
      disableBountyProgram,
    };
  },
  computed: {},
  methods: {},
});
</script>

<style lang="scss">
.landing-modal {
  .modal__body {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
}

.app-menu {
  display: flex;

  &__demo {
    margin-top: -13px;
    margin-right: 399px; // 48px;
    overflow: hidden;

    &.demo {
      margin-right: 48px;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  img.land {
    cursor: pointer;
    height: 23px;
    margin-right: 45px;
    margin-top: -0.156rem; //-4px;
  }

  .bounty-points-wrap {
    position: absolute;
    cursor: pointer;
    top: -13px;
    right: 45px;
    width: 335px;
    max-width: 335px;
  }
}

body.light {
  .app-menu {
  }
}
</style>
