<template>
  <Modal stop-mobile-max-height to-top @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.liquidityFundraiser.refund1').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="refund-demand">
        <template v-if="!$isFiorinMode">
          <p>{{ $t('popups.liquidityFundraiser.agree23') }}</p>
          <p class="underline">
            {{ $t('popups.liquidityFundraiser.agree25') }}
          </p>
          <ul>
            <li>{{ $t('popups.liquidityFundraiser.agree25-list-1') }}</li>
          </ul>
          <p class="underline">
            {{ $t('popups.liquidityFundraiser.agree27-1') }}
          </p>
          <ul>
            <li>{{ $t('popups.liquidityFundraiser.agree27-2-1') }}</li>
          </ul>
          <p class="underline">
            {{ $t('popups.liquidityFundraiser.agree27-4') }}
          </p>
          <p>{{ $t('popups.liquidityFundraiser.agree27-5') }}</p>
        </template>
        <template v-else>
          <p>{{ $t('popups.liquidity.usd.refund.providing') }}</p>
        </template>
        <p>{{ $t('popups.liquidityFundraiser.refund7') }}</p>
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.provisions') }}
        </div>
        <LiquidityProvisions @refund-tx="refundTx" />
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.links') }}
        </div>
        <UserfulLinks
          :hide-explorer="true"
          :hide-refund="true"
          @showMechanics="$emit(EVENTS.showMechanics)"
          @showAllocationModal="$emit(EVENTS.showAllocationModal)"
        />
      </div>
    </Card>
    <ConfirmRefundModal
      v-if="showConfirmRefundModal"
      :refund-tx="refundTransaction"
      @successConfirm="$emit(EVENTS.close)"
      @close="showConfirmRefundModal = false"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import UserfulLinks from './UsefulLinks';
import LiquidityProvisions from './LiquidityProvisions';
import ConfirmRefundModal from './ConfirmRefundModal';
import { ref } from 'vue';
import authMixin from '@/mixins/auth.mixin';

const EVENTS = {
  close: 'close',
  showMechanics: 'showMechanics',
  showAllocationModal: 'showAllocationModal',
};

export default {
  mixins: [authMixin],
  components: {
    Modal,
    Card,
    UserfulLinks,
    LiquidityProvisions,
    ConfirmRefundModal,
  },
  emits: [EVENTS.close, EVENTS.showMechanics, EVENTS.showAllocationModal],
  setup() {
    const showConfirmRefundModal = ref(false);
    const refundTransaction = ref(null);

    const refundTx = (tx) => {
      showConfirmRefundModal.value = true;
      refundTransaction.value = tx;
    };

    return {
      showConfirmRefundModal,
      refundTransaction,
      refundTx,
      EVENTS,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.refund-demand {
  max-height: calc(100% - 60px);
  overflow-y: auto;
  margin-bottom: 1px;
  padding-right: $px20;
  padding-left: $px20;
  padding-bottom: 15px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 230px) !important;
    padding-right: 40px;
    padding-left: 40px;
  }

  .head-title {
    margin-bottom: 1rem;
    font-family: Gotham_Pro_Bold;
    margin-top: 40px;
    margin-bottom: 5px;
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
