<template>
  <div
    class="menu-item"
    @click="
      isTradingSession
        ? $tour_goToNextStep($data.$tour_mobile, handleClick)
        : handleClick($event)
    "
  >
    <div
      class="menu-item__title"
      :class="{
        'menu-item__title--disabled': disabled || initialDisabled,
        userName: title.includes('@'),
      }"
    >
      {{
        action === CONSTANT_ACTIONS.PROVIDE_LIQUIDITY
          ? liquidityTitle
          : itemTitle
      }}
      <div class="icon-wrapper icon-wrapper--arrow">
        <icon
          v-if="nestedItems && nestedItems.length"
          name="arrow_down"
          width="16"
          height="16"
          class="expandable-arrow"
          :class="{ 'arrow-down': this.expanded }"
        />
      </div>
      <div
        v-if="action === CONSTANT_ACTIONS.BUY_CRYPTO"
        class="icon-wrapper icon-wrapper--payment"
      >
        <icon
          name="visa"
          width="32"
          height="21"
          class="icon-wrapper--visa"
          :class="`_${theme}`"
        />
        <icon
          name="masterCard"
          class="icon-wrapper--mastercard"
          width="32"
          height="21"
          :class="`_${theme}`"
        />
      </div>
      <div
        v-if="action !== 'userName' && (!nestedItems || !nestedItems.length)"
        class="icon-wrapper icon-wrapper--star"
        @click.stop="setFavourite"
      >
        <icon
          v-if="isFavourite"
          name="starfill"
          :fill-color="'#ffffff99'"
          width="14"
          height="14"
        />
        <icon
          v-else-if="menuFavourites.length < FAVOURITES_LIMIT_TO_SHOW"
          name="star"
          :fill-color="'#ffffff99'"
          width="14"
          height="14"
        />
      </div>
      <!-- show icon for user name -->
      <div
        v-if="nestedItems && title && title.includes('@')"
        class="icon-wrapper icon-wrapper--star"
      >
        <icon name="useracc" :fill-color="'#ffffff99'" width="14" height="14" />
      </div>
    </div>
    <div
      v-if="nestedItems"
      class="menu-item__nested-items"
      :style="{
        'max-height': calculateDropdownHeight(
          nestedItems && nestedItems.length
        ),
      }"
      @close-menu="close"
    >
      <transition-group name="nested-menu__nested-items">
        <div
          v-for="(item, index) in nestedItems"
          :key="index"
          class="menu-item__nested-item"
        >
          <MenuItem
            :title="item.title"
            :value="item.value"
            :action="item.action"
            :initial-disabled="item.initialDisabled"
            :translate="item.translate"
            @close-menu="emit('close-menu')"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import tourMixin from '@/mixins/tour.mixin';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { defineComponent, ref, computed } from 'vue';
import useUserActions from '@/compositions/useUserActions';
import { CONSTANT_ACTIONS } from '@/config/constantActions';
import { FAVOURITES_LIMIT_TO_SHOW } from '@/helpers/enums';

import '@/assets/icons/visa';
import '@/assets/icons/masterCard';
import '@/assets/icons/useracc';
import useTheme from '@/compositions/useTheme';

export default defineComponent({
  name: 'MenuItem',
  emits: ['close-menu', 'toggle-nested', 'close-nested'],
  mixins: [tourMixin],
  props: {
    class: {
      type: String || Object,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    translate: {
      type: Boolean,
      default: true,
    },
    action: {
      type: String,
      required: false,
    },
    nestedItems: {
      type: Array,
      required: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const { storeTheme } = useTheme();
    const userActions = useUserActions(props, { emit });
    const nestedItems = ['switchAccount', 'changeTheme', 'changeLanguage'];

    const itemTitle = ref(props.translate ? t(props.title) : props.title);

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const liquidityTitle = ref(
      props.translate
        ? settings.value?.providedLiquidity
          ? t('popups.liquidityFundraiser.title2')
          : t('popups.liquidityFundraiser.title')
        : props.title
    );

    const close = () => emit('close-menu');
    const menuFavourites = computed(
      () => store.getters['settings/menuFavourites']
    );

    const isTradingSession = computed(() => {
      return props.action === 'tradingSession';
    });

    const isFavourite = computed(() => {
      if (nestedItems.includes(props.action)) {
        return menuFavourites.value.indexOf(props.title) !== -1;
      } else {
        return menuFavourites.value.indexOf(props.action) !== -1;
      }
    });

    const getItemKey = () => {
      if (nestedItems.includes(props.action)) {
        return props.title;
      } else {
        return props.action;
      }
    };
    const setFavourite = () =>
      store.dispatch('settings/toggleMenuFavouriteItem', getItemKey());
    const handleClick = (event) => {
      if (props.disabled) {
        if (!props.nestedItems) {
          emit('close-nested');
        } else {
          emit('toggle-nested', event.target?.parentNode);
        }
        return;
      }
      if (props.nestedItems) {
        emit('toggle-nested', event.target?.parentNode);
      } else if (props.action) {
        if (props.action === CONSTANT_ACTIONS.EXPORT) {
          userActions.exportHistory();
        } else if (props.action === CONSTANT_ACTIONS.PROVIDE_LIQUIDITY) {
          userActions.openLiquidity();
        } else if (props.action === CONSTANT_ACTIONS.BUY_CRYPTO) {
          userActions.openCryptoTab();
        } else if (props.action === CONSTANT_ACTIONS.KYC_VERIFICATION) {
          if (!userActions.isEmailVerified()) {
            userActions.notifyEmailRequired();
            return;
          }
          store.dispatch(`burger/${props.action}`);
        } else if (props.action === CONSTANT_ACTIONS.STATISTICS) {
          userActions.openStats();
        } else if (props.action === CONSTANT_ACTIONS.FEATURES) {
          userActions.showCrowdfundingModal();
        } else {
          const value = props.value ?? props.title;
          store.dispatch(`burger/${props.action}`, { value });
        }
        close();
      }
    };
    return {
      theme: storeTheme.value,
      CONSTANT_ACTIONS,
      FAVOURITES_LIMIT_TO_SHOW,
      menuFavourites,
      isTradingSession,
      isFavourite,
      setFavourite,
      itemTitle,
      liquidityTitle,
      close,
      handleClick,
      emit,
    };
  },
  methods: {
    calculateDropdownHeight(size) {
      if (this.expanded) {
        return 53 * size + 'px';
      } else {
        return 0;
      }
    },
  },
});
</script>
<style lang="scss">
@import '@/assets/styles/colors';

.menu-item {
  font-family: Gotham_Pro_Medium;
  font-size: 15px;

  &__title {
    position: relative;
    width: 100%;
    height: 53px;
    padding: 0 30px 0 42px;
    display: flex;
    align-items: center;
    text-transform: none;

    &.userName {
      color: $color-gray;
      text-transform: lowercase;
    }

    &--disabled {
      color: $color-gray;
    }
  }
}

.burger-menu__item {
  .menu-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__nested-item {
      height: 53px;
      border-top-width: 1px;

      &s {
        width: 100%;
      }
    }
  }
}

.light {
  .menu-item__nested-items {
    background: rgba(0, 0, 0, 0.08);
  }
}

.menu-item__nested-items {
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

.menu-item__title {
  display: flex;
  flex-direction: row;
}

.icon-wrapper {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;

  &--arrow {
    right: 14px;
  }

  &--star {
    left: 15px;
  }

  &--payment {
    right: 41px;
  }

  &--visa {
    margin-right: 5px;

    @media all and (max-width: 1024px) {
      margin-right: 12px;
    }
  }

  &--visa,
  &--mastercard {
    pointer-events: none;

    &._light {
      path {
        fill: $color-gray;
      }
    }
  }
}

.expandable-arrow {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-90deg);
  padding: 0.12rem;

  &.arrow-down {
    transform: rotate(0);
  }
}
</style>
