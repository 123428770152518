<template>
  <Modal :stop-mobile-max-height="true" to-top @close="$emit('close')">
    <Card
      title="Get Bounty"
      description="Trade for free and withdraw profits"
      class="card"
      :body-class="'card-full'"
    >
      <div
        :class="{
          standalone: wd.navigator.standalone || isTelegram,
        }"
        class="new-bounty"
      >
        <InfoBlockVertPanel
          title="Bounty Balance, USD"
          :value="bountyBalance"
          description="Automatically used<br>to open trades"
          class="new-bounty__total-balance"
        />
        <div class="new-bounty__description">
          Enter the code or complete tasks below
        </div>
        <div class="new-bounty__field">
          <SimpleInput
            new-version
            :value="bountyCode"
            type="text"
            :optional-btn="bountyCode ? 'SEND' : 'PASTE'"
            optional-right-pos
            @optionalBack="onPaste"
            title="Bounty code"
            @onChange="onChangeCode"
          />
        </div>
        <template v-if="newBountyTasks?.length">
          <div class="new-bounty__big-title">Bounty tasks</div>
          <div class="new-bounty__tasks">
            <div
              class="new-bounty__tasks-item"
              v-for="(item, ndx) in newBountyTasks"
              :class="{
                gradient: item.status === STATUSES.NOT_PAYED,
              }"
              :key="ndx"
              @click="claimBounty(item)"
            >
              <div v-if="item.status === STATUSES.NOT_PAYED" class="label">
                Claim Bounty
              </div>
              <div
                v-else
                :class="{ paid: item.status === STATUSES.PAYED }"
                class="label"
              >
                {{
                  item?.description?.length > 30
                    ? `${item.description.slice(0, 30)}...`
                    : item.description || item.name
                }}
                <br v-if="item.status === STATUSES.ACTIVE" /><span
                  v-if="item.status === STATUSES.ACTIVE"
                  >{{ `Click to learn how get $${item.amount}` }}</span
                >
              </div>
              <div v-if="item.status === STATUSES.PAYED" class="value">
                <icon name="check" :width="12" :height="12" class="success" />
              </div>
              <div
                v-else
                :class="{
                  gradient: item.status === STATUSES.ACTIVE,
                  black: item.status === STATUSES.NOT_PAYED,
                }"
                class="value"
              >
                ${{ item.amount }}
              </div>
            </div>
          </div>
          <div class="new-bounty__full-description">
            IMPORTANT: By trading with your Bounty balance you
            <span class="boldlabel">agree to verify your phone number,</span>
            accept the Terms and Conditions and understand that your Bounty
            balance can be nullified in the case of prolonged inactivity
          </div>
          <div
            class="new-bounty__burn"
            :class="{ turnBountyPoints: uiSettings.turnBountyPoints }"
            @click="toggleTurn"
          >
            {{
              uiSettings.turnBountyPoints
                ? 'TURN ON MY BOUNTY'
                : 'TURN OFF MY BOUNTY'
            }}
          </div>
        </template>
      </div>
      <template v-if="isNotDesktop">
        <Button
          v-if="!bountyCode && !newBountyTasks.length"
          :text="$t('bountyModal.getStart')"
          :loading="startLoading"
          type="secondary"
          :class="{
            standalone: wd.navigator.standalone || isTelegram,
          }"
          class="new-bounty__btn"
          @click="getStart"
        />
        <Button
          v-else
          :text="
            bountyCode
              ? $t('popups.emailVerify.titles.sendCodeBtn')
              : 'LEARN MORE'
          "
          :accent="!!bountyCode.length"
          type="secondary"
          :class="{
            standalone: wd.navigator.standalone || isTelegram,
          }"
          class="new-bounty__btn"
          @click="learnMore"
        />
      </template>
      <template v-else>
        <Button
          v-if="!bountyCode && !newBountyTasks.length"
          :text="$t('bountyModal.getStart')"
          :loading="startLoading"
          type="secondary"
          :class="{
            standalone: wd.navigator.standalone || isTelegram,
          }"
          class="new-bounty__btn"
          @click="getStart"
        />
        <Button
          v-else
          :text="
            bountyCode
              ? $t('popups.emailVerify.titles.sendCodeBtn')
              : 'LEARN MORE'
          "
          :accent="!!bountyCode.length"
          type="secondary"
          :class="{
            standalone: wd.navigator.standalone || isTelegram,
          }"
          class="new-bounty__btn"
          @click="onClose"
        />
      </template>
    </Card>
    <div class="opacity0 st-1-mob-image1" />
    <div class="opacity0 st-1-mob-image2" />
    <div class="opacity0 st-1-mob-image3" />
    <div class="opacity0 st-1-mob-image4" />
    <div class="opacity0 st-1-mob-image5" />

    <div class="opacity0 st-1-web-image1" />
    <div class="opacity0 st-1-web-image2" />
    <div class="opacity0 st-1-web-image3" />
    <div class="opacity0 st-1-web-image4" />
    <div class="opacity0 st-1-web-image5" />

    <!--  -->
    <div class="opacity0 st-2-mob-image1" />
    <div class="opacity0 st-2-mob-image2" />
    <div class="opacity0 st-2-mob-image3" />

    <div class="opacity0 st-2-web-image1" />
    <div class="opacity0 st-2-web-image2" />
    <div class="opacity0 st-2-web-image3" />

    <!--  -->
    <!--  -->
    <div class="opacity0 st-3-mob-image1" />
    <div class="opacity0 st-3-mob-image2" />
    <div class="opacity0 st-3-mob-image3" />
    <div class="opacity0 st-3-mob-image4" />
    <div class="opacity0 st-3-mob-image5" />

    <div class="opacity0 st-3-web-image1" />
    <div class="opacity0 st-3-web-image2" />
    <div class="opacity0 st-3-web-image3" />
    <div class="opacity0 st-3-web-image4" />
    <!--  -->
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import InfoBlockVertPanel from '@/components/ui/InfoBlockVertPanel';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { usdBsvFormatAmount } from '@/helpers/utils';
import SimpleInput from '@/components/ui/SimpleInput';
import { useModals } from '@/modules/modals/api';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import '@/assets/icons/check';
import notify from '@/plugins/notify';
import { useScope } from '@/compositions/useScope';
// import { useI18n } from 'vue-i18n';
import { updateAndSetUserSettings } from '@/modules/settings/api';

const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

const STATUSES = {
  ACTIVE: 'Active',
  NOT_PAYED: 'NotPaid',
  PAYED: 'Paid',
};

export default {
  components: {
    Modal,
    Card,
    Button,
    InfoBlockVertPanel,
    SimpleInput,
  },
  setup() {
    const { isNotDesktop } = useScope();
    const store = useStore();
    const bountyCode = ref('');
    const { showModal, modalsByName } = useModals();
    const claimLoading = ref(false);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const newBountyTasks = computed(
      () => store.getters['bounty/newBountyTasks']
    );

    const bountyBalance = computed(() => {
      if (!activeConnect.value.bountyBalance) {
        return '0';
      }

      return usdBsvFormatAmount(activeConnect.value.bountyBalance, '', true);
    });

    const onChangeCode = (value) => {
      bountyCode.value = value;
    };

    const sendCode = async () => {
      try {
        const data = await connApp.invoke(
          'ProcessBountyCode',
          bountyCode.value
        );

        bountyCode.value = '';

        const bountyTasks = await connApp.invoke('ListBountyTasks');
        frames.fiorin.postMessage({ event: 'GET_BOUNTY_BALANCE' }, FIORIN_HOST);
        store.dispatch('bounty/setNewBountyBalance', bountyTasks);

        const info = await connApp.invoke('GetActivityPointsBalance');
        store.dispatch('localUiSettings/setActivityPointsBalance', info);
        await updateAndSetUserSettings({
          uiSettings: {},
        });

        console.log('BOUNTY POINTS CODE', data);
        if (data?.type === 'ActivityPoints') {
          showModal(modalsByName.tutorialHistory);
        }
      } catch (err) {
        const error = getErrorDetails(err);
        notify({
          text: error?.message,
          type: 'info',
        });
      }
    };

    const onPaste = async () => {
      if (bountyCode.value) {
        await sendCode();
        return;
      }
      const clipboardData = await navigator.clipboard.readText();
      bountyCode.value = clipboardData;
    };

    const learnMore = async () => {
      if (bountyCode.value) {
        await sendCode();
        return;
      }
      window.open(
        'https://faq.dxs.app/getting-started/bounty-trading',
        '_blank'
      );
    };

    const openInfo = () => {
      window.open(
        'https://faq.dxs.app/getting-started/bounty-trading',
        '_blank'
      );
    };

    const onClose = async () => {
      if (bountyCode.value) {
        await sendCode();
        return;
      }
      openInfo();
    };

    const claimBounty = async (item) => {
      if (claimLoading.value) {
        return;
      }
      if (item.status === STATUSES.ACTIVE && item.name === 'ReferralCode') {
        showModal(modalsByName.tutorialHistory);
        return;
      }
      if (item.status === STATUSES.ACTIVE && item.name === 'FirstOrder') {
        showModal(modalsByName.openAnyTrade);
        return;
      }
      if (item.status === STATUSES.ACTIVE && item.name === 'SharePosition') {
        showModal(modalsByName.sharePosition);
        return;
      }
      if (item.status === STATUSES.NOT_PAYED) {
        claimLoading.value = true;
        try {
          await connApp.invoke('ClaimBountyReward', item.id);
          setTimeout(async () => {
            const bountyTasks = await connApp.invoke('ListBountyTasks');
            store.dispatch('bounty/setNewBountyBalance', bountyTasks);
            const info = await connApp.invoke('GetActivityPointsBalance');
            store.dispatch('localUiSettings/setActivityPointsBalance', info);
            await updateAndSetUserSettings({
              uiSettings: {},
            });
            claimLoading.value = false;
          }, 1500);
        } catch (err) {
          claimLoading.value = false;
          const error = getErrorDetails(err);
          notify({
            text: error?.message,
            type: 'info',
          });
        }
      }
    };

    const startLoading = ref(false);

    const bountyTasks = computed(() => store.getters['bounty/bountyTasks']);

    const getStart = async () => {
      startLoading.value = true;
      await connApp
        .invoke('StartBountyProgram')
        .then(async () => {
          const bountyTasks = await connApp.invoke('ListBountyTasks');
          frames.fiorin.postMessage(
            { event: 'GET_BOUNTY_BALANCE' },
            FIORIN_HOST
          );
          store.dispatch('bounty/setNewBountyBalance', bountyTasks);

          const info = await connApp.invoke('GetActivityPointsBalance');
          store.dispatch('localUiSettings/setActivityPointsBalance', info);
          await updateAndSetUserSettings({
            uiSettings: {},
          });
        })
        .catch((err) => {
          notify({ text: getErrorDetails(err).message, type: 'info' });
          startLoading.value = false;
        });

      startLoading.value = false;
    };

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });
    console.log('BOUNTY POINTS TURN', uiSettings.value.turnBountyPoints);

    const turnFlag = ref(false);

    const toggleTurn = async () => {
      if (turnFlag.value) {
        return;
      }

      turnFlag.value = true;

      if (
        uiSettings.value.turnBountyPoints &&
        uiSettings.value.needSetCurrencyBountyProfits
      ) {
        showModal(modalsByName.bountyProfits);
      }

      await updateAndSetUserSettings({
        uiSettings: {
          turnBountyPoints: !uiSettings.value.turnBountyPoints,
        },
      });
      setTimeout(() => {
        turnFlag.value = false;
      }, 1000);
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    onMounted(async () => {
      const bountyTasks = await connApp.invoke('ListBountyTasks');
      store.dispatch('bounty/setNewBountyBalance', bountyTasks);
      await updateAndSetUserSettings({
        uiSettings: {},
      });
    });

    return {
      wd: window,
      isTelegram,
      uiSettings,
      startLoading,
      bountyBalance,
      bountyCode,
      bountyTasks,
      newBountyTasks,
      STATUSES,
      isNotDesktop,
      activeConnect,
      toggleTurn,
      onPaste,
      onChangeCode,
      learnMore,
      onClose,
      openInfo,
      claimBounty,
      getStart,
    };
  },
};
</script>
<style lang="scss" scoped>
.new-bounty {
  min-height: 400px;
  font-family: Gotham_Pro_Regular;
  padding-top: 4px;

  max-height: calc(100% - 125px);
  overflow-y: auto;
  margin-bottom: 60px;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  overflow-x: hidden;

  @media screen and (max-width: 410px) {
    max-height: calc(100% - 155px);
  }

  &.standalone {
    @media screen and (max-width: 410px) {
      // max-height: calc(100% - 165px);
    }
  }

  .success {
    position: absolute;
    right: 0px;
    top: -2px;
    color: #4daa92;
  }

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 360px) !important;
    padding-right: 40px;
    padding-left: 40px;
  }

  &__description {
    line-height: 20px;
    font-size: 15px;
    text-align: left;
    margin-bottom: 24px;

    &.mb-48 {
      margin-bottom: 48px;
    }
  }

  &__total-balance {
    margin-left: -20px;
    margin-bottom: 24px;
    width: calc(100% + 40px);
    padding: 15px 20px;

    @media screen and (min-width: 1024px) {
      margin-left: -40px;
      width: calc(100% + 80px);
      padding: 15px 40px;
    }
  }

  &__big-title {
    font-size: 15px;
    color: #888;
    font-family: Gotham_Pro_Bold;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 13px;
  }

  &__field {
    width: calc(100% + 15px);
    margin-left: -6px;
    margin-bottom: 24px;
  }

  &__tasks {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    width: calc(100% + 15px);
    margin-left: -6px;
  }

  &__tasks-item {
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
    background-color: #262a39;
    padding: 0 22px;
    margin-bottom: 1px;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.gradient {
      cursor: pointer;
      background: linear-gradient(to right, #2f80ed, #b2ffda);

      .label {
        color: #000;
      }
    }

    .label {
      font-size: 18px;
      font-family: Gotham_Pro_Medium;

      &.paid {
        color: rgba(255, 255, 255, 0.5);
      }

      span {
        font-size: 11px;
        font-family: Gotham_Pro_Regular;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .value {
      font-size: 18px;
      font-family: Gotham_Pro_Medium;
      position: relative;

      &.gradient {
        background: linear-gradient(135deg, #2f80ed 20%, #b2ffda 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        border-image: linear-gradient(135deg, #2f80ed 20%, #b2ffda 70%);
        border-image-slice: 1;
      }

      &.black {
        color: #000;
      }
    }
  }

  &__burn {
    padding: 0 22px;
    border-radius: 6px;
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(203, 72, 64, 0.25);
    color: #cb4840;
    font-size: 15px;
    font-family: Gotham_Pro_Medium;
    margin-bottom: 28px;
    cursor: pointer;

    &.turnBountyPoints {
      background-color: rgba(0, 173, 145, 0.25);
      color: rgba(0, 173, 145, 1);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__full-description {
    margin-top: 48px;
    margin-bottom: 28px;
    font-size: 15px;
    line-height: 20px;
    opacity: 0.5;

    .boldlabel {
      font-family: Gotham_Pro_Bold;
    }
  }

  &__btn {
    margin-top: 30px;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;

    &.standalone {
      bottom: 82px;
    }

    @media screen and (min-width: 1024px) {
      bottom: 0;
    }

    &.more {
      bottom: 60px;
    }
  }
}

body.dark {
  .new-bounty {
    &__separator {
      background: #262c3c;
    }

    &__link-block {
      &.first {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}

body.light {
  .new-bounty {
    &__tasks-item {
      background-color: #dcdada;

      .label {
        &.paid {
          color: #888;
        }

        span {
          color: #888;
        }
      }

      .value {
        &.gradient {
          background: linear-gradient(135deg, #2f80ed 20%, #81c1a2 70%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
          -o-border-image: linear-gradient(135deg, #2f80ed 20%, #b2ffda 70%);
          border-image: linear-gradient(135deg, #2f80ed 20%, #b2ffda 70%);
          border-image-slice: 1;
          // background: linear-gradient(135deg, #2f80ed 20%, #5ac994 70%);
          // border-image: linear-gradient(135deg, #2f80ed 20%, #5ac994 70%);
        }
      }
    }
  }
}

.opacity0 {
  z-index: -100;
  opacity: 0;
  position: fixed;
  left: -100px;
  top: -100px;
}

.st-1-mob-image1 {
  background-image: url('~@/assets/tutorial/mob1.svg');
}
.st-1-mob-image2 {
  background-image: url('~@/assets/tutorial/mob2.svg');
}
.st-1-mob-image3 {
  background-image: url('~@/assets/tutorial/mob3.svg');
}
.st-1-mob-image4 {
  background-image: url('~@/assets/tutorial/mob4.svg');
}
.st-1-mob-image5 {
  background-image: url('~@/assets/tutorial/mob5.svg');
}
.st-1-web-image1 {
  background-image: url('~@/assets/tutorial/web1.svg');
}
.st-1-web-image2 {
  background-image: url('~@/assets/tutorial/web2.svg');
}
.st-1-web-image3 {
  background-image: url('~@/assets/tutorial/web3.svg');
}
.st-1-web-image4 {
  background-image: url('~@/assets/tutorial/web4.svg');
}
.st-1-web-image5 {
  background-image: url('~@/assets/tutorial/web5.svg');
}

.st-2-mob-image1 {
  background-image: url('~@/assets/open_any_trade/mob1.svg');
}
.st-2-mob-image2 {
  background-image: url('~@/assets/open_any_trade/mob2.svg');
}
.st-2-mob-image3 {
  background-image: url('~@/assets/open_any_trade/mob3.svg');
}
.st-2-web-image1 {
  background-image: url('~@/assets/open_any_trade/web1.svg');
}
.st-2-web-image2 {
  background-image: url('~@/assets/open_any_trade/web2.svg');
}
.st-2-web-image3 {
  background-image: url('~@/assets/open_any_trade/web3.svg');
}

.st-3-mob-image1 {
  background-image: url('~@/assets/share_position/mob1.svg');
}
.st-3-mob-image2 {
  background-image: url('~@/assets/share_position/mob2.svg');
}
.st-3-mob-image3 {
  background-image: url('~@/assets/share_position/mob3.svg');
}
.st-3-mob-image4 {
  background-image: url('~@/assets/share_position/mob4.svg');
}
.st-3-mob-image5 {
  background-image: url('~@/assets/share_position/mob5.svg');
}
.st-3-web-image1 {
  background-image: url('~@/assets/share_position/web1.svg');
}
.st-3-web-image2 {
  background-image: url('~@/assets/share_position/web2.svg');
}
.st-3-web-image3 {
  background-image: url('~@/assets/share_position/web3.svg');
}
.st-3-web-image4 {
  background-image: url('~@/assets/share_position/web4.svg');
}
</style>
