<template>
  <Modal :stop-mobile-max-height="true" to-top @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.liquidityFundraiser.allocation').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div
        :class="{
          standalone:
            wd.navigator.standalone ||
            isRunningStandaloneAndroid() ||
            isTelegram,
        }"
        class="liquidity-allocation"
      >
        <template v-if="!$isFiorinMode">
          <p>{{ $t('popups.liquidityFundraiser.realTime') }}</p>
          <p>{{ $t('popups.liquidityFundraiser.realTime2') }}</p>
        </template>
        <template v-else>
          <p>{{ $t('popups.liquidity.usd.allocation') }}</p>
        </template>
        <liquidityTable />
        <div class="head-title mt-15">
          {{ $t('popups.liquidityFundraiser.links') }}
        </div>
        <UserfulLinks
          :hide-explorer="true"
          :hide-allocation="true"
          @showRefundModal="$emit(EVENTS.showRefundModal)"
          @showMechanics="$emit(EVENTS.showMechanics)"
        />
      </div>
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import UserfulLinks from './UsefulLinks';
import liquidityTable from './LiquidityTable';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import authMixin from '@/mixins/auth.mixin';

const EVENTS = {
  close: 'close',
  showRefundModal: 'showRefundModal',
  showMechanics: 'showMechanics',
};

export default {
  components: { Modal, Card, liquidityTable, UserfulLinks },
  mixins: [authMixin],
  emits: [EVENTS.close, EVENTS.showRefundModal, EVENTS.showMechanics],
  setup() {
    const store = useStore();

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      EVENTS,
      wd: window,
      isTelegram,
      isRunningStandaloneAndroid,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.liquidity-allocation {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  margin-bottom: 1px;
  padding-right: $px20;
  padding-left: $px20;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (max-width: 1024px) {
    max-height: calc(100% - 60px);

    &.standalone {
      max-height: calc(100% - 92px);
    }
  }

  @media screen and (min-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }

  .head-title {
    margin-bottom: 1rem;
    font-family: Gotham_Pro_Bold;
    margin-top: 40px;
    margin-bottom: 5px;
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }

  div {
    margin-top: 25px;
  }

  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.btn {
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
