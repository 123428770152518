import store from '@/store';

export default {
  computed: {
    $isLoggedIn() {
      return store.getters['auth/isLoggedIn'];
    },

    $userId() {
      return store.getters['auth/userId'];
    },

    $provider() {
      return store.getters['auth/provider'];
    },

    $isFiorinAsProxy() {
      return store.getters['auth/isFiorinAsProxy'];
    },

    $isFiorinMode() {
      return store.getters['auth/isFiorinMode'];
    },

    $isFiorin() {
      return store.getters['auth/isFiorin'];
    },

    $isHandCash() {
      return store.getters['auth/isHandCash'];
    },

    $isDotWallet() {
      return store.getters['auth/isDotWallet'];
    },

    $currency() {
      return this.$isFiorin ? 'USD' : 'BSV';
    },
  },

  methods: {},
};
