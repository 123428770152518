import { computed } from 'vue';
import { useStore } from 'vuex';

export const useBountyPrograms = () => {
  const store = useStore();

  const accountBalance = computed(
    () => store.getters['user/getAccountBalance']
  );

  const isFiorin = computed(() => store.getters['auth/isFiorin']);

  const firstAppLoaded = computed(
    () => store.getters['localUiSettings/firstAppLoaded']
  );

  const newBountyTasks = computed(() => store.getters['bounty/newBountyTasks']);

  const settings = computed(() => store.getters['settings/settings']);
  const activityPointsBalance = computed(
    () => store.getters['localUiSettings/activityPointsBalance']
  );

  const pointsProgress = computed(() => {
    const from = activityPointsBalance.value.balance || 0;
    const to = settings.value.bountyInfo?.referralProgram?.targetPoints || 0;
    const percentProgress = ((from / to) * 100).toFixed(2);

    const diff =
      settings?.value.bountyInfo?.referralProgram?.targetPoints -
      activityPointsBalance.value.balance;

    return {
      percentProgress: percentProgress > 100 ? 100 : percentProgress,
      currentPoint: activityPointsBalance.value.balance || 0,
      targetPoint:
        settings.value.bountyInfo?.referralProgram?.targetAmount || 0,
      leftPointsToWin: diff < 0 ? 0 : diff,
    };
  });

  const activePointsTask = computed(() => {
    return !!newBountyTasks.value.find(
      (item) => item.name === 'ReferralCode' && item.status === 'Active'
    );
  });

  // const referralProgram = computed(
  //   () =>
  //     settings.value?.bountyInfo?.referralProgram &&
  //     activityPointsBalance.value.balance <
  //       settings?.value.bountyInfo?.referralProgram?.targetPoints
  // );

  // программа не активирована, баунти 0
  const notStartProgram = computed(() => {
    return (
      firstAppLoaded.value &&
      isFiorin.value &&
      accountBalance.value.bounty == 0 &&
      !activePointsTask.value
    );
  });

  // программа активирована и основной баланс 0
  const startProgramAndNullBalance = computed(() => {
    return (
      firstAppLoaded.value &&
      isFiorin.value &&
      accountBalance.value.available == 0 &&
      !activityPointsBalance.value?.nextBalance &&
      activePointsTask.value
    );
  });

  // программа не активирована и есть баунти
  const noStartProgramAndBountyBalance = computed(() => {
    return (
      firstAppLoaded.value &&
      isFiorin.value &&
      accountBalance.value.bounty != 0 &&
      !activePointsTask.value
    );
  });

  // программа активирована и есть основной баланс
  const startProgramAndMainBalance = computed(() => {
    return (
      firstAppLoaded.value &&
      isFiorin.value &&
      accountBalance.value.available != 0 &&
      newBountyTasks.value.length &&
      activePointsTask.value
    );
  });

  return {
    referralProgram: activePointsTask.value,
    pointsProgress,
    notStartProgram,
    startProgramAndNullBalance,
    noStartProgramAndBountyBalance,
    startProgramAndMainBalance,
  };
};
