import store from '@/store';
import { WalletNames } from '@/wallets/common';
import FiorinConnector from '@/wallets/Fiorin';
import getFiorinEventHandlers from '@/store/modules/auth/fiorinHandlers';
import { isFiorinStable } from '@/config/constants';

function createFiorinConnector(account) {
  return new FiorinConnector(getFiorinEventHandlers(), {
    accessToken: account.accessToken,
    packet: account.packet,
    id: account.id,
  });
}

let _connector = null;
const getConnector = () => {
  if (!_connector) {
    throw new Error('Fiorin connector is not initialized');
  }

  return _connector;
};

const assertFiorin = () => {
  if (!store.getters['auth/isFiorin']) {
    console.debugError(
      'Accessing the fiorin-related fields when not authenticated  or using other wallet #wallet #getters #ifFiorin',
      'provider:',
      store.getters['auth/provider']
    );

    throw new Error('Not Fiorin');
  }
};

const types = {
  SET_FIORIN_LAST_TX_ID: 'SET_FIORIN_LAST_TX_ID',
};

const state = {
  userName: '',
  email: '',
  paymail: null,
  targetWeb3Provider: null,

  balances: {
    totalAssetsUsd: 0,
    activeTokens: [],
    pendingBalance: 0,
    bountyBalance: {},
    // [Vadim] store exactly whole object tokenWithMaxBalance,
    // do not hope to store only id to get from activeTokens
    // because when user has no tokens, we get empty activeTokens
    // but tokenWithMaxBalance is not empty
    tokenWithMaxBalance: {
      tokenId: '',
      currency: '',
      name: '',
      amount: 0,
      amountUsd: 0,
    },
  },

  fiorinLastTxId: '',

  // [Vadim] do not put into 'balances' obj
  balancesLog: [],
};

const getters = {
  balancesLog: (state) => state.balancesLog,

  // todo: rename fiorinLastTx -> fiorinLastTxId
  fiorinLastTx: (state) => state.fiorinLastTxId,

  isFiorinAsProxy: (state) => {
    assertFiorin();
    return !!state.targetWeb3Provider;
  },

  activeTokens: (state) => {
    assertFiorin();
    return state.balances.activeTokens;
  },

  totalAssetsAmountUsd: (state) => {
    assertFiorin();
    return state.balances.totalAssetsUsd ?? 0;
  },

  pendingUsd: (state) => {
    assertFiorin();
    return state.balances.pendingBalance;
  },

  userName: (state) => {
    assertFiorin();
    return state.userName;
  },

  paymail: (state) => {
    assertFiorin();
    return state.paymail;
  },

  bountyBalanceUsd: (state) => {
    assertFiorin();
    return state.balances.bountyBalance?.amountUsd;
  },
};

const mutations = {
  clear(state) {
    state.userName = '';
    state.email = '';
    state.fiorinLastTxId = '';
    state.paymail = null;
    state.targetWeb3Provider = null;
    state.balancesLog = [];
    state.balances.pendingBalance = 0;
    state.balances.activeTokens = [];
    state.balances.tokenWithMaxBalance = {};
    state.balances.totalAssetsUsd = 0;
    state.balances.bountyBalance = {};
  },

  init(state, { paymail, userName, targetWeb3Provider, email }) {
    if (!paymail) {
      throw new Error('No paymail passed to init #fiorin #mutations #init');
    }

    if (!userName) {
      throw new Error('No userName passed to init #fiorin #mutations #init');
    }

    if (!targetWeb3Provider) {
      throw new Error(
        'No targetWeb3Provider passed to init #fiorin #mutations #init'
      );
    }

    state.paymail = paymail;
    state.userName = userName;
    state.targetWeb3Provider = targetWeb3Provider;
    //email can be null, for example, if it's metamask internally
    state.email = email;
  },

  [types.SET_FIORIN_LAST_TX_ID](state, id) {
    console.debug('Setting fiorinLastTxId #fiorin #mutations', id);
    state.fiorinLastTxId = id;
  },

  setBalances(
    state,
    { tokenWithMaxBalance, activeTokens, bountyBalance, pendingBalance }
  ) {
    if (!tokenWithMaxBalance) {
      throw new Error(
        'tokenWithMaxBalance is null #wallet #mutations #setBalances'
      );
    } else {
      if (!tokenWithMaxBalance.tokenId) {
        throw new Error(
          'tokenWithMaxBalance.tokenId is empty #wallet #mutations #setBalances'
        );
      }
      if (!tokenWithMaxBalance.currency) {
        throw new Error(
          'tokenWithMaxBalance.currency is empty #wallet #mutations #setBalances'
        );
      }

      if (!tokenWithMaxBalance.name) {
        throw new Error(
          'tokenWithMaxBalance.name is empty #wallet #mutations #setBalances'
        );
      }
    }

    if (typeof bountyBalance !== 'object') {
      throw new Error(
        'bountyBalance should be an object #wallet #mutations #setBalances, but got: ' +
          typeof bountyBalance
      );
    } else {
      if (typeof bountyBalance.amountUsd !== 'number') {
        throw new Error(
          'bountyBalance.amount is empty #wallet #mutations #setBalances'
        );
      }
    }

    if (typeof pendingBalance !== 'number') {
      throw new Error(
        'pendingBalance should be a number #wallet #mutations #setBalances, but got: ' +
          typeof pendingBalance
      );
    }

    if (!Array.isArray(activeTokens)) {
      throw new Error(
        'activeTokens should be an array #wallet #mutations #setBalances'
      );
    }

    const totalAssetsUsd = activeTokens.reduce((prev, item) => {
      return prev + item.amountUsd;
    }, 0);

    const newBalances = {};
    newBalances.activeTokens = activeTokens;
    newBalances.totalAssetsUsd = totalAssetsUsd;
    newBalances.bountyBalance = bountyBalance;
    newBalances.pendingBalance = pendingBalance;
    newBalances.tokenWithMaxBalance = tokenWithMaxBalance;

    console.log('#balance #wallet PendingBalance:', newBalances.pendingBalance);
    console.log(
      `#balance #wallet BountyBalance: ${JSON.stringify(
        newBalances.bountyBalance
      )}`
    );
    console.log(
      '#balance #wallet TokenWithMaxBalance',
      newBalances.tokenWithMaxBalance.tokenId
    );

    console.log(
      '#balance #wallet ActiveTokens:\n',
      newBalances.activeTokens
        .map(
          (t) => `tokenId: ${t.tokenId}, name: ${t.name} amount: ${t.amount}`
        )
        .join('\n')
    );
    console.log('#balance #wallet TotalAssetsUsd:', newBalances.totalAssetsUsd);

    // todo: [Vadim] log only if values are changed
    let log = [
      `|${+new Date()}|`,
      `TMBA: ${newBalances.tokenWithMaxBalance.amount}`,
      `Bb: ${newBalances.bountyBalance}`,
      `PB: ${newBalances.pendingBalance}`,
      `TAU: ${newBalances.totalAssetsUsd}`,
    ].join('\n');

    state.balancesLog.push(log);
    state.balances = newBalances;
  },
};

const actions = {
  async initAuth(_, account) {
    if (account.provider !== WalletNames.fiorin) {
      throw new Error(
        'Invalid provider. #fiorin #initAuth Expected Fiorin, got ' +
          account.provider
      );
    }

    if (!account.accessToken) {
      throw new Error('Empty access token #fiorin #initAuth');
    }

    if (!account.id) {
      throw new Error('Empty account id #fiorin #initAuth');
    }

    // do not save to _connector here! It will be initialized in the event handler
    const connector = createFiorinConnector(account);
    await connector.connect();

    const timeout = setInterval(() => {
      console.warn(
        'Fiorin responds too long for waitForAuthStatus. #initAuth #Fiorin'
      );
    }, 10000);

    try {
      await connector.waitForAuthStatus();

      return;
    } catch (e) {
      console.debugError(
        'Error when waitForAuthStatus for #initAuth #Fiorin',
        e
      );
    } finally {
      clearTimeout(timeout);
    }
  },

  async startLogin(_, { options, account }) {
    // показываем модалку по флагу eth (чтобы в модалке иконка была другой) или по флагу true
    const dialogMode =
      options.trustWalletFlag ||
      options.metamaskFlag ||
      options.walletConnectFlag
        ? 'eth'
        : true;
    await store.dispatch('localUiSettings/setShowConnectModal', dialogMode);

    // for the case if fiorin does not respond
    setTimeout(() => {
      store.dispatch('localUiSettings/setShowConnectModal', false);
    }, 60_000);

    //do not save this connector, to avoid closing the current one, until new auth is complete
    //the _connector is initialized in the event handler when the auth is complete ('init')
    const connector = createFiorinConnector(account || {});
    await connector.connect(options);
  },

  async logout() {
    if (!_connector) {
      console.debug('No connector to logout #fiorin #logout');
      return;
    }
    const oldConnector = _connector;
    _connector = null;

    await oldConnector.logout();
    //auth/onLogout will be called by the connector
  },

  async switchAccount({ dispatch }, account) {
    if (account.provider !== WalletNames.fiorin) {
      throw new Error(
        'Invalid provider. #fiorin #switchAccount Expected Fiorin, got ' +
          account.provider
      );
    }

    if (!account.accessToken) {
      throw new Error('Empty access token #fiorin #switchAccount');
    }

    // todo: [Vadim] yet to be implemented, need to implement correct behavior of iframes
    await dispatch('startLogin', { account, options: {} });
  },

  //todo: [Vadim] responsibility of this module?
  setFiorinLastTxId({ commit }, id) {
    console.debug('Setting fiorinLastTxId #fiorin #dispatch', id);
    commit(types.SET_FIORIN_LAST_TX_ID, id);
  },

  async clear({ commit }) {
    if (_connector) {
      await _connector.disconnect();
      _connector = null;
    }
    await commit('clear');
  },

  async init({ commit }, data) {
    commit('init', data.payload);
    if (_connector) {
      await _connector.disconnect();
    }
    _connector = data.connector;
  },

  async refill(_, payload) {
    assertFiorin();
    const response = await getConnector().refill(payload);
    return response?.txId;
  },

  viewDeposit() {
    assertFiorin();
    getConnector().viewDeposit();
  },
  viewWallet() {
    assertFiorin();
    getConnector().viewWallet();
  },

  async setBalances({ commit }, balances) {
    commit('setBalances', balances);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  cachedPaths: ['fiorin.balancesLog'],
  modules: {
    maxBalanceToken: {
      namespaced: true,
      getters: {
        token: (state, getters, rootState) => {
          assertFiorin();
          return rootState.fiorin.balances.tokenWithMaxBalance;
        },

        tokenId: (state, getters) => {
          assertFiorin();
          return getters.token.tokenId;
        },

        currency: (state, getters) => {
          assertFiorin();
          return getters.token.currency;
        },

        name: (state, getters) => {
          assertFiorin();
          return getters.token.name;
        },

        amount: (state, getters) => {
          assertFiorin();
          return getters.token.amount;
        },

        amountUsd: (state, getters) => {
          assertFiorin();
          return getters.token.amountUsd;
        },

        isStable: (state, getters) => {
          assertFiorin();
          return isFiorinStable(getters.currency);
        },
      },
    },
  },
};
