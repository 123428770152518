import { connApp } from '@/helpers/signalR';

const types = {
  SET_ACTIVE_CONNECT: 'SET_ACTIVE_CONNECT',
  SET_USER_NAME: 'SET_USER_NAME',
  ADD_NEW_CONNECT: 'ADD_NEW_CONNECT',
  UPDATE_CONNECTS: 'UPDATE_CONNECTS',
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  UPDATE_CONNECTION_STATUS: 'UPDATE_CONNECTION_STATUS',
  SET_AUTH_LOADER: 'SET_AUTH_LOADER',
  SET_RECONNECT_FLAG: 'SET_RECONNECT_FLAG',
};

const getInitialState = () => ({
  activeConnect: {},
  connects: [],
  walletLoading: true,
  connectionStatus: true,
  userName: '',
  authLoader: false,
  isReconnect: false,
});

let activeConnectedWasInitialized = false;

const getters = {
  isReconnect: (state) => state.isReconnect,
  // todo: [Vadim] check if it is used and make sure it's updated correctly on connApp disconnect, and make sure it's for this purpose
  authLoader: (state) => state.authLoader,
  activeConnect: (state) => {
    if (activeConnectedWasInitialized) {
      if (
        !state.activeConnect ||
        !state.activeConnect.provider ||
        !state.activeConnect.accessToken
      ) {
        console.debugError(
          'getting empty activeConnect after it was initialized #connectors #getters activeConnect',
          state.activeConnect
        );
      }
    }
    return state.activeConnect;
  },
  connects: (state) => state.connects,
  connectStatus: (state) => state.connectionStatus,
  getWalletLoadingStatus: (state) => state.walletLoading,
  userName: (state) => state.userName,
};

// todo: [Vadim] wtf it's doing here?
let serverInfo;

const mutations = {
  [types.SET_RECONNECT_FLAG](state, value) {
    state.isReconnect = value;
  },

  [types.SET_AUTH_LOADER](state, value) {
    state.authLoader = value;
  },

  [types.SET_ACTIVE_CONNECT](state, value) {
    console.debug(
      'Setting active connect #connectors #mutations #setActiveConnect',
      value
    );

    if (!value && state.activeConnect) {
      console.debugError(
        'Why passing empty value to setActiveConnect? #connectors #mutations #setActiveConnect'
      );
      throw new Error('Empty value passed to setActiveConnect');
    }

    if (value.serverInfo) {
      serverInfo = value.serverInfo;
    }
    value.serverInfo =
      serverInfo || value.serverInfo || state.activeConnect.serverInfo; // preserve serverInfo if set

    state.activeConnect = value;
  },

  [types.SET_USER_NAME](state, value) {
    state.userName = value;
  },
  [types.ADD_NEW_CONNECT](state, value) {
    state.connects.push(value);
  },
  [types.UPDATE_CONNECTS](state, value) {
    state.connects = value;
  },
  [types.UPDATE_LOADING_STATUS](state, value) {
    state.walletLoading = value;
  },
  [types.UPDATE_CONNECTION_STATUS](state, value) {
    state.connectionStatus = value;
  },
};

let actions = {
  setReconnectFlag({ commit }, value) {
    commit(types.SET_RECONNECT_FLAG, value);
  },

  setAuthLoader({ commit }, value) {
    console.debug(
      'Setting auth loader #connectors #actions #setAuthLoader',
      value
    );
    commit(types.SET_AUTH_LOADER, value);
  },

  async stopSocket() {
    console.debug('Disconnecting wallet... #connectors #actions #disconnect');
    connApp.setAccessData(null, null, null);
    await connApp.stop();
  },

  setUserName({ commit }, name) {
    commit(types.SET_USER_NAME, name);
  },

  async updateSocket({ state }) {
    console.debug('Changing auth #connectors #actions #changeAuth');
    const activeConnect = state.activeConnect;
    connApp.setAccessData(
      activeConnect.provider,
      activeConnect.accessToken,
      activeConnect.serverInfo?.userId
    );

    await connApp.restart();
  },

  async initActiveConnect({ commit }, provider) {
    commit(types.SET_ACTIVE_CONNECT, { provider });
  },

  // [Vadim] temp
  async setActiveConnectUnsafe({ commit }, wallet) {
    commit(types.SET_ACTIVE_CONNECT, wallet);
  },

  async setActiveConnect({ state, commit, dispatch }, wallet) {
    console.debug('#setActiveConnect #wallet #connectors wallet:', wallet);
    let oldWallet = { ...state.activeConnect };
    if (!wallet) {
      console.debugError('No wallet provided #setActiveConnect #connectors');
    } else {
      if (!wallet.provider || !wallet.accessToken) {
        console.debugError(
          'No provider or token provided #setActiveConnect #connectors',
          wallet
        );
      }
    }

    wallet = { ...wallet };

    commit(types.SET_ACTIVE_CONNECT, wallet);

    {
      oldWallet = oldWallet || {};
      const newWallet = wallet || {};
      //log all the values:

      if (
        oldWallet.accessToken !== newWallet.accessToken ||
        oldWallet.provider !== newWallet.provider ||
        oldWallet.serverInfo?.userId !== newWallet.serverInfo?.userId
      ) {
        await dispatch('updateSocket');
      } else {
        console.debug(
          'No need to update connection, same provider and token #setActiveConnect #connectors'
        );
      }
    }

    // todo: it was newer set true
    commit(types.UPDATE_LOADING_STATUS, false);
  },
  async onAuthenticated({ state, commit, dispatch }, wallet) {
    console.debug('#onAuthenticated  #connectors #actions');

    if (!wallet) {
      const error = 'No wallet provided #connectors #actions #authenticated';
      console.debugError(error);
      throw new Error(error);
    }

    if (!wallet.provider || !wallet.accessToken) {
      const error =
        'No provider or token provided #connectors #actions #authenticated';
      console.debugError(error);
      throw new Error(error);
    }

    commit(
      types.UPDATE_CONNECTS,
      state.connects.filter(
        //todo:why filter only by provider?
        (conn) => conn.provider && conn.provider !== wallet.provider
      )
    );

    // todo: [Vadim]: it should not happen, move out
    // await wallet.fetchBalance();
    // await wallet.fetchProfile();

    commit(types.SET_ACTIVE_CONNECT, wallet);

    // it passes token and provider to socket and restart it
    await dispatch('updateSocket');

    // set serverInfo to activeConnect
    {
      //  socket should be initialized with token before settings processing
      const settings = await dispatch('settings/onAuthenticated', null, {
        root: true,
      });

      const authInfo = {
        provider: settings?.auth?.provider,
        providerId: settings?.auth?.providerId,
        userName: settings?.auth?.userName,
        userId: settings?.user?.id,
      };

      if (authInfo.userId) {
        await dispatch('setServerInfo', authInfo);
        // it passes userId to socket and restart it
        await dispatch('updateSocket');
      } else {
        console.debugError(
          'No userId returned from GetUser #connectors #actions #authenticated'
        );
      }
    }

    // burger must be initialized before session
    await dispatch('burger/onAuthenticated', null, { root: true });

    // session requires burger to be initialized
    // user is the last, serverInfo is needed for it

    await Promise.all([
      dispatch('session/onAuthenticated', null, { root: true }),
      dispatch('user/onAuthenticated', null, { root: true }),
    ]);

    await dispatch('auth/onAuthenticated', wallet.provider, { root: true });

    {
      wallet = state.activeConnect;
      const findAccount = state.connects.find(
        (acc) => acc.provider === wallet.provider
      );

      if (!findAccount) {
        console.debug(
          'findAccount is false, Adding new connect #setActiveConnect #connectors'
        );
        commit(types.ADD_NEW_CONNECT, wallet);
      }
    }
  },
  //set connection but presave serverInfo
  // eslint-disable-next-line no-unused-vars
  async setActiveConnectionInfo({ state, commit, dispatch }, wallet) {
    if (!wallet) {
      console.debugError('No wallet provided #setActiveConnect #connectors');
      throw new Error('No wallet provided #setActiveConnect #connectors');
    }
    console.debug('#setActiveConnect #wallet #connectors wallet:', wallet);
    wallet = { ...wallet };
    let serverInfo = state.activeConnect?.serverInfo;
    let newWallet = { ...wallet, serverInfo };
    await dispatch('setActiveConnect', newWallet);
  },
  async setServerInfo({ getters, commit }, info) {
    info = { ...info };
    console.debug(
      'Setting active connect info #connectors #actions #setServerInfo',
      info
    );
    if (!info) {
      console.debugError(
        'No info provided #connectors #actions #setServerInfo'
      );

      throw new Error('No info provided #connectors #actions #setServerInfo');
    }

    const activeConnect = getters.activeConnect;
    activeConnect.serverInfo = info;
    commit(types.SET_ACTIVE_CONNECT, activeConnect);
  },
  async onLogout({ commit, dispatch, state }) {
    console.debug('#logout #connectors #actions');

    const activeConnect = { ...state.activeConnect };

    localStorage.removeItem('dxsLogined');
    localStorage.removeItem('_fp');

    await dispatch('setUserName', '');
    await dispatch('setAuthLoader', false);
    await dispatch('burger/onLogout', null, { root: true });
    await dispatch('session/onLogout', null, { root: true });
    await dispatch('settings/onLogout', null, { root: true });
    await dispatch('user/onLogout', null, { root: true });
    await dispatch('stopSocket');

    commit(types.SET_ACTIVE_CONNECT, {});

    await dispatch('auth/onLogout', activeConnect.provider, { root: true });
  },
};
export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
