import bsvPrice from '@/compositions/bsvPrice';
import BigNumber from 'bignumber.js';
import { PositionTypes } from '@/modules/positions/helpers';
import store from '@/store';
import { connApp } from '@/helpers/signalR';
import { refreshUserData } from '@/modules/user/store/refreshUserData';

const state = () => {
  return {
    marketFavourites: [],
    wsConfig: {},
  };
};

const getters = {
  getAccountBalance(state, getters, rootState, rootGetters) {
    if (
      !(
        rootGetters['positions/ready'] &&
        rootGetters['session/infoIsInitialized']
      )
    ) {
      // console.debug(
      //   '#notReady Not ready for the accountBalance #user #getAccountBalance'
      // );
      return {};
    }

    const isLoggedIn = rootGetters['auth/isLoggedIn'];
    if (isLoggedIn && !rootGetters['session/allInitialized']) {
      return {};
    }

    if (!isLoggedIn && rootGetters['positions/getPositions'].length === 0) {
      return {};
    }

    const isFiorin = rootGetters['auth/isFiorin'];
    const isHandCash = rootGetters['auth/isHandCash'];
    const fiorinMode = rootGetters['auth/isFiorinMode'];

    const bountyProgramAvailable =
      rootGetters['settings/settings']?.bountyProgramAvailable;

    let resultBalanceUsd = 0;
    let tokenWithMaxBalanceAmount;

    if (isLoggedIn) {
      if (isFiorin) {
        resultBalanceUsd = rootGetters['fiorin/totalAssetsAmountUsd'];
        tokenWithMaxBalanceAmount =
          rootGetters['fiorin/maxBalanceToken/amountUsd'];
      } else if (isHandCash) {
        const amountBsv = rootGetters['handcash/amountBsv'];
        const amountUsd = rootGetters['handcash/amountUsd'];

        resultBalanceUsd = amountBsv ? amountBsv * bsvPrice() : amountUsd;

        tokenWithMaxBalanceAmount = resultBalanceUsd;
      } else {
        throw new Error(
          'Unknown provider in getAccountBalance: ' +
            rootGetters['auth/provider']
        );
      }
    } else {
      // demoMode
      resultBalanceUsd = 990;
      tokenWithMaxBalanceAmount = resultBalanceUsd;
    }

    const bountyBalanceUsd = fiorinMode
      ? bountyProgramAvailable &&
        !store.getters['settings/uiSettings'].turnBountyPoints
        ? rootGetters['bounty/balanceUsd']
        : 0
      : 0;

    // DEMO CALCS START
    let hold_on_pending = 0;
    let hold_on_positions = 0;
    let unrealizedPL = 0;
    let risk = 0;

    if (!isLoggedIn && rootGetters['positions/ready']) {
      const exchangeRate = 1 / (fiorinMode ? 1 : bsvPrice());

      const openedPositions =
        rootGetters['positions/getPositionsByType'][PositionTypes.open] || [];
      const pendingPositions =
        rootGetters['positions/getPositionsByType'][PositionTypes.pending] ||
        [];

      pendingPositions.forEach((pos) => {
        hold_on_pending += !isNaN(pos.margin) ? pos.margin : 0;
      });

      openedPositions.forEach((pos) => {
        const exRate = pos.open_exchange_rate != 1 ? exchangeRate : 1;
        // unrealizedPL += !isNaN(pos.pl) ? pos.pl : 0;
        unrealizedPL += !isNaN(+pos.pl) ? +pos.pl * exRate : 0;

        hold_on_positions += !isNaN(+pos.margin) ? +pos.margin : 0;
        let stop_loss = +pos.stop_loss_price ? +pos.stop_loss : 80;

        if (80 < stop_loss) {
          stop_loss = 80;
        }

        if (!isNaN(pos.margin)) {
          risk += (stop_loss * +pos.margin) / 100;
        }
      });
      // DEMO CALCS END
    }

    const userTradeInfo = rootGetters['session/userTradeInfo'];
    const incomingBalance = rootGetters['positions/getIncomingBalance'];

    const demoTotalWithPL = isLoggedIn
      ? 0
      : BigNumber(
          +hold_on_pending +
            +hold_on_positions +
            +unrealizedPL +
            +bountyBalanceUsd +
            +incomingBalance +
            resultBalanceUsd
        ).toFixed();

    const unrealizedPlValue = userTradeInfo.pl_b;

    // const unrealizedPlValue =
    //   userTradeInfo.pl_b /
    //   (activeConnect.provider === userProviders.fiorin ? 1 : bsvPrice());

    // console.debug('#available #balance', resultBalance);
    return {
      //todo: [Vadim] use currency as postfix
      availableTotal: resultBalanceUsd,
      available: tokenWithMaxBalanceAmount,
      bounty: bountyBalanceUsd,
      hold_on_total: isLoggedIn
        ? userTradeInfo.m_b
        : hold_on_pending + hold_on_positions,
      risk: isLoggedIn ? userTradeInfo.r_b : risk,
      unrealizedPL: isLoggedIn
        ? BigNumber(unrealizedPlValue).toFixed()
        : BigNumber(unrealizedPL).toFixed(),
      total: isLoggedIn
        ? BigNumber(userTradeInfo.m_b + resultBalanceUsd).toFixed()
        : BigNumber(
            +hold_on_pending + +hold_on_positions + resultBalanceUsd
          ).toFixed(),
      totalWithPL: isLoggedIn
        ? BigNumber(
            (userTradeInfo.m_b || 0) +
              (unrealizedPlValue || 0) +
              resultBalanceUsd +
              +bountyBalanceUsd +
              +incomingBalance
          ).toFixed()
        : demoTotalWithPL,
    };
  },

  wsConfig: (state) => state.wsConfig,
};

const types = {
  SET_WS_CONFIG: 'SET_WS_CONFIG',
};

export const userMutations = types;

const mutations = {
  [types.SET_WS_CONFIG](state, config) {
    console.debug('setting ws config #user #mutation #SET_WS_CONFIG', config);
    state.wsConfig = config;
  },
};

const actions = {
  async onAuthenticated({ commit }) {
    try {
      console.debug('#onAuthenticated #user #store');
      await Promise.all([
        updateConfig(commit),
        refreshUserData(store, connApp),
      ]);
    } catch (e) {
      console.debugError('Error onAuthenticated #user #store', e);
      throw e;
    }
  },

  // async refreshBalance({ commit }) {},

  onLogout() {
    console.debug('#onLogout #burger #store');
  },
};

async function updateConfig(commit) {
  try {
    if (!commit) {
      throw new Error('Missing the commit function');
    }
    let config = await connApp.invoke('GetAddresses', 'BSV');
    console.log('config', config);
    commit(types.SET_WS_CONFIG, config);
  } catch (e) {
    console.debugError('Error getting config #user #actions', e);
    throw e;
  }
}

export const cachedPaths = [];
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
