export function initConsole() {
  let index = 0;

  //todo: make false for production
  let enableConsoleDebug = true;

  let prevDate = new Date();

  function getTime() {
    let date = new Date();
    let hr = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let ms = date.getMilliseconds();

    let diff = date - prevDate;
    prevDate = date;
    let diffStr = diff > 1000 ? `+${diff / 1000}s` : `+${diff}ms`;

    return `${hr}:${min}:${sec}:${ms} (${diffStr})`;
  }

  {
    const oldDebugMethod = console.debug;
    console.debug = function () {
      if (enableConsoleDebug) {
        const time = getTime();

        const args = Array.from(arguments);
        fixArguments(args);
        // for better search in console
        args.push('#debug');
        args.push('#DXS');
        args.push('#index-' + index++);

        addSourceToArguments(args);

        args.push('\n ' + time);

        oldDebugMethod.apply(console, args);
      }
    };
  }

  console.debugError = function () {
    if (enableConsoleDebug) {
      const time = getTime();

      const args = Array.from(arguments);
      fixArguments(args);

      // for better search in console
      args.push('#error');
      args.push('#debug');
      args.push('#DXS');
      args.push('#index-' + index++);

      addSourceToArguments(args);

      args.push('\n ' + time);

      console.error.apply(console, args);
    }
  };

  console.debugWithTime = async function (arg1, arg2, arg3, fn) {
    if (enableConsoleDebug) {
      console.debug('#start', arg1, arg2, arg3);
      const startTime = new Date();
      const result = await fn();
      const endTime = new Date();
      const diff = endTime - startTime;
      console.debug('#finish', arg1, arg2, arg3, '\n #time:', diff, '\n');
      return result;
    } else {
      return await fn();
    }
  };

  console.useDebug = function (value) {
    if (typeof value !== 'boolean') {
      throw new Error('console.useDebug accepts only boolean values');
    }
    enableConsoleDebug = value;
  };

  function fixArguments(args) {
    for (let i = 0; i < args.length; i++) {
      let type = typeof args[i];
      if (type === 'object') {
        args[i] = JSON.parse(JSON.stringify(args[i]));
      }
    }
  }

  function addSourceToArguments(args) {
    let e = new Error();
    e.stack = e.stack.split('\n').slice(2).join('\n');
    args.push('\n');
    args.push(e);
  }
}
