import notify from '@/plugins/notify';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useModals } from '@/modules/modals/api';
import { STATS_URL, SWAP_URL } from '@/helpers/enums';

import { CONSTANT_ACTIONS } from '@/config/constantActions';
import { WalletNames } from '@/wallets/common';

// eslint-disable-next-line no-unused-vars
const useUserActions = (_ = null, { emit } = {}) => {
  const store = useStore();
  const { t } = useI18n();
  const { showModal, hideModal, modalsByName } = useModals();

  const EVENTS = {
    close: 'close',
  };

  const settings = computed(() => store.getters['settings/settings']);

  const menuFavourites = computed(
    () => store.getters['settings/menuFavourites']
  );

  const provider = computed(() => store.getters['auth/provider']);

  // todo: [Vadim]
  const connectStatus = computed(() => {
    return store.getters['connectors/connectStatus'];
  });

  const positions = computed(() => store.getters['positions/getPositions']);

  const isHasEverOpenedPosition = computed(() => {
    return positions.value.length > 0;
  });

  const isHandCashProfile = computed(() => {
    // todo: [Vadim] why we check email to determine if it is handcash?
    return (
      provider.value === WalletNames.handCash && store.getters['handcash/email']
    );
  });

  const isEmailVerified = () =>
    Boolean(settings.value.emailConfirmed) ||
    Boolean(provider.value === WalletNames.fiorin) ||
    Boolean(!provider.value);

  const notifyEmailRequired = () => {
    notify({
      text: t('order.new.toasts.emailRequired'),
      type: 'info',
    });
    showModal(modalsByName.email);
  };

  const notifyHandCashBalance = () => {
    showModal(modalsByName.handCashNotify);
  };

  const exportHistory = () => {
    if (!isEmailVerified()) {
      notifyEmailRequired();
      return;
    }
    connApp
      .invoke('RequestClosedPositionsReport')
      .then(() => {
        notify({
          text: t('popups.emailVerify.toasts.checkEmail'),
          type: 'info',
        });

        if (emit) {
          emit(EVENTS.close);
        }
      })
      .catch((error) => {
        notify({
          text: getErrorDetails(error).message,
          type: 'info',
        });
      });
  };

  const openCryptoTab = () => {
    window.open(SWAP_URL, '_blank');
  };

  const openStats = () => {
    window.open(STATS_URL, '_blank');
  };

  const showCrowdfundingModal = () => {
    showModal('features');
  };

  const openLiquidity = () => {
    showModal(modalsByName.liquidityMain);
  };

  const removeLiquidityFromFavourites = () => {
    if (menuFavourites.value.includes(CONSTANT_ACTIONS.PROVIDE_LIQUIDITY)) {
      store.dispatch(
        'settings/toggleMenuFavouriteItem',
        CONSTANT_ACTIONS.PROVIDE_LIQUIDITY
      );
    }
  };

  const removeSwitchAccountFromFavourites = () => {
    if (menuFavourites.value.includes(CONSTANT_ACTIONS.SWITCH_ACCOUNT)) {
      store.dispatch(
        'settings/toggleMenuFavouriteItem',
        CONSTANT_ACTIONS.SWITCH_ACCOUNT
      );
    }
  };

  const addLiquidityToFavourites = () => {
    if (!menuFavourites.value.includes('provideLiquidity')) {
      store.dispatch('settings/toggleMenuFavouriteItem', 'provideLiquidity');
    }
  };

  const addTopUpWalletToFavourites = () => {
    store.dispatch('settings/toggleMenuFavouriteItem', [
      'topUpWallet',
      'provideLiquidity',
    ]);
  };

  watch(
    () => connectStatus.value,
    (value) => {
      if (!value) {
        showModal(modalsByName.lostConnectionStatus);
        return;
      }
      hideModal(modalsByName.lostConnectionStatus);
    },
    {
      deep: true,
    }
  );

  return {
    exportHistory,
    openLiquidity,
    openCryptoTab,
    openStats,
    showCrowdfundingModal,
    removeLiquidityFromFavourites,
    removeSwitchAccountFromFavourites,
    addLiquidityToFavourites,
    addTopUpWalletToFavourites,
    isEmailVerified,
    notifyEmailRequired,
    notifyHandCashBalance,
    positions,
    isHandCashProfile,
    isHasEverOpenedPosition,
  };
};

export default useUserActions;
