<template>
  <div class="liquidity-links">
    <!-- FAQ 
    <div
      v-if="!hideExplorer"
      class="decoration--underlined liquidity-links__item"
    >
      <a
        class="decoration--underlined"
        href="https://faq.dxs.app/"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ $t('popups.liquidityFundraiser.faq') }}
      </a>
    </div>
    -->
    <!-- MECHANICS
    <div
      v-if="!hideMechanics"
      @click="$emit('showMechanics')"
      class="decoration--underlined liquidity-links__item"
    >
      {{ $t('popups.liquidityFundraiser.risks') }}
    </div>
    -->
    <div class="decoration--underlined liquidity-links__item">
      <a
        class="decoration--underlined"
        href="https://youtu.be/hBHJeMk1ecs"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ $t('popups.liquidityFundraiser.introduction') }}
      </a>
    </div>
    <div class="decoration--underlined liquidity-links__item">
      <a
        class="decoration--underlined"
        href="https://pool.dxs.app"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ $t('popups.liquidityFundraiser.teaser') }}
      </a>
    </div>
    <div class="decoration--underlined liquidity-links__item">
      <a
        class="decoration--underlined"
        href="https://faq.dxs.app/getting-started/get-started-as-a-liquidity-provider"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ $t('popups.liquidityFundraiser.guide') }}
      </a>
    </div>
    <div
      v-if="!hideAllocation"
      @click="$emit('showAllocationModal')"
      class="decoration--underlined liquidity-links__item"
    >
      {{ $t('popups.liquidityFundraiser.allocation1') }}
    </div>
    <div
      v-if="!hideRefund"
      @click="$emit('showRefundModal')"
      class="decoration--underlined liquidity-links__item"
    >
      {{ $t('popups.liquidityFundraiser.refund') }}
    </div>
    <div
      v-if="
        $isLoggedIn && !$isFiorin && userFunded && transfersList?.users?.length
      "
      @click="$emit('showTransferLiquidity')"
      class="decoration--underlined liquidity-links__item"
    >
      {{ $t('popups.liquidityFundraiser.transferLiqudity') }}
    </div>
    <div class="decoration--underlined liquidity-links__item">
      <a
        class="underline cursor-pointer text-xs-sm"
        href="https://faq.dxs.app/policies/liquidity-provider-agreement"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ $t('popups.liquidityFundraiser.agreement') }}
      </a>
    </div>
  </div>
</template>
<script>
import authMixin from '@/mixins/auth.mixin';

export default {
  name: 'LiquidityLinks',
  mixins: [authMixin],
  props: {
    hideExplorer: { type: Boolean, default: false },
    hideRefund: { type: Boolean, default: false },
    hideAllocation: { type: Boolean, default: false },
    hideMechanics: { type: Boolean, default: false },
    transfersList: {
      type: Object,
    },
    userFunded: {
      type: Number,
    },
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.liquidity-links {
  display: flex;
  flex-direction: column;

  &__item {
    margin-bottom: 12px;
    font-family: Gotham_Pro_Regular;
    margin-top: 0.5rem;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    cursor: pointer;

    a {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
    }
  }
}
</style>
