<template>
  <div class="laptop">
    <!-- LEFT-->
    <div class="blocks left">
      <!-- ACCOUNT, MARKET INFO, ETC -->
      <div class="mb-10">
        <AccountInfo is-web />
      </div>
      <div class="mb-10">
        <FormPosition />
      </div>
      <div class="markets left box mb-10">
        <Markets />
      </div>
      <div class="mb-10 box">
        <MarketInfo />
      </div>
      <div v-if="$store.getters['auth/isLoggedIn']" id="v-step-8" class="box">
        <SessionInfo />
      </div>
    </div>
    <!-- RIGHT -->
    <div class="right">
      <!-- CHART -->
      <div class="chart box">
        <div class="card__title--menu" @click="(e) => e.stopPropagation()">
          <Menu />
        </div>
        <Card
          :title="$parent.chartTitle"
          :replace-str-upper="$parent.chartMarketName"
          no-first-letter-parse-title
          :auto-width="true"
          :is-toggle="true"
          stop-web-paddings
          :body-class="'card-full'"
        >
          <Chart1 />
        </Card>
      </div>
      <!-- BOTTOM -->
      <div class="row mt-10">
        <!-- POSITIONS -->
        <div class="positions">
          <Positions />
        </div>
      </div>
    </div>
    <v-tour name="laptop-tour" :steps="steps" :options="tourOptions" />
  </div>
</template>
<script>
import FormPosition from '@/modules/formPosition/FormPosition.vue';
import Markets from '@/modules/markets/Markets.vue';
import AccountInfo from '@/modules/accountInfo/AccountInfo.vue';
import MarketInfo from '@/modules/marketInfo/MarketInfo.vue';
import SessionInfo from '@/modules/session/SessionInfo.vue';
import Positions from '@/modules/positions/Positions.vue';
import Chart1 from '@/components/ui/Chart1.vue';
import Card from '@/components/ui/Card.vue';
import Menu from '@/components/app/Menu.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LaptopLayout',
  components: {
    FormPosition,
    Markets,
    AccountInfo,
    MarketInfo,
    SessionInfo,
    Positions,
    Chart1,
    Card,
    Menu,
  },
  data() {
    return {
      steps: [
        {
          target: '#v-step-0',
          content: 'Here are the markets you can trade.',
          params: {
            placement: 'right-start',
          },
          before() {
            setTimeout(() => {
              document.documentElement.scrollTop = 0;
            }, 0);
          },
        },
        {
          target: '#v-step-1',
          content: 'Please select a market category.',
          params: {
            placement: 'right-start',
          },
          before() {
            setTimeout(() => {
              document.documentElement.scrollTop = 300;
            }, 0);
          },
        },
        {
          target: '#v-step-2',
          content: 'Please click the market you wish to trade.',
          params: {
            placement: 'right-start',
          },
          before() {
            setTimeout(() => {
              document.documentElement.scrollTop = 300;
            }, 0);
          },
        },
        {
          target: '#v-step-3',
          content:
            'Here you can conduct technical analysis on the selected market.',
          params: {
            placement: 'bottom-start',
          },
          before() {
            setTimeout(() => {
              document.documentElement.scrollTop = 0;
            }, 0);
          },
        },
        {
          target: '#v-step-4',
          content: `Before opening the trade please study the 'Market data' panel, each asset has different terms. Please view these numbers and their meaning.`,
          params: {
            placement: 'right-start',
          },
        },
        {
          target: '#v-step-5',
          content: `This is where you create positions. Go long (buy/green) or short (sell order/red).`,
          params: {
            placement: 'right-start',
          },
          before() {
            setTimeout(() => {
              document.documentElement.scrollTop = 0;
            }, 0);
          },
        },
        {
          target: '#v-step-5',
          content: `The amount is equal to margin (collateral) multiplied by leverage (max by default). Click 'More' on the right side of the 'Buy/Sell' button to define entry price, change leverage or set 'Target/Stop' in advance.`,
          params: {
            placement: 'right-start',
          },
          before() {
            setTimeout(() => {
              const buyButton = document.querySelector(
                '.positon-order-btns__btn.buy'
              );
              buyButton.click();

              setTimeout(() => {
                const moreButton = document.querySelector(
                  '.form-position__controls-btn.toggle'
                );
                moreButton.click();
              }, 1000);

              document.documentElement.scrollTop = 0;
            }, 10);
          },
        },
        {
          target: '#v-step-6',
          content: `Here you can see all of your open, pending or historic positions. You can click on any of your positions to see details, manage 'Target/Stop' prices or split the position into two or more new positions to be managed separately.`,
          params: {
            placement: 'top-start',
          },
          before() {
            setTimeout(() => {
              document.documentElement.scrollTop = 0;
            }, 0);
          },
        },
        {
          target: '#v-step-7',
          content: `Here you can see your wallet balance, pending and current positions as well as current risk. You can also see your Grand Total, your unrealized profit/loss and unsettled profits.`,
          params: {
            placement: 'right-start',
          },
        },
        {
          target: '#v-step-8',
          content: `This panel shows current session liquidity,
          i.e. how much communal profit available for all traders to win.
          It is calculated based on the current trading session
          profit/loss balance and coverage from the liquidity pool.
          Profits and losses are settled against each other every 8 hours.`,
          params: {
            placement: 'right-start',
          },
        },
      ],
      tourOptions: {
        highlight: true,
        useKeyboardNavigation: false,
        labels: {
          buttonPrevious: 'Back',
          buttonNext: 'Next',
          buttonStop: 'Finish',
        },
      },
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.laptop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  .blocks {
    &.left {
      width: 350px;
    }
  }

  .right {
    position: relative;
    width: calc(100% - 360px);
    overflow: hidden;
    margin-left: 10px;
  }

  .chart {
    width: 100%;
  }

  .row {
    display: flex;
    width: 100%;
  }

  .positions {
    width: 100%;
  }

  .markets {
    height: calc(768px - 200px); // 367px;
    overflow: auto;
  }

  .block {
    width: 350px;
    height: 350px;
    background: $color-card-background-light;
  }

  .mt-10 {
    margin-top: $px10;
  }

  .mb-10 {
    margin-bottom: $px10;
  }

  .ml-10 {
    margin-left: $px10;
  }
}
</style>
