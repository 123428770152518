import { connMarkets } from '@/helpers/signalR';
import { logMessage } from './helpers';

export class Requester {
  constructor(headers) {
    if (headers) {
      this._headers = headers;
    }
  }
  async sendRequest(datafeedUrl, urlPath, params = {}) {
    // jfyi: code from library;
    // if (params !== undefined) {
    //   const paramKeys = Object.keys(params);
    //   if (paramKeys.length !== 0) {
    //     urlPath += '?';
    //   }
    //   urlPath += paramKeys
    //     .map((key) => {
    //       return `${encodeURIComponent(key)}=${encodeURIComponent(
    //         params[key].toString()
    //       )}`;
    //     })
    //     .join('&');
    // }
    // logMessage('New request: ' + urlPath);
    // // Send user cookies if the URL is on the same origin as the calling script.
    // const options = { credentials: 'same-origin' };
    // if (this._headers !== undefined) {
    //   options.headers = this._headers;
    // }
    // eslint-disable-next-line no-restricted-globals
    const method = `udf/${urlPath}`;

    if (urlPath === 'config' || urlPath === 'time') {
      return await await connMarkets.invoke(method);
    }

    logMessage('New request: %j', { method, params });

    return await connMarkets.invoke(method, params);
  }
}
