const types = {
  SET_OPEN_RANDOM_POSITION_FLAG: 'SET_OPEN_RANDOM_POSITION_FLAG',
};

const state = {
  randomTrade: {
    amountBtc: '0.0998',
    entryPrice: '',
    leverage: 1,
    marginBsv: 0.0998,
    marginValue: 0.0998,
    stopLoss: '',
    takeProfit: '',
  },
  openRandomPositionFlag: false,
};

const getters = {
  randomTrade: (state) => state.randomTrade,
  openRandomPositionFlag: (state) => state.openRandomPositionFlag,
};

const mutations = {
  [types.SET_OPEN_RANDOM_POSITION_FLAG](state, value) {
    state.openRandomPositionFlag = value;
  },
};

const actions = {
  setOpenRandomPositionFlag({ commit }, value) {
    commit(types.SET_OPEN_RANDOM_POSITION_FLAG, value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
