<template>
  <UseNativeApp v-if="isAndroid()" />
  <div class="dashboard">
    <Modal
      v-if="modalStatus(modalsByName.positionCard)"
      :stop-mobile-max-height="true"
      to-top
      modal-class="stickToUp"
      @close="resetCurrentPosition"
    >
      <Card
        :title="
          getCurrentPosition.type === 'history'
            ? $t('popups.orderCloseConfirm.titleClosedPosition')
            : $t('popups.orderCloseConfirm.titleEditPosition')
        "
        :description="
          $t('popups.orderCloseConfirm.titleEditPositionDescription', {
            market: marketTicker(getCurrentPosition.market).toUpperCase(),
            amount: `$${
              getCurrentPosition.base_currency === 'USD'
                ? getFormatedAmount(getCurrentPosition.amount, true)
                : toCurrencyFormat(getCurrentPosition.amount)
            }`,
            side: getCurrentPosition.side,
            num: `#${getCurrentPosition.id}`,
          })
        "
        :replace-str-upper="marketTicker(getCurrentPosition.market)"
        no-first-letter-parse-title
        :body-class="'card-full'"
      >
        <PositionsCard @close="resetCurrentPosition" />
      </Card>
      <RelatedTransactions
        v-if="showRelated"
        :tx-link="
          getCurrentPosition.open_tx
            ? `https://whatsonchain.com/tx/${getCurrentPosition.open_tx}`
            : ''
        "
        @close="showRelated = false"
      />
    </Modal>
    <LiquidityModal
      v-if="modalStatus(modalsByName.liquidityMain)"
      @close="hideModal(modalsByName.liquidityMain)"
    />
    <ProfitRebate
      v-if="modalStatus(modalsByName.profitRebate)"
      :stop-mobile-max-height="true"
      to-top
      modal-class="stickToUp"
      @close="hideModal(modalsByName.profitRebate)"
    />
    <PhoneVerification
      v-if="modalStatus(modalsByName.phoneVerification)"
      stop-mobile-height
      @close="hideModal(modalsByName.phoneVerification)"
    />
    <Modal
      v-if="modalStatus(modalsByName.positionClose)"
      :stop-mobile-max-height="true"
      @close="hideModal(modalsByName.positionClose)"
    >
      <PositionsCloseConfirm />
    </Modal>
    <Weave v-if="false" @close="hideModal('weave')" />
    <DotwalletUsers v-if="showDWUsers" @close="showDWUsers = false" />
    <Announcements
      v-if="showAnnouncements && isLoggedIn"
      :show-active="true"
      :active-announcements-from-parent="activeAnnouncements"
      @close="closeAnnouncements"
    />
    <NewBountyModal
      v-if="modalStatus(modalsByName.getBounty)"
      @close="hideModal(modalsByName.getBounty)"
    />
    <BountyProfits
      v-if="modalStatus(modalsByName.bountyProfits)"
      @close="hideModal(modalsByName.bountyProfits)"
    />
    <EmailVerification
      v-if="modalStatus(modalsByName.email)"
      @close="hideModal(modalsByName.email)"
    />
    <TourModal
      v-if="modalStatus(modalsByName.tour)"
      @close="hideModal(modalsByName.tour)"
    />
    <Login
      v-if="modalStatus(modalsByName.login)"
      @close="hideModal(modalsByName.login)"
    />
    <RestrictedJurisdiction
      v-if="modalStatus(modalsByName.usaAgree)"
      @close="hideModal(modalsByName.usaAgree)"
    />
    <BreakOutTrade
      v-if="modalStatus(modalsByName.breakoutTrade)"
      @close="onBreakOutTradeClose"
    />
    <SubCentProfit
      v-if="modalStatus(modalsByName.subCentProfit)"
      @close="hideModal(modalsByName.subCentProfit)"
    />
    <BountyReceived
      v-if="modalStatus(modalsByName.bountyReceived)"
      @close="hideModal(modalsByName.bountyReceived)"
    />
    <StandaloneAttention
      v-if="modalStatus(modalsByName.standaloneAttention)"
      @close="hideModal(modalsByName.standaloneAttention)"
    />
    <PWAModal
      v-if="modalStatus(modalsByName.pwaModal)"
      @close="hideModal(modalsByName.pwaModal)"
    />
    <BountySharing
      v-if="modalStatus(modalsByName.bountySharing)"
      @close="hideModal(modalsByName.bountySharing)"
    />
    <AndroidPwaPrompt
      v-if="modalStatus(modalsByName.pwaAndroidPrompt)"
      :install-event="installEvent"
      @close="hideModal(modalsByName.pwaAndroidPrompt)"
    />
    <EarnedLiquidity
      v-if="modalStatus(modalsByName.earnedLiquidity)"
      @close="hideModal(modalsByName.earnedLiquidity)"
    />
    <StoriesEarnPoints
      v-if="modalStatus(modalsByName.tutorialHistory)"
      @close="hideModal(modalsByName.tutorialHistory)"
    />
    <StoriesOpenAnyTrade
      v-if="modalStatus(modalsByName.openAnyTrade)"
      @close="hideModal(modalsByName.openAnyTrade)"
    />
    <StoriesSharePosition
      v-if="modalStatus(modalsByName.sharePosition)"
      @close="hideModal(modalsByName.sharePosition)"
    />
    <SmallAmountModal
      v-if="modalStatus(modalsByName.smallAmount)"
      @close="hideModal(modalsByName.smallAmount)"
    />
    <ConfirmRemovePositions
      v-if="modalStatus(modalsByName.confirmRemovePositions)"
      @close="hideModal(modalsByName.confirmRemovePositions)"
    />
    <OpenRandomPosition
      v-if="openRandomPositionFlag"
      @close="$store.dispatch('randomTrade/setOpenRandomPositionFlag', false)"
    />
    <Unauthorized v-if="modalStatus(modalsByName.unauthorized)" />
    <ConnectModal v-if="showConnectModal" />
    <NewVersion v-if="showNewVersion" @close="showNewVersion = false" />
    <router-view name="Login" />
    <DesktopLayout v-if="scope.isXlarge" />
    <LaptopLayout v-else-if="scope.isLarge" />
    <MobileLayout v-else />
  </div>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import PositionsCard from '@/modules/positions/components/PositionsCard';
import PositionsCloseConfirm from '@/modules/positions/components/PositionsCloseConfirm';
import ProfitRebate from '@/components/modals/ProfitRebate';
import NewVersion from '@/components/modals/NewVersion';
import BreakOutTrade from '@/components/modals/BreakOutTrade';
import SubCentProfit from '@/components/modals/SubCentProfit';
import DesktopLayout from '@/layouts/DesktopLayout';
import LaptopLayout from '@/layouts/LaptopLayout';
import MobileLayout from '@/layouts/MobileLayout';
import DotwalletUsers from '@/components/modals/DotwalletUsers';
import LiquidityModal from '@/modules/liquidity';
import RestrictedJurisdiction from '@/components/modals/RestrictedJurisdiction';
import EmailVerification from '@/components/modals/EmailVerification';
import ConfirmRemovePositions from '@/components/modals/ConfirmRemovePositions';
import Unauthorized from '@/components/modals/Unauthorized';
import StandaloneAttention from '@/components/modals/StandaloneAttention';
import PWAModal from '@/components/modals/PWAModal';
import BountySharing from '@/components/modals/BountySharing';
import Login from '@/modules/user/Login';
import UseNativeApp from '@/components/app/UseNativeApp.vue';
import EarnedLiquidity from '@/components/modals/YouEarnedLiquidity';
import StoriesEarnPoints from '@/components/modals/stories/StoriesEarnPoints';
import StoriesOpenAnyTrade from '@/components/modals/stories/StoriesOpenAnyTrade';
import StoriesSharePosition from '@/components/modals/stories/StoriesSharePosition';
import NewBountyModal from '@/components/modals/NewBountyModal';
import BountyProfits from '@/components/modals/BountyProfits';
import OpenRandomPosition from '@/components/modals/OpenRandomPosition';

import SmallAmountModal from '@/components/modals/SmallAmountModal';
import { banCountires, whiteListAfterBan } from '@/config/countries';
import { initChaport } from '@/plugins/chaport';
import { toCurrencyFormat, getFormatedAmount } from '@/helpers/utils';
import ConnectModal from '@/components/modals/ConnectModal';

import {
  computed,
  watch,
  defineComponent,
  onBeforeUnmount,
  ref,
  onMounted,
} from 'vue';
import Radio from '@/components/app/Radio';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { scope } from '../breakpoints';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useModals } from '@/modules/modals/api';
import Announcements from '@/components/modals/Announcements';
import PhoneVerification from '@/components/modals/PhoneVerification';
import TourModal from '@/components/modals/TourModal';
import Weave from '@/components/modals/Weave';

import RelatedTransactions from '@/components/modals/RelatedTransactions';
import { connApp } from '@/helpers/signalR';
// import { isHistoryPosition } from '@/modules/positions/helpers';
import useDemopositions from '@/compositions/useDemopositions';
import { APP_ROUTE_NAMES } from '@/helpers/enums';
import { usePosition } from '@/compositions/usePosition';
import { useInitialize } from '@/compositions/useInitialize';
import useBalance from '@/compositions/useBalance';
import {
  setActiveMarketFromRoute,
  setRouteFromActiveMarket,
} from '@/helpers/activeMarketRoute';
import { marketTicker } from '@/config/wrapMarkets';
import { getSleepInfo } from '@/helpers/sleep';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import BountyReceived from '@/components/modals/BountyReceived';
import {
  // isIOS,
  isAndroid,
  // hasStandaloneSupport,
  // installedStandalone,
} from '@/helpers/detects';
import { userProviders } from '@/modules/user/api';
import AndroidPwaPrompt from '@/components/modals/AndroidPwaPrompt';

export default defineComponent({
  name: 'Dashboard',
  components: {
    AndroidPwaPrompt,
    BountyReceived,
    Modal,
    Card,
    Login,
    DotwalletUsers,
    BreakOutTrade,
    LiquidityModal,
    Announcements,
    TourModal,
    NewBountyModal,
    BountyProfits,
    Weave,
    PositionsCard,
    PositionsCloseConfirm,
    ProfitRebate,
    NewVersion,
    DesktopLayout,
    LaptopLayout,
    MobileLayout,
    RelatedTransactions,
    RestrictedJurisdiction,
    EmailVerification,
    PhoneVerification,
    SubCentProfit,
    StandaloneAttention,
    PWAModal,
    BountySharing,
    UseNativeApp,
    EarnedLiquidity,
    StoriesEarnPoints,
    StoriesOpenAnyTrade,
    StoriesSharePosition,
    SmallAmountModal,
    ConfirmRemovePositions,
    ConnectModal,
    Unauthorized,
    OpenRandomPosition,
  },
  setup() {
    const store = useStore();
    const installEvent = ref(null);
    const { initMarkets, woofHandler, initTelegram } = useInitialize();
    const route = useRoute();
    const { t } = useI18n();
    const positionComposition = usePosition({});
    const showDWUsers = ref(false);
    const { fetchWalletBalance } = useBalance();
    const showNewVersion = ref(false);

    const showAnnouncements = ref(false);
    const activeAnnouncements = ref([]);

    const { initialDemoPositions } = useDemopositions();

    const showRelated = ref(false);

    const location = computed(() => store.getters['usa/getLocation']);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const showConnectModal = computed(
      () => store.getters['localUiSettings/showConnectModal']
    );

    const setActiveMarket = () => {
      setActiveMarketFromRoute();
      setRouteFromActiveMarket();
    };

    let marketsUpdateInterval;

    onMounted(async () => {
      console.debug('#dashboard #onMounted');

      // todo: [Vadim] : why it is here? It subscribes many times when resizing window
      connApp.once('unauthorized', () => {
        console.debug(
          'subscribed connApp.once "unauthorized" #Dashboard #onMounted'
        );
        showModal(modalsByName.unauthorized);
      });

      // todo: [Vadim] why it's here?
      await initMarkets();

      // if (
      //   isIOS() &&
      //   hasStandaloneSupport() &&
      //   !installedStandalone() &&
      //   activeConnect.value.provider === userProviders.fiorin &&
      //   !localStorage.getItem('showPWAModal')
      // ) {
      //   setTimeout(() => {
      //     showModal(modalsByName.pwaModal);
      //   }, 2000);
      // }
      // document.body.style.overflow = '';

      if (!isLoggedIn.value && !localStorage.getItem('setInitialDarkTheme')) {
        localStorage.setItem('setInitialDarkTheme', true);
        store.dispatch('burger/changeTheme', { value: 'dark' });
      }

      if (document.body.classList.contains('twilight')) {
        document.body.classList.remove('twilight');
        store.dispatch('burger/changeTheme', { value: 'dark' });
      }

      if (!isLoggedIn.value) {
        store.dispatch('session/updateInfo', 'USD', { root: true });
      }

      marketsUpdateInterval = setInterval(async () => {
        if (!document[getSleepInfo().hidden]) {
          await initMarkets();
        }
      }, 60000);

      if (
        router.currentRoute.name !== APP_ROUTE_NAMES.dashboard &&
        (scope.isLarge || scope.isXlarge)
      ) {
        setActiveMarket();
      }
      activeAnnouncements.value = await connApp.invoke('GetAnnouncements', {
        onlyActive: true,
      });

      const readedAnnouncements =
        JSON.parse(localStorage.getItem('readedAnnouncements')) || {};
      activeAnnouncements.value = activeAnnouncements.value.filter(
        (ann) => !readedAnnouncements[ann.id]
      );

      if (activeAnnouncements.value && activeAnnouncements.value.length) {
        showAnnouncements.value = true;
      }

      setTimeout(() => {
        if (activeConnect.value.provider === userProviders.fiorin) {
          if (uiSettings.value.needSetCurrencyBountyProfits) {
            // showModal(modalsByName.bountyProfits);
          }
        }
      }, 6000);

      initChaport();
    });

    const closeAnnouncements = () => {
      showAnnouncements.value = false;
      const readedAnnouncements =
        JSON.parse(localStorage.getItem('readedAnnouncements')) || {};
      activeAnnouncements.value.forEach((ann) => {
        readedAnnouncements[ann.id] = true;
      });
      localStorage.setItem(
        'readedAnnouncements',
        JSON.stringify(readedAnnouncements)
      );
    };

    const { showModal, hideModal, modalStatus, modalsByName } = useModals();

    const tdxpAPPTitle = ref('Hello, DXS 2.0');

    store.dispatch('settings/initFromLs');

    // check email confirm url
    if (route.query.id && route.query.code) {
      store.dispatch('email/confirmUserEmail', {
        userId: route.query.id,
        code: route.query.code,
      });
    }

    if (route.fullPath.includes('authToken') && activeConnect.value.provider) {
      router.push('/');
    }

    initialDemoPositions();

    // initConnectors('Dashboard').then((result) => {
    //   showDWUsers.value = result.showDWUsers;
    // });

    // todo :vadim: showDWUsers.value should be true if provider is DotWallet

    initTelegram();

    if (!activeConnect.value.provider) {
      // showModal(modalsByName.landing);
    }

    Radio.$on('woof', woofHandler);

    onBeforeUnmount(() => {
      Radio.$off('woof', woofHandler);
      clearInterval(marketsUpdateInterval);
    });

    const chartTitle = computed(() => {
      const market = store.getters['markets/activeMarket'];
      return market ? `${marketTicker(market.name)} CHART` : 'CHART';
    });

    const chartMarketName = computed(() => {
      const market = store.getters['markets/activeMarket'];
      return marketTicker(market?.name) || '';
    });

    const getCurrentPosition = computed(
      () => store.getters['positions/getCurrentPosition']
    );

    const resetCurrentPosition = () => {
      hideModal(modalsByName.positionCard);
    };

    const onBreakOutTradeClose = () => {
      hideModal(modalsByName.breakoutTrade);
      localStorage.setItem('showBreakOutTradeModal', true);
    };

    const currentPositionTitle = computed(() => {
      const position = getCurrentPosition.value;
      const positionState = positionComposition.getPositionState(position);

      return t(`order.card.titles.positionState.${positionState}`, {
        market: marketTicker(position.market),
        side: position.side.toUpperCase(),
        id: position.id,
      });
    });

    const authInfo = computed(() => store.getters['user/truedexAuth']);
    const phoneConfirmed = computed(
      () => store.getters['settings/settings']?.phoneNumberConfirmed
    );

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const openRandomPositionFlag = computed(
      () => store.getters['randomTrade/openRandomPositionFlag']
    );

    watch(
      () => authInfo.value && authInfo.value.userId,
      async () => {
        hideModal(modalsByName.landing);
        fetchWalletBalance();

        if (activeConnect.value.provider === userProviders.fiorin) {
          await store.dispatch('bounty/updateBountyTasks');
          await store.dispatch('bounty/updateBalance');
          const bountyTasks = await connApp.invoke('ListBountyTasks');
          store.dispatch('bounty/setNewBountyBalance', bountyTasks);

          // if (!localStorage.getItem('showPWAModalAndroid')) {
          //   window.addEventListener('beforeinstallprompt', (e) => {
          //     e.preventDefault();
          //     installEvent.value = e;
          //     showModal(modalsByName.pwaAndroidPrompt);
          //   });
          // }
        }
        store.dispatch('session/toggleGrandTotalLoader');
        localStorage.setItem('dxsLogined', true);

        await store.dispatch('usa/fetchIPAddress', { root: true });
        const usaFlag = localStorage.getItem('usa');

        // if (phoneConfirmed.value) {
        //   await updateAndSetUserSettings({
        //     uiSettings: {
        //       blockedFromIp: null,
        //     },
        //   });
        // }
        if (whiteListAfterBan.includes(location.value?.countryCode)) {
          await updateAndSetUserSettings({
            uiSettings: {
              blockedFromIp: null,
              emailVerificationLater: null,
            },
          });
        }

        const prohibitedInterval = setInterval(() => {
          const settingsFetched = JSON.stringify(
            store.getters['settings/settings'] !== '{}'
          );
          if (
            activeConnect.value.provider &&
            !usaFlag &&
            banCountires.includes(location.value?.countryCode) &&
            !phoneConfirmed.value &&
            settingsFetched
          ) {
            showModal(modalsByName.usaAgree);

            clearInterval(prohibitedInterval);
          }
        }, 2500);

        setTimeout(() => {
          store.dispatch('connectors/setAuthLoader', false);
        }, 3500);
      }
    );

    watch(
      () => scope,
      () => {
        setActiveMarket();
      },
      {
        deep: true,
      }
    );

    return {
      showNewVersion,
      isLoggedIn,
      showAnnouncements,
      activeAnnouncements,
      tdxpAPPTitle,
      scope,
      showModal,
      hideModal,
      modalStatus,
      modalsByName,
      chartTitle,
      chartMarketName,
      getCurrentPosition,
      currentPositionTitle,
      resetCurrentPosition,
      setActiveMarket,
      showRelated,
      closeAnnouncements,
      showDWUsers,
      onBreakOutTradeClose,
      installEvent,
      isAndroid,
      marketTicker,
      toCurrencyFormat,
      getFormatedAmount,
      showConnectModal,
      openRandomPositionFlag,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';

.dashboard {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: $px10 $px10 0 $px10;
}

@media screen and (max-width: 1023px) {
  .dashboard {
    padding: 0;
  }
}

@media (max-width: 480px) {
}
</style>
