import { getModalFlag } from '@/helpers/storage';
import store from '@/store';

export function useModals() {
  const modalsByName = {
    bountyTrading: 'Bounty-Trading',
    bountySharing: 'Bounty-Sharing',
    bountyReceived: 'Bounty-Received',
    profitRecovery: 'Profit-Recovery',
    accountDeletion: 'Account-Deletion',
    marketData: 'Market-Data',
    landing: 'Landing',
    tour: 'Site-Tutorial',
    tradingSession: 'Trading-Session',
    termsAndConditions: 'Terms-and-Conditions',
    login: 'Login',
    logout: 'Logout',
    prohibitedPersons: 'Prohibited-Persons',
    privacyPolicy: 'Privacy-Policy',
    email: 'Email',
    announcements: 'Announcements',
    preLogin: 'PreLogin',
    cookiesPolicy: 'Cookies-Policy',
    KYC: 'AML-Policy',
    riskStatement: 'Risk-Statement-Policy',
    reviews: 'Reviews',
    profitRebate: '_Profit-Deduction',
    positionClose: '_Position-Close-Confirm',
    positionCard: '_Position-Card',
    breakoutTrade: '_Breakout-Trade',
    liquidityMain: 'Liquidity',
    liquidityConfirmRefund: 'Liquidity-Confirm-Refund',
    liquidityAllocation: 'Liquidity-Allocation',
    liquidityProvide: 'Liquidity-Provide',
    liquidityMechanics: 'Liquidity-Mechanics',
    liquidityPaymentReceived: 'Liquidity-Payment-Received',
    liquidityRefund: 'Liquidity-Refund-on-Demand',
    swaps: 'Swaps',
    handCashNotify: 'HandCash-Notify',
    usaAgree: '_Usa-Agree',
    verification: 'KYC-Verification',
    phoneVerification: 'Phone-Verification',
    kycStatus: 'KYC-status',
    lostConnectionStatus: 'Lost-Connection-status',
    subCentProfit: '_Sub-Cent-Profit',
    standaloneAttention: 'Standalone-Attention',
    pwaModal: 'Pwa',
    pwaAndroidPrompt: 'PwaAndroidPrompt',
    transferLiquidity: 'Transfer-Liquidity',
    earnedLiquidity: 'EarnedLiquidity',
    tutorialHistory: 'TutorialHistory',
    openAnyTrade: 'OpenAnyTrade',
    sharePosition: 'SharePosition',
    getBounty: 'GetBounty',
    bountyProfits: 'BountyProfits',
    smallAmount: 'SmallAmount',
    confirmRemovePositions: 'ConfirmRemovePositions',
    unauthorized: 'Unauthorized',
    chooseCollateral: 'Choose-collateral',
  };

  const showModal = (modalName, value, { force, usdOnly } = {}) => {
    const localStorageModalFlag = getModalFlag(modalName);
    const fiorinModalFlag = store.getters['auth/isFiorinMode'];

    const availableForWallet = usdOnly ? fiorinModalFlag : true;

    if ((!localStorageModalFlag || force) && availableForWallet) {
      store.dispatch('modals/toggleModal', { k: modalName, v: value || true });
    }
  };
  const hideModal = (modalName) => {
    store.dispatch('modals/toggleModal', { k: modalName, v: false });
  };
  const modalStatus = (modalName) =>
    store.getters['modals/modalStatus'](modalName) || false;

  return { showModal, hideModal, modalStatus, modalsByName };
}
