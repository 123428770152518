import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import EventEmitter from 'events';
import config from '@/config';
import { v4 as uuidv4 } from 'uuid';
import { waitUntil } from '@/helpers/retry';

const RECONNECT_TIMEOUT = 1000;

// const dispatchCloseAndReload = () => {
//   window.document.dispatchEvent(
//     new CustomEvent('closeLogin', { bubbles: true })
//   );
//   window.location.reload();
// };

class SignalRConnection extends EventEmitter {
  constructor(host, withAuth) {
    super();

    this.host = host;
    // todo: [Vadim] why need this field?
    this.connected = false;
    this.withAuth = withAuth;
    this._provider = null;
    this._accessToken = null;
    this._userId = null;

    this._connection = new HubConnectionBuilder()
      .withUrl(this._buildConnectionString(), {
        accessTokenFactory: withAuth ? this._accessTokenFactory : undefined,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => Math.random() * RECONNECT_TIMEOUT,
      })
      .configureLogging(LogLevel.Critical)
      .build();

    this._connection.onclose(this._onDisconnect);
    this._connection.onreconnected(this._onReconnecting);
    this._connection.onreconnecting((error) => {
      if (error) {
        console.log({ type: 'Reconnected', error });
      } else {
        this.emit('connect');
      }
    });

    // [vadim]: analyse it.
    // When signalR is stopped, it may collect calls, waiting for connect,
    // and invoke all the calls at once, what may lead to a lot of requests at once
    // document.addEventListener('visibilitychange', this._ensureInValidState);
    // window.addEventListener('focus', this._ensureInValidState);
  }

  _onDisconnect = () => {
    this.connected = false;

    console.log('Socket connection has closed');

    // todo: [vadim] not needed if stopped
    console.log(`Will try again in ${RECONNECT_TIMEOUT}ms`);
  };

  _onReconnecting = () => {
    this._onDisconnect();
  };

  // todo: [Vadim] check if this is needed
  // _ensureInValidState = async () => {
  //   if (document.visibilityState !== 'visible') {
  //     return;
  //   }
  //
  //   // disabled while debugging access tokens
  //   // // todo: [Vadim] check if this is needed
  //   // if (!this._hasAccessToken()) {
  //   //   await this._connection.stop();
  //   //   console.debug('Stopping connection because no access token #SignalRConnection #_ensureInValidState #_hasAccessToken');
  //   //   return;
  //   // }
  //
  //   if (
  //     this._connection.state === HubConnectionState.Disconnected ||
  //     this._connection.state === HubConnectionState.Disconnecting
  //   ) {
  //     this.connected = false;
  //
  //     await this.start();
  //   }
  // };

  _buildConnectionString = () => {
    let uuid = localStorage.getItem('dxsUUID');

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('dxsUUID', uuid);
    }

    return this.host + `?browserId=${uuid}`;
  };

  setAccessData = (provider, accessToken, userId) => {
    this._provider = provider;
    this._accessToken = accessToken;
    this._userId = userId;
  };

  _getAccessData = () => {
    if (!this._accessToken || !this._provider) {
      console.debug(
        'No provider or accessToken provided #SignalRConnection #getAccessData'
      );

      return {
        provider: null,
        accessToken: null,
        userId: null,
      };
    }

    return {
      provider: this._provider,
      accessToken: this._accessToken,
      userId: this._userId,
    };
  };

  _hasAccessToken = () => Boolean(this._getAccessData().accessToken);

  _buildBearerToken = (provider, accessToken) => `${provider}:${accessToken}`;

  _accessTokenFactory = () => {
    const { provider, accessToken, userId } = this._getAccessData();

    if (!accessToken) {
      return;
    }

    this._userId = userId;

    return this._buildBearerToken(provider, accessToken);
  };

  // some wierd cases to verify
  // setTimeout(() => {
  //   if (!provider || !store.getters['connectors/activeConnect']?.provider) {
  //     return;
  //   }

  //   if (activeConnect.provider === 'Fiorin') {
  //     if (provider !== store.getters['connectors/activeConnect']?.provider) {
  //       dispatchCloseAndReload();
  //     }

  //     return;
  //   }

  //   if (
  //     accessToken !== store.getters['connectors/activeConnect']?.accessToken
  //   ) {
  //     dispatchCloseAndReload();
  //   }
  // }, RECONNECT_TIMEOUT);

  start = async () => {
    try {
      if (this._connection.state !== HubConnectionState.Connected) {
        await this._connection.start();
        this.emit('connect');
        this.connected = true;
      }
    } catch (err) {
      this.connected = false;
      if (err.statusCode === 401) {
        // means accessToken is invalid, need to delete accessToken from store and relogin
        this.unauthorized = true;
        this.emit('unauthorized');

        return 'unauthorized';
      } else {
        console.error(
          'Error when start connection #signalR #start, error:',
          err
        );
        throw err;
      }
    }
  };

  isSameUserConnected = (userId) => userId === this._userId;

  stop = async () => {
    if (
      this._connection.state !== HubConnectionState.Disconnected &&
      this._connection.state !== HubConnectionState.Disconnecting
    ) {
      try {
        await this._connection.stop();
      } catch {
        // error may happen when connection is not in 'Connected' state
        // even if we check it before, it still can happen
        // it's ok, just swallow it
      }
    }
  };

  restart = async () => {
    console.debug('#SignalRConnection #restart');

    await this.stop();
    await this.start();

    const connected = await waitUntil(
      '#SignalRConnection #restart',
      5000, // 5 sec,
      200,
      () => this._connection.state === HubConnectionState.Connected
    );

    if (!connected) {
      throw new Error('Connection was not established');
    }
  };

  subscribe = (methodName, callback) => {
    this._connection.off(methodName, callback);
    this._connection.on(methodName, callback);
  };

  invoke = async (methodName, ...args) => {
    // console.log({ type: 'invoke', methodName, args });

    if (this.unauthorized) {
      throw new Error(
        'Unable to invoke method because unauthorized. Method: ' + methodName
      );
    }

    try {
      const result = await this._connection.invoke(methodName, ...args);
      return result;
    } catch (e) {
      console.error(
        `Error when invoke, state: ${this._connection.state}, method "${methodName}", #SignalRConnection #invoke, error:`,
        e
      );
      throw e;
    }
  };
}

export function getErrorDetails(err) {
  try {
    // parse SignalR hardcoded string
    var msgJson = (
      err.message.match(/HubException: (.*)/) || [err.message]
    ).pop();
    return JSON.parse(msgJson);
  } catch (e) {
    return { id: null, message: err.message };
  }
}

export const connMarkets = new SignalRConnection(config.wsUrl + 'markets');
export const connApp = new SignalRConnection(config.wsUrl + 'app', true);
