<template>
  <Modal @close="$emit('close')" to-top stop-mobile-max-height>
    <div class="phone-verification-wrap">
      <Card :title="modalTitle" class="card">
        <div v-if="!agreedToVerify" class="phone-verification">
          <div class="phone-verification__info mt-20">
            {{ $t('popups.phoneVerification.info') }}
          </div>
        </div>
        <div v-else class="phone-verification">
          <div class="phone-verification__info">
            {{ $t('popups.phoneVerification.bounty') }}
          </div>
          <SimpleInput
            new-version
            :value="phone"
            type="tel"
            directive="tel"
            :error="phoneError"
            :pattern="/^[\+]?[(]?[0-9]*[)]?[-\s\.]?[0-9]*[-\s\.]?[0-9]*$/"
            :green-title="settings.phoneNumberConfirmed"
            :title="
              settings.phoneNumberConfirmed
                ? $t('popups.emailVerify.confirmed')
                : $t('popups.phoneVerification.phone')
            "
            @onChange="onChangePhone"
            class="phone-verification-input phone"
          />
          <SimpleInput
            new-version
            :value="code"
            type="number"
            directive="number"
            :disabled="!codeSended"
            :title="$t('popups.phoneVerification.code')"
            @onChange="onChangeCode"
            class="phone-verification-input"
          />
          <div class="phone-verification__info mt-20">
            {{ $t('popups.phoneVerification.robot') }}
          </div>
          <div class="phone-verification__info mt-20">
            {{ $t('popups.phoneVerification.important') }}
          </div>
        </div>
      </Card>
      <div
        v-if="!agreedToVerify"
        :class="{ standalone: wd.navigator.standalone || isTelegram }"
        class="btns"
      >
        <Button
          :text="$t('popups.phoneVerification.buttonLater')"
          type="secondary"
          class="btn"
          @click="shutDown"
        />
        <Button
          :text="$t('popups.phoneVerification.buttonVerify')"
          type="secondary"
          class="btn"
          @click="agreeToVerify"
        />
      </div>
      <div
        v-else-if="!codeSended || tryAgainFlag"
        :class="{ standalone: wd.navigator.standalone || isTelegram }"
        class="btns"
      >
        <Button
          :text="$t('popups.phoneVerification.SMS')"
          :loading="loadingSendCode"
          :disabled="!phone"
          type="secondary"
          class="btn"
          @click="sendCode(codeTypes.SMS)"
        />
        <Button
          :text="$t('popups.phoneVerification.WhatsApp')"
          :loading="loadingSendCode"
          :disabled="!phone"
          type="secondary"
          class="btn"
          @click="sendCode(codeTypes.WHATSAPP)"
        />
      </div>
      <div
        v-else
        :class="{ standalone: wd.navigator.standalone || isTelegram }"
        class="btns"
      >
        <Button
          :text="
            $t('popups.phoneVerification.buttonTryAgain', { time: timer || '' })
          "
          :disabled="timer"
          type="secondary"
          class="btn"
          @click="tryAgain"
        />
        <Button
          :text="$t('popups.phoneVerification.buttonVerify')"
          :loading="loadingVerifyCode"
          :disabled="!code"
          type="secondary"
          class="btn"
          @click="verifyCode"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from 'axios';
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import SimpleInput from '@/components/ui/SimpleInput';
import notify from '@/plugins/notify';
import { useI18n } from 'vue-i18n';
import { userProviders } from '@/modules/user/api';
import {
  getUserSettings,
  updateAndSetUserSettings,
} from '@/modules/settings/api';
import { setModalFlag } from '@/helpers/storage';
import { useModals } from '@/modules/modals/api';
import { useRoute } from 'vue-router';

export default {
  components: { Modal, Card, Button, SimpleInput },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { modalsByName } = useModals();
    const route = useRoute();

    const codeTypes = {
      WHATSAPP: 'WhatsApp',
      SMS: 'Sms',
    };

    const phone = ref('');
    const code = ref('');
    const loadingSendCode = ref(false);
    const loadingVerifyCode = ref(false);
    const agreedToVerify = ref(false);
    const codeSended = ref(false);

    const settings = computed(() => store.getters['settings/settings']);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const isHandCashNewProfile = computed(() => {
      return (
        !settings.value.phoneNumberConfirmed &&
        activeConnect.value.provider === userProviders.handCash &&
        activeConnect.value.profile?.privateProfile?.phoneNumber
      );
    });

    const modalTitle = computed(() => {
      return agreedToVerify.value
        ? t('popups.phoneVerification.titleVerify').toLowerCase()
        : t('popups.phoneVerification.titleFee').toLowerCase();
    });

    const agreeToVerify = () => {
      agreedToVerify.value = true;
    };

    const onChangePhone = (val) => {
      phone.value = val.replace(/[A-Za-z\s-]/g, '');
      phoneError.value = '';
    };

    const onChangeCode = (val) => {
      code.value = val;
    };

    const shutDown = async () => {
      setModalFlag(modalsByName.phoneVerification, true);
      await updateAndSetUserSettings({
        uiSettings: {
          bountyPaused: true,
        },
      });

      const phoneConfirmed =
        store.getters['settings/settings']?.phoneNumberConfirmed;

      if (!phoneConfirmed) {
        store.commit('bounty/SET_BALANCE', 0);
      }

      emit('close');
    };

    const timer = ref(30);
    let intervalId = null;

    const setCountDown = () => {
      clearInterval(intervalId);

      intervalId = setInterval(() => {
        if (timer.value !== 0) {
          return timer.value--;
        }

        clearInterval(intervalId);
      }, 1000);
    };

    const tryAgainFlag = ref(false);
    const tryAgain = () => {
      clearInterval(intervalId);
      timer.value = 30;
      tryAgainFlag.value = true;
    };

    const phoneError = ref('');

    const sendCode = async (codeType) => {
      try {
        setCountDown();
        tryAgainFlag.value = false;
        loadingSendCode.value = true;

        await axios.put(
          `${process.env.VUE_APP_API_URL}user/phone-number/change`,
          {
            phoneNumber: phone.value,
            codeType,
          },
          {
            headers: {
              Authorization: `Bearer ${activeConnect.value.accessToken}`,
            },
          }
        );

        codeSended.value = true;

        getUserSettings();

        notify({
          text: t('popups.phoneVerification.codeSended'),
          type: 'info',
        });
      } catch (error) {
        const isPhoneError = error.response?.data?.error?.id === 'PhoneNumber';

        if (isPhoneError) {
          phoneError.value = error.response?.data?.error?.message;
        } else {
          notify({
            text:
              error.response?.data?.error?.message ||
              error.response?.data?.errors?.PhoneNumber[0] ||
              error.message,
            type: 'warning',
          });
        }
      } finally {
        loadingSendCode.value = false;
      }
    };

    const verifyCode = async () => {
      if (!code.value) {
        notify({ text: 'Invalid code', type: 'info' });
        return;
      }

      loadingVerifyCode.value = true;

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}User/phone-number/confirm`,
          code.value,
          {
            headers: {
              'Content-Type': 'application/json-patch+json',
              Authorization: `Bearer ${activeConnect.value.accessToken}`,
            },
          }
        );

        codeSended.value = true;

        await updateAndSetUserSettings({
          uiSettings: {
            blockedFromIp: null,
          },
        });

        notify({
          text: t('popups.phoneVerification.codeVerified'),
          type: 'info',
        });

        await store.dispatch('bounty/updateBountyTasks');
        await store.dispatch('bounty/updateBalance');

        emit('close');
      } catch (error) {
        notify({
          text: error.response?.data?.error?.message || error.message,
          type: 'warning',
        });
      } finally {
        loadingVerifyCode.value = false;
      }
    };

    const useHandCashPhone = async () => {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}User/phone-number/confirm/force`,
          null,
          {
            headers: {
              Authorization: `Bearer ${activeConnect.value.accessToken}`,
            },
          }
        );

        getUserSettings();

        notify({
          text: t('popups.phoneVerification.codeVerified'),
          type: 'info',
        });

        emit('close');
      } catch (error) {
        notify({
          text: error.response?.data?.error?.message || error.message,
          type: 'warning',
        });
      }
    };

    const setHandCashPhone = () => {
      if (!phone.value && isHandCashNewProfile.value) {
        phone.value = activeConnect.value.profile.privateProfile.phoneNumber;
      }
    };

    const isHandCashPhone = computed(() => {
      return (
        phone.value === activeConnect.value.profile?.privateProfile?.phoneNumber
      );
    });

    onMounted(() => {
      phone.value = settings.value.phoneNumber;

      if (route?.query?.stage === '2') {
        agreeToVerify();
      }

      setHandCashPhone();
    });

    watch(
      () => settings.value.phone,
      () => {
        phone.value = settings.value.phoneNumber;
      }
    );

    watch(
      () => activeConnect.value.profile,
      () => {
        setHandCashPhone();
      }
    );

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      isTelegram,
      wd: window,
      phoneError,
      modalTitle,
      timer,
      tryAgain,
      tryAgainFlag,
      phone,
      code,
      sendCode,
      verifyCode,
      codeSended,
      agreedToVerify,
      agreeToVerify,
      onChangeCode,
      isHandCashPhone,
      onChangePhone,
      useHandCashPhone,
      shutDown,
      loadingSendCode,

      isHandCashNewProfile,
      activeConnect,

      settings,
      codeTypes,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.phone-verification-wrap {
  .card {
    min-height: 530px;

    @media screen and (max-width: 1024px) {
      min-height: 100vh;
    }
  }

  .phone-verification {
    padding-bottom: 60px;

    &-label {
      margin-top: 10px;
      color: $color-green;
      font-family: Gotham_Pro_Regular;
    }

    &__info {
      font-family: Gotham_Pro_Regular;
      font-size: 0.9375rem;
      color: $color-gray;
      line-height: $default-lineheight;

      &.mt-20 {
        margin-top: 20px;
      }

      &.mt-10 {
        margin-top: 10px;
      }
    }

    .phone-verification-input {
      margin-top: 14px;

      &.phone {
        margin-top: 24px;
      }

      width: calc(100% + 15px);
      margin-left: -6px;
    }

    .phone-verification-label {
      margin-top: 10px;
      color: $color-green;
      font-family: Gotham_Pro_Regular;
    }
  }

  .btn {
    width: 100%;
    margin: 0 1px;
  }

  .btns {
    width: 100%;
    margin-top: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      bottom: 50px;

      &.standalone {
        bottom: 82px;
      }
    }
  }
}
</style>
