'use strict';

async function sleep(timeoutMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeoutMs);
  });
}

let lastTrackId = Math.floor(10000 * Math.random());

async function waitUntil(name, timeoutMs, intervalMs, conditionFn) {
  if (!name) {
    throw new Error('name is required');
  }
  if (!(intervalMs > 0)) {
    throw new Error('intervalMs must be greater than 0');
  }
  if (!(timeoutMs > 0)) {
    throw new Error('timeoutMs must be greater than 0');
  }
  if (intervalMs > timeoutMs) {
    throw new Error('intervalMs must be less than timeoutMs');
  }
  if (!conditionFn) {
    throw new Error('conditionFn is required');
  }

  let passed = conditionFn();
  if (passed instanceof Promise) {
    passed = await passed;
  }

  if (passed) {
    return true;
  }

  let trackId = 't' + ++lastTrackId;
  let label = `${name} #waitUntil trackId: #${trackId} `;

  console.debug(
    `Waiting until the condition is met, ${label}, timeout: ${timeoutMs} ms, sleepMs: ${intervalMs} ms #waitUntilEntered`
  );

  const maxDate = Date.now() + timeoutMs;

  do {
    let passed = conditionFn();
    if (passed instanceof Promise) {
      passed = await passed;
    }

    if (passed) {
      console.debug(`Condition is met #waitUntil2_ConditionMet ${label}`);
      return true;
    }

    await sleep(intervalMs);
  } while (Date.now() < maxDate);

  console.error(`Timeout for waiting a condition #waitUntil2_Timeout ${label}`);

  return false;
}

export { waitUntil, sleep };
