<template>
  <div
    id="v-step-3"
    :class="{ telegram: isTelegram }"
    class="chart-container box"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width="28"
      height="28"
      class="fullscreen-btn"
      @click="toggleFullscreen"
    >
      <g fill="currentColor">
        <path d="M21 7v4h1V6h-5v1z"></path>
        <path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path>
        <path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path>
        <path d="M16.854 16.146l5 5-.708.708-5-5z"></path>
        <g>
          <path d="M7 21v-4H6v5h5v-1z"></path>
          <path d="M11.146 16.146l-5 5 .708.708 5-5z"></path>
        </g>
      </g>
    </svg>
    <div
      v-show="isChartReady"
      :id="containerId"
      :class="{
        standalone:
          wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
      }"
      class="TVChartContainer"
    />
    <Loader v-show="!isChartReady" class="chart-container__loader" />
  </div>
</template>

<script>
import Loader from './Loader';
import { mapGetters, mapActions, useStore, mapMutations, mapState } from 'vuex';
import envConfig from '@/config';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { UDFCompatibleDatafeed } from '@/helpers/chart/lib/udf-compatible-datafeed.js';
import { computed } from 'vue';
import { graphTypes, mapPositionToColors, mapTheme } from './Chart/mappers';
import { getWidgetOptions, stylesToOverride } from './Chart/getWidgetOptions';
import { waitUntil } from '@/helpers/retry';
import { widget } from '@/../public/charting_library_v28';
import axios from 'axios';
import { PositionSides } from '@/config/constants';
import { getFormatedAmount, toCurrencyFormat } from '@/helpers/utils';
import { useModals } from '@/modules/modals/api';

const LATEST_CHART_NAME = 'Latest DXS';

export default {
  name: 'Chart',
  emits: ['symbolHasChanged', 'onGraphClick'],
  setup() {
    const store = useStore();
    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      wd: window,
      isRunningStandaloneAndroid,
      isTelegram,
    };
  },
  components: {
    Loader,
  },
  props: {
    containerId: {
      default: 'tv_chart_container',
      type: String,
    },
    datafeedUrl: {
      default: 'https://demo_feed.tradingview.com',
      type: String,
    },
    libraryPath: {
      default: '/charting_library_v28/charting_library/',
      type: String,
    },
    chartsStorageUrl: {
      default: process.env.VUE_APP_API_URL.slice(0, -1),
      type: String,
    },
    chartsStorageApiVersion: {
      default: 'v1.0',
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    mobile: Boolean,
  },
  data() {
    return {
      ...useModals(),
      linesPositions: [],
      isChartReady: false,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.settings.uiSettings.theme,
    }),

    ...mapGetters({
      activeMarket: 'markets/activeMarket',
      activeMarketId: 'markets/activeMarketId',
      currentGraphType: 'graph/getCurrentGraphType',
      loading: 'graph/showOverlay',
      userId: 'auth/userId',
      activeMarketPositions: 'positions/getActiveMarketPositions',
    }),

    widgetOptions() {
      return getWidgetOptions({
        datafeed: new UDFCompatibleDatafeed(
          `${envConfig.apiUrl}trading-view/udf`,
          1000
        ),
        symbol: this.activeMarket.name,
        container: this.containerId,
        library_path: this.libraryPath,
        charts_storage_url: this.chartsStorageUrl,
        charts_storage_api_version: this.chartsStorageApiVersion,
        fullscreen: this.fullscreen,
        user_id: this.userId,
        // debug: true,
      });
    },
  },

  async mounted() {
    await Promise.all([
      this.waitStrictly(() => this.activeMarket),
      this.waitStrictly(() => this.activeMarketPositions),
    ]);

    const tvWidget = new widget(this.widgetOptions);

    this.tvWidget = tvWidget;

    let latestData;

    if (this.userId) {
      latestData = await this.fetchLatestSavedChart();
    }

    tvWidget.onChartReady(async () => {
      if (latestData) {
        console.debug('#CHART', 'load data', latestData);
        tvWidget.load(latestData);
      }

      tvWidget.headerReady().then(() => this.listenChartHeaderReady());
      tvWidget.subscribe('mouse_down', () => this.listenChartMouseDown());
      tvWidget.subscribe('onAutoSaveNeeded', () => this.listenChartAutoSave());
      tvWidget.subscribe('chart_loaded', () => this.listenChartLoaded());

      this.redrawPositions();
      await this.changeTheme();

      document.addEventListener(
        'fullscreenchange',
        this.handleSystemFullscreen
      );

      this.container = document.querySelector('.chart-container');
      this.isChartReady = true;
    });
  },

  beforeUnmount() {
    this.tvWidget.remove();
    this.tvWidget = null;

    document.body.removeEventListener(
      'fullscreenchange',
      this.handleSystemFullscreen
    );
  },

  watch: {
    userId() {
      this.delayTasksForReadyChart(async () => {
        const data = await this.fetchLatestSavedChart();

        this.tvWidget.load(data);
        await this.changeTheme();
      });
    },

    currentGraphType() {
      const subscribers =
        this.tvWidget._options.datafeed._dataPulseProvider._subscribers;

      const currentSubscriberKey = Object.keys(subscribers).find((key) =>
        key.includes(this.activeMarket.name)
      );

      const currentSubscriber = subscribers[currentSubscriberKey];

      currentSubscriber?.onResetCacheNeededCallback?.();
      this.tvWidget.activeChart().resetData();
    },

    activeMarketId() {
      this.delayTasksForReadyChart(() => {
        this.tvWidget.activeChart().setSymbol(this.activeMarket.name, {
          dataReady: () => {
            const innerApi = this.tvWidget._innerAPI();

            this.previousOpts = innerApi._saveChartService.saveToJSON();
            this.redrawPositions();
          },
        });
      });
    },

    theme() {
      this.changeTheme();
    },
  },

  methods: {
    ...mapActions({
      setCurrentPosition: 'positions/setCurrentPosition',
      changeGraphType: 'graph/changeGraphType',
    }),

    ...mapMutations({
      updateActiveMarket: 'markets/UPDATE_ACTIVE_MARKET',
    }),

    listenChartMouseDown() {
      this.$emit('onGraphClick');
    },

    listenChartLoaded() {
      const innerApi = this.tvWidget._innerAPI();
      const { symbol } = innerApi._saveChartService.saveToJSON();

      this.$emit('symbolHasChanged', symbol);
    },

    listenChartAutoSave() {
      const innerApi = this.tvWidget._innerAPI();
      const currentOpts = innerApi._saveChartService.saveToJSON();
      const onlySymbolHasChanged =
        (this.previousOpts || currentOpts).symbol !== currentOpts.symbol;

      this.previousOpts = currentOpts;

      console.debug(
        '#CHART',
        'options',
        currentOpts,
        JSON.parse(currentOpts.content)
      );

      // TODO: if current chart name in not Latest then skip autosave flow

      if (onlySymbolHasChanged) {
        console.debug(
          '#CHART',
          'only symbol has changed, skip autosaving flow'
        );

        return;
      }

      console.debug('#CHART', 'saving...');

      return this.saveChartToServer()
        .then(() => console.debug('#CHART', 'Chart has been saved to server'))
        .catch((err) =>
          console.error('#CHART', 'Unable to load data from server', err)
        );
    },

    listenChartHeaderReady() {
      const buttons = [
        [graphTypes.BID, this.createGraphButton(graphTypes.BID)],
        [graphTypes.MID, this.createGraphButton(graphTypes.MID)],
        [graphTypes.ASK, this.createGraphButton(graphTypes.ASK)],
      ];

      buttons.forEach(([, button]) => {
        button.addEventListener('click', ({ target }) => {
          const activeGraphType = target.textContent;

          this.changeGraphType(activeGraphType);
          this.recalculateGraphButtons(activeGraphType);
        });
      });

      this.graphButtons = buttons;

      this.recalculateGraphButtons(this.currentGraphType);
    },

    async delayTasksForReadyChart(task) {
      await this.waitStrictly(() => this.tvWidget);

      return new Promise((resolve, reject) => {
        this.tvWidget.onChartReady(() => {
          // eslint-disable-next-line prettier/prettier
          Promise.resolve()
            .then(task)
            .then(resolve)
            .catch(reject);
        });
      });
    },

    redrawPositions() {
      const positions = this.activeMarketPositions || [];
      const self = this;

      this.tvWidget.activeChart().dataReady(() => {
        positions.forEach((position) => {
          const { mainColor, secondColor } = mapPositionToColors(
            this.theme,
            position
          );
          const line = this.tvWidget.activeChart().createPositionLine();

          line
            .setLineLength(100)
            .setLineStyle(position.state === PositionSides.PROPOSED ? 2 : 0)
            .setLineColor(secondColor)
            .setBodyTextColor(secondColor)
            .setBodyBorderColor(secondColor)
            .setBodyBackgroundColor(mainColor)
            .setQuantityBorderColor(secondColor)
            .setQuantityBackgroundColor(secondColor)
            .setQuantityTextColor(mainColor)
            .setQuantityFont('bold 16pt Verdana')
            .setBodyFont('bold 16pt Verdana')
            .setPrice(position.entry_price)
            .setProtectTooltip('Edit position')
            .onModify({ number: position.id }, async () => {
              localStorage.setItem('hideTradeButton', true);

              await self.openPositionCard({ position });
            })
            .setQuantity(
              `    $${
                position.base_currency === 'USD'
                  ? getFormatedAmount(position.amount, true)
                  : toCurrencyFormat(position.amount, null, 4)
              }    `
            )
            .setText('');
        });
      });
    },

    recalculateGraphButtons(activeGraphType) {
      this.graphButtons.forEach(([graphType, button]) => {
        button.classList.remove(
          ...['_active-graph-type-disable', '_active-graph-type']
        );
        button.classList.add(
          activeGraphType === graphType
            ? '_active-graph-type'
            : '_active-graph-type-disable'
        );
      });
    },

    createGraphButton(graphType) {
      const button = this.tvWidget.createButton({ align: 'left' });

      button.classList.add('graph-type');
      button.setAttribute('title', `PRICE ${graphType}`);
      button.textContent = graphType;

      return button;
    },

    async getSavedChartData(chartId) {
      const {
        data: { data: savedData },
      } = await axios.get(
        `${process.env.VUE_APP_API_URL}v1.0/charts?client=${this.widgetOptions.client_id}&user=${this.userId}&chart=${chartId}`
      );

      return savedData;
    },

    async getSavedCharts() {
      const {
        data: { data: savedCharts },
      } = await axios.get(
        `${process.env.VUE_APP_API_URL}v1.0/charts?client=${this.widgetOptions.client_id}&user=${this.userId}`
      );

      return savedCharts;
    },

    async fetchLatestSavedChart() {
      const savedCharts = await this.getSavedCharts();

      console.debug('#CHART', 'loaded charts', savedCharts);

      const [latestChart] = savedCharts
        .filter((chart) => chart.name === LATEST_CHART_NAME)
        .sort((a, b) => b.id - a.id);

      if (!latestChart) {
        console.debug('#CHART', 'No saved charts to load from server. Skip');

        return;
      }

      console.debug('#CHART', 'latest chart', latestChart);

      this.chartIdToRewrite = latestChart.id;

      const data = await this.getSavedChartData(latestChart.id);

      console.debug('#CHART', 'loaded data', data);

      return JSON.parse(data.content);
    },

    async saveChartToServer() {
      const innerApi = this.tvWidget._innerAPI();
      const { content, symbol, resolution } =
        innerApi._saveChartService.saveToJSON();

      const form = {
        name: LATEST_CHART_NAME,
        content,
        symbol,
        resolution,
      };

      console.debug(
        '#CHART',
        'before save state',
        form,
        JSON.parse(form.content)
      );

      const formData = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const url = this.chartIdToRewrite
        ? `${process.env.VUE_APP_API_URL}v1.0/charts?client=${this.widgetOptions.client_id}&user=${this.userId}&chart=${this.chartIdToRewrite}`
        : `${process.env.VUE_APP_API_URL}v1.0/charts?client=${this.widgetOptions.client_id}&user=${this.userId}`;

      const { data } = await axios.post(url, formData);

      console.debug('#CHART', 'manual saving result', data);
    },

    async changeTheme() {
      return this.delayTasksForReadyChart(async () => {
        this.tvWidget.changeTheme(mapTheme(this.theme), { disableUndo: true });

        // We need to apply overrides in next tick after tvWidget.changeTheme method
        // and delayTasksForReadyChart fits for it
        return this.delayTasksForReadyChart(() =>
          this.tvWidget.applyOverrides(stylesToOverride)
        );
      });
    },

    async openPositionCard(payload) {
      await this.setCurrentPosition(payload);

      this.showModal(this.modalsByName.positionCard);
    },

    handleSystemFullscreen() {
      if (document.fullscreenElement) {
        this.container.classList.add('fullscreen');

        return;
      }

      this.container.classList.remove('fullscreen');
    },

    async toggleFullscreen() {
      if (!document.fullscreenElement) {
        await document.body.requestFullscreen();

        return;
      }

      await document.exitFullscreen();
    },

    async waitStrictly(predicate) {
      const timeLimit = 30_000;
      const result = await waitUntil(
        '#Chart #waitRequiredConditions',
        timeLimit,
        100,
        predicate
      );

      if (!result) {
        throw new Error(
          `Waited ${timeLimit} ang got nothing for predicate=${predicate.toString()}`
        );
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.TVChartContainer {
  height: 100%;
  border-top: 1px solid #eaeaea;

  &.standalone {
    height: calc(100% - 30px);
  }
}

.chart-container {
  position: relative;
  height: 350px;

  &.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
  }

  .fullscreen-btn {
    position: absolute;
    right: 10px;
    top: 6px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media screen and (max-width: 1023px) {
    height: calc(100% - 52px);

    &.telegram {
      margin-top: 78px;
      height: calc(100% - 100px);
    }
  }

  @media screen and (min-width: 1440px) {
    height: 50vh;
  }
}

body.dark {
  .TVChartContainer {
    border-top: 1px solid #5a5f755c;
  }
}
</style>
