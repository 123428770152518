<template>
  <Modal @close="onClose" to-top stop-mobile-max-height>
    <div
      :class="{ verified: hasEmailConfirmed }"
      class="email-verification-wrap"
    >
      <Card :title="$t('popups.emailVerify.title')" class="card">
        <div class="email-verification">
          <div class="email-verification__info mt-20">
            {{
              hasEmailConfirmed
                ? $t('popups.emailVerify.confirmedLong')
                : $t('popups.emailVerify.confirm')
            }}
          </div>
          <SimpleInput
            new-version
            :value="email"
            type="text"
            :green-title="hasEmailConfirmed"
            :title="
              hasEmailConfirmed
                ? $t('popups.emailVerify.confirmed')
                : $t('popups.emailVerify.titles.email')
            "
            directive="email"
            @blur="onBlur"
            @onChange="onChangeEmail"
            class="email-verification-input email-input"
          />
          <!-- <div v-if="hasEmailConfirmed" class="email-verification-label">
            {{ $t('popups.emailVerify.confirmed') }}
          </div> -->
          <SimpleInput
            new-version
            :value="sendCode"
            :disabled="!showCode"
            type="text"
            :title="$t('popups.emailVerify.titles.sendCode')"
            @onChange="onChangeSendCode"
            class="email-verification-input code-input"
          />
        </div>
      </Card>
      <div
        :class="{ standalone: wd.navigator.standalone || isTelegram }"
        class="btns"
      >
        <Button
          v-if="!sendLink && showLaterBtn && $isFiorin && !hasEmailConfirmed"
          text="LATER"
          type="secondary"
          class="btn"
          @click="later"
        />
        <Button
          v-if="!showCode"
          :text="sendLink ? $t('popups.emailVerify.titles.verify') : btnText"
          :loading="loadingSend"
          :disabled="hasEmailConfirmed"
          type="secondary"
          class="btn"
          @click="sendConfirmation"
        />
        <Button
          v-if="showCode"
          :text="$t('popups.emailVerify.titles.verify')"
          :loading="loadingSendCode"
          type="secondary"
          class="btn"
          @click="onSendCode"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import SimpleInput from '@/components/ui/SimpleInput';
import { useStore } from 'vuex';
import { ref, computed, onMounted, watch } from 'vue';
import notify from '@/plugins/notify';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import { useI18n } from 'vue-i18n';
import isEmail from 'validator/es/lib/isEmail';
import { getUserSettings } from '@/modules/settings/api';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import config from '@/config';
import axios from 'axios';
import get from 'lodash/get';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default {
  mixins: [authMixin],
  components: { Modal, Card, Button, SimpleInput },
  setup(props, { emit }) {
    const auth = useAuth();
    const { t } = useI18n();
    const store = useStore();
    const email = ref('');
    const sendCode = ref('');
    const loadingSend = ref(false);
    const loadingSendCode = ref(false);
    const showCode = ref(false);
    const sendLink = ref(false);

    const settings = computed(() => store.getters['settings/settings']);

    const startEmailVerification = computed(() => {
      return !settings.value.emailConfirmed && settings.value.email;
    });

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const isHandCashNewProfile = computed(() => {
      return (
        !settings.value.emailConfirmed &&
        auth.isHandCash.value &&
        store.getters['handcash/email']
      );
    });

    const later = async () => {
      let countLater = uiSettings.value.emailVerificationLater || 0;

      await updateAndSetUserSettings({
        uiSettings: {
          emailVerificationLater: ++countLater,
        },
      });
      emit('close');
    };

    const onClose = async () => {
      if (auth.isFiorin.value) {
        // later10
        let countLater = uiSettings.value.emailVerificationLater || 0;

        if (countLater < 10) {
          await updateAndSetUserSettings({
            uiSettings: {
              emailVerificationLater: ++countLater,
            },
          });
        }
      }
      emit('close');
    };

    const onBlur = () => {
      email.value = email.value.replaceAll('+', '');
    };

    const btnText = computed(() => {
      return isHandCashNewProfile.value &&
        store.getters['handcash/email'] === email.value
        ? t('popups.emailVerify.useHandCashEmail')
        : t('popups.emailVerify.sendConfirmation');
    });

    const showLaterBtn = computed(() => {
      return (uiSettings.value.emailVerificationLater || 0) < 10;
    });

    const hasEmailConfirmed = computed(() => {
      return (
        email.value === settings.value.email && settings.value.emailConfirmed
      );
    });

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    email.value = settings.value.email;

    const onChangeEmail = (val) => {
      email.value = val.replaceAll('+', '');
    };

    const onChangeSendCode = (val) => {
      sendCode.value = val;
    };

    const onSendCode = async () => {
      if (!sendCode.value?.trim()) {
        const input = window.document.querySelector('.code-input input');
        notify({ text: 'Enter code from email', type: 'info' });
        if (input) {
          setTimeout(() => {
            input.focus();
          }, 300);
        }
        return;
      }

      if (sendCode.value.length !== 6) {
        notify({ text: 'Verification code must be 6 digits', type: 'info' });
        return;
      }
      loadingSendCode.value = true;

      const userId = store.getters['auth/userId'];

      axios
        .post(`${config.apiUrl}User/email/confirm/code`, {
          code: sendCode.value,
          userId: userId,
        })
        .then(
          async (res) => {
            if (res) {
              notify({ text: 'Email verified', type: 'info' });
              loadingSendCode.value = false;
              await getUserSettings();
              emit('close');
            }
          },
          (error) => {
            notify({
              text: get(error, 'response.data.error.message', error),
              type: 'info',
            });
            loadingSendCode.value = false;
          }
        );
    };

    const sendConfirmation = async () => {
      if (!email.value?.trim()) {
        const input = window.document.querySelector('.email-input input');
        notify({ text: 'Enter email', type: 'info' });
        if (input) {
          setTimeout(() => {
            input.focus();
          }, 300);
        }
        return;
      }

      loadingSend.value = true;
      if (!isEmail(email.value || '')) {
        notify({ text: 'Invalid email', type: 'info' });
        loadingSend.value = false;
        return;
      }

      let error;
      const res = await connApp
        .invoke('UpdateUserEmail', email.value)
        .then(async (data) => {
          sendLink.value = true;

          await updateAndSetUserSettings({
            uiSettings: {
              emailVerificationLater: 10,
            },
          });

          if (data.emailConfirmed) {
            notify({
              text: t('popups.emailVerify.confirmed'),
              type: 'info',
            });
            getUserSettings();
            emit('close');
            return;
          }
          notify({ text: 'Check your email', type: 'info' });
          showCode.value = true;
        })
        .catch((err) => {
          console.log(`Unable to update email: ${err.message || err}`);
          error = getErrorDetails(err);
        })
        .finally(() => {
          loadingSend.value = false;
        });

      if (res) {
        notify({
          text: res.id
            ? res.message
            : 'Failed to update email. Please contact support if the issue persists.',
          type: 'info',
        });
      } else if (error) {
        notify({
          text: 'Email already used',
          type: 'info',
        });
      }
    };

    const setHandCashEmail = () => {
      if (!email.value && isHandCashNewProfile.value) {
        email.value = store.getters['handcash/email'];
      }
    };

    onMounted(() => {
      setHandCashEmail();
    });

    watch(
      () => settings.value.email,
      () => {
        email.value = settings.value.email;
      }
    );

    watch(
      () => email.value,
      () => {
        email.value = email.value.replaceAll('+', '');
      }
    );

    watch(
      () => (auth.isHandCash.value ? store.getters['handcash/email'] : null),
      () => {
        setHandCashEmail();
      }
    );

    return {
      later,
      sendConfirmation,
      onChangeEmail,
      onChangeSendCode,
      onBlur,
      onClose,
      sendLink,
      startEmailVerification,
      hasEmailConfirmed,
      btnText,
      isHandCashNewProfile,
      loadingSend,
      email,
      sendCode,
      settings,
      showLaterBtn,
      showCode,
      onSendCode,
      loadingSendCode,
      isTelegram,
      wd: window,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.email-verification-wrap {
  .card {
    min-height: 550px;

    @media screen and (max-width: 1024px) {
      min-height: 100vh;
    }
  }

  .email-verification {
    &__info {
      font-family: Gotham_Pro_Regular;
      font-size: 15px;
      color: $color-gray;
      line-height: $default-lineheight;

      &.mt-20 {
        margin-top: 20px;
      }
    }

    .email-verification-input {
      margin-top: 14px;

      &.email-input {
        margin-top: 24px;
      }

      width: calc(100% + 15px);
      margin-left: -6px;
    }

    .email-verification-label {
      margin-top: 10px;
      color: $color-green;
      font-family: Gotham_Pro_Regular;
    }
  }

  .btns {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;

    @media screen and (max-width: 1024px) {
      bottom: 50px;

      &.standalone {
        bottom: 82px;
      }
    }
  }
}
</style>
