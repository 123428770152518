// todo: [Vadim] move to theme module
const supportedThemeColors = {
  dark: '#141721',
  light: '#fff',
  // twilight: 'rgb(59, 67, 116)',
};
export const setMetaThemeColor = (value) => {
  if (!value) {
    console.debugError('Missing theme value #setMetaThemeColor');
    value = 'dark';
  }

  let color = supportedThemeColors[value];
  if (!color) {
    console.debugError(
      `Unsupported theme value '${value}', using default 'dark' #setMetaThemeColor`
    );
    color = supportedThemeColors.dark;
  }

  document.querySelector("meta[name='theme-color']").content = color;
};

export const parseTitle = (title, replaceStr = '') => {
  if (!title) {
    return title;
  }
  return `${title[0].toUpperCase()}${title
    .toLowerCase()
    .slice(1)
    .replaceAll(replaceStr.toLowerCase(), replaceStr.toUpperCase())}`;
};
