import { computed } from 'vue';
import { useStore } from 'vuex';
import { userProviders } from '@/modules/user/api';

const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

export default function useChangeFilter() {
  const store = useStore();

  const activeConnect = computed(() => {
    return store.getters['connectors/activeConnect'];
  });

  const fetchWalletBalance = async () => {
    if (activeConnect.value) {
      if (
        activeConnect.value.provider === userProviders.fiorin &&
        frames.fiorin
      ) {
        frames.fiorin.postMessage({ event: 'GET_BALANCE' }, FIORIN_HOST);
        frames.fiorin.postMessage({ event: 'GET_BOUNTY_BALANCE' }, FIORIN_HOST);
        return;
      }
      if (
        activeConnect.value.provider === userProviders.relayX &&
        window.relayone
      ) {
        const { satoshis } = await window.relayone.getBalance2();
        activeConnect.value.satoshiBalance = satoshis / 100000000;
        await store.dispatch(
          'connectors/setActiveConnectionInfo',
          activeConnect.value
        );
      } else {
        const connect = activeConnect.value;
        let attempts = 0;
        const MAX_ATTEMPTS = 500; // 25 sec

        while (!connect.fetchBalance && attempts < MAX_ATTEMPTS) {
          await new Promise((res) => setTimeout(res, 50));
          attempts++;
        }
        if (connect.fetchBalance) {
          await connect.fetchBalance();
        }

        await store.dispatch('connectors/setActiveConnectionInfo', connect);
      }
    }
  };

  return { fetchWalletBalance };
}
