<template>
  <ItemLabelValue
    :label="$t('order.card.titles.profitLoss')"
    :is-red="position.pl < 0"
    :is-green="position.pl > 0"
    :value="`${
      (position.pl > 0 ? '+' : '') +
      (position.base_currency === 'USD'
        ? getFormatedAmount(position.pl, true)
        : toCurrencyFormat(position.pl))
    } ${position.base_currency} `"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.profitLossPercent')"
    :is-red="position.pl_percent < 0"
    :is-green="position.pl_percent > 0"
    :value="`${
      (position.pl_percent > 0 ? '+' : '') +
      +(position.pl_percent && position.pl_percent.toFixed(2))
    }%`"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.side')"
    :is-red="position.side === PositionSides.SELL"
    :is-green="position.side === PositionSides.BUY"
    :value="`${position.side.toUpperCase()}`"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.entryPrice')"
    :value="`${
      position.side === PositionSides.BUY
        ? PositionSideTypes.ask
        : PositionSideTypes.bid
    }  ${calcEntryPrice(position)}`"
  />

  <ItemLabelValue
    v-if="
      (position.side === PositionSides.BUY && position.state === 'opened') ||
      (position.side === PositionSides.SELL && position.state === 'proposed')
    "
    :label="$t('order.card.titles.currentPrice')"
    :value="`BID  ${calcCurrentPrice(position, markets)}`"
  />
  <ItemLabelValue
    v-if="
      (position.side === PositionSides.SELL && position.state === 'opened') ||
      (position.side === PositionSides.BUY && position.state === 'proposed')
    "
    :label="$t('order.card.titles.currentPrice')"
    :value="`${PositionSideTypes.ask}  ${calcCurrentPrice(position, markets)}`"
  />

  <ItemLabelValue
    :label="$t('order.card.titles.amount')"
    :value="`${
      position.base_currency === 'USD'
        ? getFormatedAmount(position.amount, true)
        : toCurrencyFormat(position.amount)
    } ${position.base_currency === 'USD' ? 'USD' : 'BSV'}`"
  />
  <ItemLabelValue
    :label="
      $t('order.card.titles.margin') +
      `${position.is_bounty ? ' (bounty)' : ''}`
    "
    :value="`${
      $isFiorinMode && !isFiorinStable(position?.currency)
        ? toUSDMarginFormat(position.margin, 2)
        : toCurrencyFormat(position.margin)
    } ${
      !$isLoggedIn
        ? 'USD'
        : $isFiorinMode
        ? position.is_bounty
          ? 'USD'
          : position?.currency
        : 'BSV'
    }`"
    :hint-options="{ top: '-40px', left: '70px' }"
    :hint-title="$t('order.card.hints.margin')"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.leverage')"
    :value="`${(+toCurrencyFormat(position.leverage))
      .toFixed(1)
      .replace('.0', '')}×`"
    :hint-options="{ top: '-40px', left: '80px' }"
    :hint-title="$t('order.card.hints.leverage')"
  />
  <ItemLabelValue :label="$t('order.card.titles.takeProfit')">
    <Icon
      name="pen"
      width="14"
      height="14"
      class="icon-opacity"
      @click="$emit('setTakeProfit')"
    />
    <div v-if="position.take_profit_price" class="tpsl-label">
      {{
        `${
          position.side === PositionSides.BUY
            ? PositionSideTypes.bid
            : PositionSideTypes.ask
        } ${toCurrencyFormat(
          position.take_profit_price,
          null,
          floatList[position.market]
        )}`
      }}
    </div>
  </ItemLabelValue>

  <ItemLabelValue :label="$t('order.card.titles.stopLoss')">
    <Icon
      name="pen"
      width="14"
      height="14"
      class="icon-opacity"
      @click="$emit('setStopLoss')"
    />
    <div v-if="position.stop_loss_price" class="tpsl-label">
      {{
        `${
          position.side === PositionSides.BUY
            ? PositionSideTypes.bid
            : PositionSideTypes.ask
        } ${toCurrencyFormat(
          position.stop_loss_price,
          null,
          floatList[position.market]
        )}`
      }}
    </div>
  </ItemLabelValue>
  <ItemLabelValue
    :label="$t('order.card.titles.holdingFee')"
    :value="`${toCurrencyFormat(position.decay)} ${
      position.base_currency === 'USD' ? 'USD' : 'BSV'
    }`"
    :hint-options="{ top: '-25px', left: '100px' }"
    :hint-title="$t('order.card.hints.holdingFee')"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.holdingFeeRate')"
    :value="`${
      (position.decay_rate && toCurrencyFormat(position.decay_rate * 100)) ||
      '0'
    }% ${position.is_bounty ? 'x4 if bounty' : ''}`"
    :hint-options="{ top: '-25px', left: '140px' }"
    :hint-title="$t('order.card.hints.holdingFeeRate')"
  />
  <ItemLabelValue
    v-if="+positionLoyaltyFeeValue"
    :label="$t('blocks.titles.loyaltyFee')"
    :hint-options="{ top: '-40px', left: '100px' }"
    :hint-title="$t('blocks.titles.loyaltyFeeHint')"
    :value="`${positionLoyaltyFeeValue?.toFixed(2)} USD`"
  />
  <ItemLabelValue
    v-if="position.isProfitVoided"
    :label="$t('blocks.titles.scalpingFee')"
    :hint-options="{ top: '-40px', left: '100px' }"
    :value="`${toCurrencyFormat(position.scalpingFee)} USD`"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.netProfitLossBsv')"
    :is-red="
      position.pl -
        positionLoyaltyFeeValue -
        (position.rebate || 0) -
        (position.decay || 0) <
      0
    "
    :is-green="
      position.pl -
        positionLoyaltyFeeValue -
        (position.rebate || 0) -
        (position.decay || 0) >
      0
    "
    :value="
      formatNetProfitLoss(
        position.pl -
          positionLoyaltyFeeValue -
          (position.rebate || 0) -
          (position.decay || 0),
        position.base_currency
      )
    "
  />
  <ItemLabelValue
    :label="$t('order.card.titles.liquidationPrice')"
    :value="`${
      position.side === PositionSides.BUY
        ? PositionSideTypes.bid
        : PositionSideTypes.ask
    } ${toCurrencyFormat(
      position.liquidation_price,
      null,
      floatList[position.market]
    )}`"
    :hint-options="{ top: '-40px', left: '140px' }"
    :hint-title="$t('order.card.hints.liquidationPrice')"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.created')"
    :value="showDateFormat(position.proposed)"
  />
  <ItemLabelValue
    :label="$t('order.card.titles.opened')"
    :value="showDateFormat(position.opened)"
  />
  <ItemLabelValue
    v-if="position.rebate"
    :label="$t('order.card.titles.liquidityRebate')"
    :value="`-${toCurrencyFormat(position.rebate)}`"
    :hint-options="{ top: '-100px', left: '135px' }"
    :hint-title="$t('order.card.hints.liquidityRebate')"
  />
</template>

<script>
import {
  toCurrencyFormat,
  getFormatedAmount,
  toUSDMarginFormat,
} from '@/helpers/utils';
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import usePositionCalcs from '@/compositions/usePositionCalcs';
import Icon from '@/components/ui/Icon';
import '@/assets/icons/pen';
import DateTime from 'luxon/src/datetime.js';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { PositionSides } from '@/config/constants';
import { PositionSideTypes } from '@/modules/positions/helpers';
import { formatNetProfitLoss } from '../../formaters';
import { floatList } from '@/helpers/floatList';
import { isFiorinStable } from '@/config/constants';
import authMixin from '@/mixins/auth.mixin';

export default {
  name: 'PositionDetailsOpened',
  mixins: [authMixin],
  props: {
    position: {
      type: Object,
      required: true,
    },
    positionLoyaltyFeeValue: {
      type: [Number, String],
    },
  },
  components: { ItemLabelValue, Icon },
  setup() {
    const store = useStore();

    const { calcClosePrice, calcEntryPrice, calcCurrentPrice } =
      usePositionCalcs();

    const markets = computed(() => store.getters['markets/markets']);

    const showDateFormat = (date) =>
      DateTime.fromISO(date).toUTC().toFormat('HH:mm, dd.MM.yy');

    return {
      formatNetProfitLoss,
      toCurrencyFormat,
      toUSDMarginFormat,
      markets,
      calcClosePrice,
      showDateFormat,
      calcCurrentPrice,
      calcEntryPrice,
      PositionSides,
      getFormatedAmount,
      PositionSideTypes,
      floatList,
      isFiorinStable,
    };
  },
};
</script>

<style lang="scss">
.icon-opacity {
  transition: all 0.2s ease-in-out;
  opacity: 0.3;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.tpsl-label {
  display: inline-block;
  margin-left: 5px;
}
</style>
