<template>
  <div :class="{ notStart: !referralProgram, active }" class="bounty-points">
    <div
      :style="{
        width: !startProgramAndMainBalance
          ? '100%'
          : (+pointsProgress?.percentProgress + +percentPointDynamic >= 100
              ? 100
              : +pointsProgress?.percentProgress + +percentPointDynamic) + '%',
      }"
      :class="{
        complete:
          !startProgramAndMainBalance ||
          !referralProgram ||
          !!taskForClaim ||
          pointsProgress?.percentProgress >= 100,
        notStart: !referralProgram,
      }"
      class="gradient"
    />
    <div
      v-if="!!taskForClaim && !startProgramAndMainBalance"
      class="bounty-points__item notStart"
      @click="showModal(modalsByName.getBounty)"
    >
      {{ `Tap To Claim $${getNumFormat(claimTotalAmount, 1)}` }}
    </div>
    <template v-else>
      <div
        v-if="notStartProgram"
        class="bounty-points__item notStart"
        @click="showModal(modalsByName.getBounty)"
      >
        Get Bounty To Trade For FREE
      </div>
      <div
        v-if="startProgramAndNullBalance"
        class="bounty-points__item notStart"
        @click="addFunds"
      >
        {{
          `Add Funds To Join $${
            settings.bountyInfo?.referralProgram?.targetPoints || 0
          } Challenge`
        }}
      </div>
      <div
        v-if="noStartProgramAndBountyBalance"
        class="bounty-points__item notStart"
        @click="showModal(modalsByName.getBounty)"
      >
        {{ `You've Got $${bountyBalanceUsd || 0.1} For FREE Trading` }}
      </div>
      <div
        v-if="startProgramAndMainBalance"
        :class="{
          notStartInProgress:
            !referralProgram || pointsProgress?.percentProgress < 12,
        }"
        class="bounty-points__item"
        @click="showModal(modalsByName.tutorialHistory)"
      >
        {{
          firstTimerLabel ||
          `${getNumFormat(
            +pointsProgress?.leftPointsToWin - percentPointOneSecValue <= 0
              ? 0
              : +pointsProgress?.leftPointsToWin - percentPointOneSecValue,
            2
          )} Points Left To Get $${pointsProgress?.targetPoint}`
        }}
      </div>
    </template>
    <!--<div class="bounty-points__item">Tap To Claim $20</div> -->
  </div>
</template>
<script>
import { useBountyPrograms } from '@/compositions/useBountyPrograms';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useModals } from '@/modules/modals/api';
import { useStore } from 'vuex';
import { connApp } from '@/helpers/signalR';
import { getNumFormat } from '@/helpers/utils';

export default {
  setup() {
    const {
      notStartProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
      pointsProgress,
      referralProgram,
    } = useBountyPrograms();
    const { showModal, modalsByName } = useModals();
    const store = useStore();
    const timer = ref(null);
    const timerSeconds = ref(null);
    const active = ref(false);
    const secondsLeft = ref(null);
    const percentPointDynamic = ref(0);
    const percentPointOneSecValue = ref(0);

    setTimeout(() => {
      active.value = true;
    }, 500);

    const userSettings = computed(() => store.getters['settings/uiSettings']);
    const settings = computed(() => store.getters['settings/settings']);
    const activityPointsBalance = computed(
      () => store.getters['localUiSettings/activityPointsBalance']
    );
    const activityPointsForTimer = computed(
      () => store.getters['localUiSettings/activityPointsForTimer']
    );

    const firstTimerLabel = computed(() => {
      if (
        activityPointsForTimer.value?.balance == 0 &&
        activityPointsForTimer.value?.nextBalance > 0 &&
        activityPointsForTimer.value?.secondsToUpdate
      ) {
        return `Points will flow in 00:${
          secondsLeft.value < 10 ? `0${secondsLeft.value}` : secondsLeft.value
        }`;
      }
      return '';
    });

    const bountyBalanceUsd = computed(() => store.getters['bounty/balanceUsd']);

    const taskForClaim = computed(() => {
      return newBountyTasks.value?.find((item) => item.status === 'NotPaid');
    });

    const claimTotalAmount = computed(() => {
      return newBountyTasks.value
        ?.filter((item) => item.status === 'NotPaid')
        .reduce((prev, current) => {
          return prev + current.amount;
        }, 0);
    });

    const pointProgress = computed(() => {
      const from = activityPointsBalance.value.balance || 0;
      const to = settings.value.bountyInfo?.referralProgram?.targetAmount || 0;
      const percentProgress = ((from / to) * 100).toFixed(2);

      return percentProgress;
    });

    const addFunds = () => {
      return store.dispatch('fiorin/viewDeposit');
    };

    watch(
      () => activityPointsForTimer.value,
      (newV) => {
        if (newV && newV?.secondsToUpdate) {
          secondsLeft.value = newV?.secondsToUpdate;
          percentPointDynamic.value = 0;
          percentPointOneSecValue.value = 0;
          console.log('POINTS WATCH NEW TIME', newV?.secondsToUpdate);
        }
      },
      true
    );

    const newBountyTasks = computed(
      () => store.getters['bounty/newBountyTasks']
    );

    onBeforeUnmount(() => {
      clearInterval(timer.value);
      clearInterval(timerSeconds.value);
    });

    onMounted(async () => {
      timerSeconds.value = setInterval(() => {
        // обратный отсчет
        secondsLeft.value--;
        // если таймер достиг нуля, не пускаем ниже и держим в 0
        if (secondsLeft.value <= 0) {
          secondsLeft.value = '0';
        }

        if (secondsLeft.value == 0) {
          return;
        }

        // проверка следующего поинта
        const from = activityPointsBalance.value.nextBalance || 0;
        const to =
          settings.value.bountyInfo?.referralProgram?.targetPoints || 0;

        if (
          // если есть все параметры значит программа идет
          activityPointsForTimer.value?.balance &&
          activityPointsForTimer.value?.nextBalance &&
          activityPointsForTimer.value?.secondsToUpdate
        ) {
          // находим процент следующего получения и делим на количество оставшихся секунд
          percentPointDynamic.value = (
            ((from / to) * 100) /
            (secondsLeft.value <= 0 ? 1 : secondsLeft.value)
          ) // если делить на /4 /3 /2 /1 сильно прыгает в конце
            .toFixed(2);

          percentPointOneSecValue.value =
            activityPointsForTimer.value?.nextBalance /
            (secondsLeft.value <= 0 ? 1 : secondsLeft.value);

          console.log(
            'POINTS TIMER TICK',
            percentPointDynamic.value + '%',
            secondsLeft.value
          );
        }
      }, 1000);
      try {
        timer.value = setInterval(async () => {
          if (newBountyTasks.value.length) {
            const bountyTasks = await connApp.invoke('ListBountyTasks');
            await store.dispatch('bounty/setNewBountyBalance', bountyTasks);
          }
        }, 10000);
      } catch (err) {
        clearInterval(timer.value);
        console.log(err);
      }
      try {
        const info = await connApp.invoke('GetActivityPointsBalance');
        const bountyTasks = await connApp.invoke('ListBountyTasks');
        await Promise.all([
          store.dispatch('bounty/setNewBountyBalance', bountyTasks),
          store.dispatch('localUiSettings/setActivityPointsBalance', info),
        ]);
        console.log(
          'BOUNTY POINTS INFO',
          activityPointsBalance.value,
          settings.value.bountyInfo,
          pointProgress.value
        );
      } catch (err) {
        // notify({ text: getErrorDetails(err).message, type: 'info' });
      }
    });

    return {
      settings,
      percentPointDynamic,
      percentPointOneSecValue,
      claimTotalAmount,
      notStartProgram,
      referralProgram,
      startProgramAndNullBalance,
      noStartProgramAndBountyBalance,
      startProgramAndMainBalance,
      activityPointsBalance,
      modalsByName,
      userSettings,
      pointsProgress,
      newBountyTasks,
      taskForClaim,
      addFunds,
      showModal,
      bountyBalanceUsd,
      active,
      firstTimerLabel,
      getNumFormat,
    };
  },
};
</script>
<style lang="scss">
.bounty-points {
  height: 44px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #45475c; // #283646;
  transition: all 0.5s ease-in-out;
  // opacity: 0;

  &.active {
    opacity: 1;
  }

  @media screen and (min-width: 1024px) {
    height: 44px;
  }

  &__item {
    font-size: 15px;
    position: absolute;
    color: #000;
    font-family: 'Gotham_Pro_Medium';
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 44px;
    display: inline-block;

    @media screen and (min-width: 1024px) {
      line-height: 44px;
    }

    &.notStart {
      -webkit-text-fill-color: initial;
      color: #000;
    }

    &.notStartInProgress {
      -webkit-text-fill-color: initial;
      color: #000;
      background: none;
    }
  }

  .gradient {
    position: absolute;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 0;
    top: 0;
    height: 44px;
    background: linear-gradient(to right, #2f80ed, #b2ffda);

    @media screen and (min-width: 1024px) {
      height: 44px;
    }

    &.complete {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.notStart {
      background: linear-gradient(to right, #2f80ed, #b2ffda);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
</style>
