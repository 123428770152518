<template>
  <div
    id="v-step-5"
    :class="{
      opened: !!formType,
      standalone: wd.navigator.standalone || isRunningStandaloneAndroid(),
      showBackControls: !showBackControls,
      isTelegram,
    }"
    class="form-position box"
  >
    <FormPositionBuySell
      v-if="activeMarket"
      :form-type="formType"
      :closed-market="closedMarket"
      :active-market="activeMarket"
      :price-buy="priceBuy"
      :price-sell="priceSell"
      @onInactiveBuySell="onInactiveBuySell"
      @onConnectionLost="onConnectionLost"
      @showForm="onShowForm"
    />
    <FormPositionSimpleForm
      v-if="!!formType && !formExpanded && isWorkingMarket"
      :active-market="activeMarket"
      :initial-amount="formData.amountBtc"
      :initial-leverage="formData.leverage"
      @changeNewOrderForm="onChangeNewOrderForm"
      @expand="onExpand"
    />
    <FormPositionExpandedForm
      v-if="!!formType && formExpanded && isWorkingMarket"
      :active-market="activeMarket"
      :is-bounty="isBountyPosition && !bountyPaused"
      :initial-amount="formData.amountBtc"
      :initial-leverage="formData.leverage"
      @tpsl-error="onTpSlError"
      :orderType="formType"
      @changeNewOrderForm="onChangeNewOrderFormExpanded"
      @expand="onExpand"
    />
    <div v-if="!!formType" :class="[formType]" class="form-position__controls">
      <div
        v-if="false"
        class="form-position__controls-btn back"
        @click="backToMarkets"
      >
        <icon name="arrowleft" width="20" height="20" @click="() => {}" />
      </div>
      <div
        :class="{
          disabled: !+formData.amountBtc || tpslError,
          toggleProTrading,
          mobile: scope.isSmall || scope.isMedium || scope.noMatch,
        }"
        class="form-position__controls-btn open"
        @click="confirmOperation"
      >
        {{ positionActionTitle }}
      </div>
      <!-- <div
        v-if="!toggleProTrading"
        class="form-position__controls-btn toggle"
        @click="toggleFormExpanded"
      >
        {{
          formExpanded
            ? $t('order.new.titles.less')
            : $t('order.new.titles.more')
        }}
      </div> -->
    </div>
    <Modal
      v-if="showConfirmModal"
      :stop-mobile-max-height="true"
      to-top
      @close="showConfirmModal = false"
    >
      <Card
        title-class="capitalize"
        :title="$t('popups.orderConfirm.titleNew')"
        :description="orderCardDescrition"
        :description-green="isLong"
        :description-red="isShort"
        :replace-str-upper="marketTicker(activeMarket.name)"
      >
        <FormPositionConfirm
          :is-bounty="isBountyPosition && !bountyPaused"
          :active-market="activeMarket"
          :orderType="formType"
          :margin-value-custom="formData.marginBsv"
          :formData="formData"
          @payment="onPayment"
          @error="onError"
          @on-close-order-confirm="onClose"
        />
      </Card>
    </Modal>

    <ModalMarketOpenClose
      v-if="showMarketOpenClose"
      :hide-again-btn="true"
      :value="workHour"
      @close="
        showMarketOpenClose = false;
        workHour = '';
      "
    />
    <ModalConnectionLost
      v-if="showConnectionLost"
      @close="showConnectionLost = false"
    />
    <HelloDXS v-if="showDXSModal" @close="showDXSModal = false" />
  </div>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import FormPositionBuySell from './components/FormPositionBuySell';
import FormPositionSimpleForm from './components/FormPositionSimpleForm';
import FormPositionExpandedForm from './components/FormPositionExpandedForm';
import FormPositionConfirm from './components/FormPositionConfirm';
import HelloDXS from '@/modules/user/HelloDXS';

import { useStore } from 'vuex';
import { watch, ref, computed, nextTick } from 'vue';

import { toCurrencyFormat, removeSpaces } from '@/helpers/utils';
import { floatList } from '@/helpers/floatList';

import { workHours } from '@/helpers/utils';
import useMarketCoin from '@/compositions/useMarketCoin';
import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';
import ModalConnectionLost from '@/components/modals/ModalConnectionLost';
import { useI18n } from 'vue-i18n';
import notify from '@/plugins/notify';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import { scope } from '@/breakpoints';

import '@/assets/icons/arrowleft';
import { useScope } from '@/compositions/useScope';
import { useRouter } from 'vue-router';
import { setLocalItem } from '@/helpers/storage';
import { marketTicker } from '@/config/wrapMarkets';
import { useModals } from '@/modules/modals/api';
import { banCountires } from '@/config/countries';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import useMaxBalance from '@/compositions/useMaxBalance';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default {
  name: 'FormPosition',
  mixins: [authMixin],
  components: {
    Modal,
    Card,
    FormPositionBuySell,
    FormPositionSimpleForm,
    FormPositionExpandedForm,
    FormPositionConfirm,
    ModalMarketOpenClose,
    HelloDXS,
    ModalConnectionLost,
  },
  setup(_, { emit }) {
    const auth = useAuth();
    const marketCoins = useMarketCoin();
    const { isMarketActive } = marketCoins;
    const store = useStore();
    const { isNotDesktop } = useScope();
    const router = useRouter();

    const closedMarket = ref(false);
    const showConfirmModal = ref(false);
    const formType = ref('');
    const prevFormType = ref('');
    const formData = ref({});
    const showConnectionLost = ref(false);
    const tpslError = ref(false);

    const backToMarkets = () => {
      emit('close');
    };

    const getLeverageLocalKey = () => `leverage_${activeMarket.value.name}`;

    const onExpand = ({ value, percentSelectorAmount }) => {
      const val =
        percentSelectorAmount === 'MAX' ? 100 : parseInt(percentSelectorAmount);

      if (val) {
        localStorage.setItem('setMaxPercentCache', val);
      } else {
        localStorage.removeItem('setMaxPercentCache');
      }

      if (!toggleProTrading.value) {
        toggleFormExpanded();
        formData.value.leverage = value;
        localStorage.setItem('cacheLeverage', value);
        updateAndSetUserSettings({
          uiSettings: {
            [getLeverageLocalKey()]: formData.value.leverage,
          },
        });
      }
    };

    const { showModal, modalsByName } = useModals();

    const toggleProTrading = computed(() =>
      store.getters['settings/uiSetting']('toggleProTrading')
    );

    const toggleProTradingAndMobile = computed(
      () =>
        toggleProTrading.value &&
        (scope.isSmall || scope.isMedium || scope.noMatch)
    );

    const tradeToolMinimize = computed(() =>
      store.getters['settings/uiSetting']('tradeToolMinimize')
    );

    const formExpanded = ref(toggleProTrading.value);

    if (toggleProTrading.value) {
      formExpanded.value = true;
      tpslError.value = false;
      // formData.value = {};
    }

    const onTpSlError = (error) => {
      tpslError.value = error;
    };

    const { t } = useI18n();

    const workHour = ref('');
    const showMarketOpenClose = ref(false);
    const showDXSModal = ref(false);

    const activeMarket = computed(() => {
      return store.getters['markets/activeMarket'];
    });

    const sleepLoading = computed(
      () => store.getters['positions/sleepLoading']
    );

    const isWorkingMarket = computed(() =>
      marketCoins.isWorkingMarket(activeMarket.value)
    );

    const priceBuy = computed(() =>
      toCurrencyFormat(
        activeMarket.value.buy,
        null,
        floatList[activeMarket.value.name]
      ).toString()
    );
    const priceSell = computed(() =>
      toCurrencyFormat(
        activeMarket.value.sell,
        null,
        floatList[activeMarket.value.name]
      ).toString()
    );
    const openFormPosition = computed(
      () => store.getters['positions/getFormPosition']
    );

    const onInactiveBuySell = () => {
      showMarketOpenClose.value = true;
      workHour.value = workHours(new Date(), activeMarket.value);
    };

    const onConnectionLost = () => {
      showConnectionLost.value = true;
    };

    const isLong = computed(() => formType.value.toLowerCase() === 'buy');
    const isShort = computed(() => formType.value.toLowerCase() == 'sell');

    const orderCardDescrition = computed(() => {
      const side = formType.value.toLowerCase() === 'buy' ? 'long' : 'short';

      return `${marketTicker(
        activeMarket.value.name
      )} ${side}, debiting your wallet`;
    });

    const onShowForm = (type) => {
      if (closedMarket.value) {
        return;
      }
      if (scope.isSmall || scope.isMedium || scope.noMatch) {
        if (localStorage.getItem(`amount${activeMarket.value.name}`)) {
          formData.value.amountBtc = localStorage.getItem(
            `amount${activeMarket.value.name}`
          );
        }
      }
      formType.value = type;
      store.dispatch('positions/toggleFormPosition', false);
    };

    const toggleFormExpanded = () => {
      formExpanded.value = !formExpanded.value;
      tpslError.value = false;
      const leverage = formData.value.leverage;
      const amountBtc = formData.value.amountBtc;
      formData.value = {};
      formData.value.amountBtc = amountBtc;
      formData.value.leverage = leverage;
    };

    watch(
      () => isWorkingMarket.value,
      () => {
        if (!isWorkingMarket.value) {
          formType.value = '';
        }
      }
    );

    watch(
      () => tradeToolMinimize.value,
      () => {
        if (tradeToolMinimize.value) {
          formType.value = '';
          return;
        }

        if (!tradeToolMinimize.value) {
          if (!isWorkingMarket.value) {
            prevFormType.value = PositionSides.BUY;
          } else {
            formType.value = PositionSides.BUY;
          }

          store.dispatch('positions/toggleFormPosition', false);
        }
      }
    );

    watch(
      () => sleepLoading.value,
      () => {
        if (sleepLoading.value) {
          showConfirmModal.value = false;
        }
      }
    );

    watch(
      () => toggleProTrading.value,
      () => {
        if (toggleProTrading.value) {
          formExpanded.value = true;
          tpslError.value = false;
          formData.value = {};
        }
      }
    );

    watch(openFormPosition, () => {
      if (openFormPosition.value) {
        formType.value = '';
        formExpanded.value = toggleProTrading.value;
        tpslError.value = false;
        formData.value = {};
      }
    });

    // initial check (mobile case)
    if (!isMarketActive(activeMarket.value)) {
      closedMarket.value = true;
    }

    watch(
      () => auth.isLoggedIn.value,
      async () => {
        if (activeMarket?.value && auth.isLoggedIn) {
          await store.dispatch(
            'volumeLimits/fetchGroupActiveAmount',
            activeMarket.value.id
          );
        }
      }
    );

    watch(
      () => activeMarket?.value?.usdPrice,
      async () => {
        if (activeMarket?.value?.usdPrice) {
          window.top.document.title = `${toCurrencyFormat(
            activeMarket.value.usdPrice,
            null,
            floatList[activeMarket.value.name]
          )} ${activeMarket.value.name}`;
        }

        if (auth.isLoggedIn.value) {
          await store.dispatch(
            'volumeLimits/fetchLoyaltyFeeRate',
            activeMarket?.value?.id
          );
          await store.dispatch(
            'volumeLimits/fetchLoyaltyFeeInfo',
            activeMarket?.value?.id
          );
        }
      }
    );

    watch(
      () => activeMarket.value,
      async (newV, oldV) => {
        if (newV && oldV && newV.id === oldV.id) {
          return;
        }

        localStorage.removeItem('setMaxPercentCache');

        if (activeMarket?.value && auth.isLoggedIn.value) {
          await store.dispatch(
            'volumeLimits/fetchGroupActiveAmount',
            activeMarket.value.id
          );
        }

        if (scope.isSmall || scope.isMedium || scope.noMatch) {
          formType.value = '';
        } else {
          // fix for reset button relay (reset confirm form)
          if (newV?.id !== oldV?.id) {
            const initialAmount = localStorage.getItem(
              `amount${activeMarket.value?.name}`
            );
            // formData.value = {};
            if (initialAmount) {
              formData.value.amountBtc = initialAmount;
            }
            delete formData.value.entryPrice;
            // delete formData.value.leverage;
            delete formData.value.marginBsv;
            delete formData.value.marginValue;
            delete formData.value.stopLoss;
            delete formData.value.takeProfit;
          }
        }

        if (formType.value) {
          prevFormType.value = formType.value;
        }

        // formData.value = {};

        if (!isWorkingMarket.value) {
          closedMarket.value = true;
          formType.value = '';

          return;
        }

        if (!tradeToolMinimize.value) {
          formType.value = prevFormType.value;
        }

        closedMarket.value = false;
      }
    );

    const getAccountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const positionActionTitle = computed(() => {
      if (formData.value.entryPrice) {
        switch (formType.value) {
          case PositionSides.BUY:
            if (
              getAccountBalance.value.bounty &&
              !bountyPaused.value &&
              +formData.value.marginValue &&
              getAccountBalance.value.bounty >= +formData.value.marginValue
            ) {
              return `BOUNTY AT ${t('order.new.titles.buy')} ${
                formData.value.entryPrice
              }`;
            }
            return `${t('order.new.titles.buy')} AT ${
              formData.value.entryPrice
            }`;
          case PositionSides.SELL:
            if (
              getAccountBalance.value.bounty &&
              +formData.value.marginValue &&
              getAccountBalance.value.bounty >= +formData.value.marginValue
            ) {
              return `BOUNTY AT ${t('order.new.titles.sell')} ${
                formData.value.entryPrice
              }`;
            }
            return `${t('order.new.titles.sell')} AT ${
              formData.value.entryPrice
            }`;
          default:
            return '';
        }
      }
      switch (formType.value) {
        case PositionSides.BUY:
          if (
            getAccountBalance.value.bounty &&
            +formData.value.marginValue &&
            getAccountBalance.value.bounty >= +formData.value.marginValue
          ) {
            return `${t('order.new.titles.bountyBuy')} AT ${priceBuy.value}`;
          }
          return `${t('order.new.titles.marketBuy')} AT ${priceBuy.value}`;
        case PositionSides.SELL:
          if (
            getAccountBalance.value.bounty &&
            +formData.value.marginValue &&
            getAccountBalance.value.bounty >= +formData.value.marginValue
          ) {
            return `${t('order.new.titles.bountySell')} AT ${priceSell.value}`;
          }
          return `${t('order.new.titles.marketSell')} AT ${priceSell.value}`;
        default:
          return '';
      }
    });

    const maxBalance = computed(() => {
      const fee = 0.0001;
      const staticFee = 7e-6;

      const maxAmountValue = activeMarket.value.max_amount_usd;

      const maxAmountFromPositions =
        maxAmountValue -
        store.getters['positions/getMarketMaxAmount'](activeMarket.value.name);
      const minVal = toCurrencyFormat(
        Math.min(
          maxAmountFromPositions,
          Math.max(
            Math.max(
              getAccountBalance.value.available,
              getAccountBalance.value.bounty
            ) -
              fee -
              staticFee,
            0
          ) *
            (activeMarket.value.max_leverage || 1) *
            bsvPrice()
        )
      );

      return removeSpaces(minVal + '');
    });

    const isBountyPosition = computed(
      () =>
        getAccountBalance.value.bounty >= +formData.value.marginValue &&
        auth.isFiorinMode.value
    );
    const bountyPaused = computed(
      () => store.getters['settings/uiSettings']?.bountyPaused
    );

    const bountyTasks = computed(() => store.getters['bounty/bountyTasks']);

    const location = computed(() => store.getters['usa/getLocation']);

    const enableToOpenPosition = computed(() => {
      return useMaxBalance(
        formData.value.leverage,
        formData.value.amountBtc,
        auth.isFiorinMode.value,
        t
      ).enableToOpenPosition.value;
    });

    const confirmOperation = () => {
      if (!auth.isLoggedIn.value) {
        store.dispatch('burger/login');

        if (isNotDesktop.value) {
          backToMarkets();
        }
        return;
      }

      if (
        !localStorage.getItem('usa') &&
        banCountires.includes(location.value?.countryCode)
      ) {
        showModal(modalsByName.usaAgree);
        return;
      }

      const phoneConfirmed =
        store.getters['settings/settings']?.phoneNumberConfirmed;

      if (store.getters['settings/uiSettings']?.blockedFromIp) {
        if (!phoneConfirmed) {
          store.dispatch('burger/phoneVerification', { auto: false });
          return;
        }
      }

      if (bountyTasks.value.hasPositions && !phoneConfirmed) {
        store.dispatch('burger/phoneVerification', { auto: false });
        return;
      }

      if (sleepLoading.value || store.getters['connectors/isReconnect']) {
        notify({
          text: 'Reconnecting...',
          type: 'info',
        });
        return;
      }

      if (!enableToOpenPosition.value) {
        notify({
          text: t('popups.marketInactive.limitReached'),
          type: 'info',
        });
        return;
      }

      if (!formData.value.amountBtc) {
        notify({
          text: 'Enter amount',
          type: 'info',
        });
        const input = document.querySelector(
          '.new-version.selectorLiquidityInput input'
        );
        if (input) {
          input.focus();
        }
        return;
      }

      if (auth.isFiorin.value) {
        if (formData.value.marginValue < 0.01) {
          notify({
            text: t('positionform.minMargin'),
            type: 'info',
          });
          return;
        }
      } else {
        //if (+formData.value.amountBtc < 0.01) {
        if (+formData.value.marginBsv * bsvPrice() < 0.01) {
          notify({
            text: t('positionform.minAmount'),
            type: 'info',
          });
          return;
        }
      }

      if (!+formData.value.amountBtc || tpslError.value) {
        return;
      }

      if (!auth.isLoggedIn.value) {
        showDXSModal.value = true;
        return;
      }

      const maxAmountValue = activeMarket.value.max_amount_usd;

      if (!+maxAmountValue) {
        notify({
          text: t('popups.marketInactive.limitReached'),
          type: 'info',
        });
        return;
      }

      if (+formData.value.amountBtc > +maxBalance.value) {
        if (auth.isHandCash.value) {
          notify({
            text: t('order.new.toasts.depletedHandcash'),
            type: 'info',
          });
          return;
        }
        notify({
          text: t('order.new.toasts.depleted', {
            provider: auth.provider.value,
          }),
          type: 'info',
        });
        return;
      }

      showConfirmModal.value = true;
    };

    const onChangeNewOrderForm = (data) => {
      const leverage = data.leverage;
      formData.value = data;
      formData.value.marginBsv = data.marginValue;
      if (data.amountBtc) {
        localStorage.setItem(
          `amount${activeMarket.value?.name}`,
          data.amountBtc
        );
      }

      // localStorage.setItem('cacheAmountUsd', data.amountBtc);

      if (leverage) {
        formData.value.leverage = leverage;
      }
    };

    const onChangeNewOrderFormExpanded = (data) => {
      if (data.leverage == 1) {
        // afrer reset
        localStorage.setItem('cacheLeverage', data.leverage);
      }
      formData.value = data;
      formData.value.marginBsv = data.marginValue;
      if (data.amountBtc) {
        localStorage.setItem(
          `amount${activeMarket.value?.name}`,
          data.amountBtc
        );
      }
      // localStorage.setItem('cacheAmountUsd', data.amountBtc);
    };

    const onPayment = async () => {
      showConfirmModal.value = false;

      const setBreakInfo = () => {
        if (
          formData.value.entryPrice &&
          !localStorage.getItem('showBreakOutTradeModal')
        ) {
          showModal(modalsByName.breakoutTrade);
        }
      };

      if (
        +activeMarket.value.usdPrice < +formData.value.entryPrice &&
        formType.value === PositionSides.BUY
      ) {
        setBreakInfo();
      } else if (
        +activeMarket.value.usdPrice > +formData.value.entryPrice &&
        formType.value !== PositionSides.BUY
      ) {
        setBreakInfo();
      }

      formData.value = {};
      // localStorage.removeItem('cacheAmountUsd');
      localStorage.removeItem(`amount${activeMarket.value?.name}`);
      const fType = formType.value;
      formType.value = '';
      localStorage.setItem('stopAutosetAmount', true);

      nextTick(() => {
        formType.value = fType;
        setTimeout(() => {
          localStorage.removeItem('stopAutosetAmount');
        }, 2000);
      });

      store.dispatch('bounty/updateBalance');

      if (isNotDesktop.value) {
        onClose();
        setLocalItem('mobileScrollPositions.Positions', {
          top: 0,
          left: 0,
        });
        await router.push('/positions');
      }
    };

    const onClose = () => {
      showConfirmModal.value = false;
    };

    const onError = () => {
      showConfirmModal.value = false;
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const showBackControls = computed(
      () => store.getters['localUiSettings/showBackControls']
    );

    return {
      isLong,
      isShort,
      showBackControls,
      onExpand,
      isWorkingMarket,
      formType,
      formExpanded,
      positionActionTitle,
      activeMarket,
      priceBuy,
      priceSell,
      orderCardDescrition,
      showConfirmModal,
      formData,
      tpslError,
      toggleFormExpanded,
      onShowForm,
      onChangeNewOrderForm,
      onChangeNewOrderFormExpanded,
      confirmOperation,
      onTpSlError,
      scope,

      isBountyPosition,
      bountyPaused,

      onPayment,
      onError,
      onClose,

      closedMarket,
      showDXSModal,
      showConnectionLost,
      showMarketOpenClose,
      onInactiveBuySell,
      onConnectionLost,
      workHour,

      toggleProTrading,
      toggleProTradingAndMobile,
      backToMarkets,
      wd: window,
      isRunningStandaloneAndroid,
      marketTicker,
      isTelegram,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';
@import '@/assets/styles/colors';

.form-position {
  &__controls {
    display: flex;
  }

  &__controls-btn {
    height: 60px;
    width: 100%; // calc(100% - 50px);
    color: $color-white;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 0.9375rem;
    font-family: Cantarell_Bold;
    display: flex;
    align-items: center;
    justify-content: center;

    &.open {
      // padding-left: 35px;
    }

    &.mobile {
      width: 100%; // calc(100% - 100px);
      // padding-right: 22px;
    }

    &.toggleProTrading {
      width: 100%;
      padding-left: 0;
    }

    &.toggleProTradingAndMobile {
      width: calc(100% - 50px);
      padding-right: 32px;
      padding-left: 0;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &.disabled {
      opacity: 0.7;
    }

    &.toggle {
      width: 50px;
      font-family: Gotham_Pro_Medium;
      font-size: 0.65rem;
    }

    &.back {
      width: 50px;
      font-family: Gotham_Pro_Medium;
      font-size: 0.65rem;
    }
  }
}
</style>
