import { getBountyTasks, getPositionStatus } from '@/modules/bounty/api';
import notify from '@/plugins/notify';
import { getErrorDetails } from '@/helpers/signalR';

const state = {
  lastCheckedBalance: 0,
  bountyTasks: {
    start: false,
    firstOrder: false,
    nthOrder: false,
    paused: false,
    hasPositions: false,
    bountySharing: false,
  },
  newBountyTasks: [],
};

const getters = {
  newBountyTasks: (state) => state.newBountyTasks,
  balanceUsd: (state, getters, rootState, rootGetters) => {
    if (state.bountyTasks.paused) {
      return 0;
    }

    if (!rootGetters['auth/isLoggedIn']) {
      return 0;
    }

    return rootGetters['wallet/bountyBalanceUsd'];
  },
  bountyTasks: (state) => state.bountyTasks,
};

const types = {
  SET_BOUNTY_TASKS: 'SET_BOUNTY_TASKS',
  SET_BOUNTY_PAUSE: 'SET_BOUNTY_PAUSE',
  SET_NEW_BOUNTY_TASKS: 'SET_NEW_BOUNTY_TASKS',
};

const mutations = {
  setLastCheckedBalance(state, value) {
    state.lastCheckedBalance = value;
  },
  [types.SET_NEW_BOUNTY_TASKS](state, value) {
    state.newBountyTasks = value;
  },
  [types.SET_BOUNTY_TASKS](state, { result, paused, hasPositions }) {
    Object.assign(state.bountyTasks, { ...result, paused, hasPositions });
  },
  [types.SET_BOUNTY_PAUSE](state, value) {
    state.bountyTasks.paused = value;
  },
};

// let timeouts = [];
const actions = {
  setNewBountyBalance({ commit }, value) {
    console.log('BOUNTY POINTS TASKS', value);
    commit(types.SET_NEW_BOUNTY_TASKS, value);
  },
  async updateBalance({ dispatch }) {
    await dispatch('updateBountyTasks');
    // [Vadim]: disabled since not needed
    // await dispatch('checkBountyUpdated');
  },

  // [Vadim]: disabled since not needed
  // checkBountyUpdated({ commit, rootGetters }) {
  //   const currentBountyBalance = rootGetters['wallet/bountyBalance'];
  //   const diff = state.lastCheckedBalance - currentBountyBalance;
  //   if (diff > 0) {
  //     notify({
  //       text: `Bounty received: +${diff}`,
  //       type: 'info',
  //       group: 'bounty',
  //     });
  //   }
  //
  //   commit('setLastCheckedBalance', currentBountyBalance);
  // },

  setBountyPaused({ commit }, isPaused) {
    commit(types.SET_BOUNTY_PAUSE, isPaused);
  },

  async updateBountyTasks({ commit, rootGetters }) {
    const paused = rootGetters['settings/uiSettings']?.bountyPaused;
    const result = await getBountyTasks().catch((err) => {
      notify({ text: getErrorDetails(err).message, type: 'info' });
      if (!localStorage.getItem('getBountyTasksError')) {
        console.log(3);
        window.location.reload();
        localStorage.setItem('getBountyTasksError', true);
      }
    });

    const hasPositions = await getPositionStatus();

    if (result) {
      localStorage.removeItem('getBountyTasksError');
      commit(types.SET_BOUNTY_TASKS, { result, paused, hasPositions });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
