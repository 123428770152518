const types = {
  ADD_NEW_ACCOUNT: 'ADD_NEW_ACCOUNT',
  REMOVE_ACCOUNT: 'REMOVE_ACCOUNT',
  CLEAR_ACCOUNTS: 'CLEAR_ACCOUNTS',
};

const getInitialState = () => ({
  accounts: [],
  activeAccountId: null,
});

const getters = {
  accounts: (state) => state.accounts,

  accountNames: (state) => state.accounts.map((acc) => acc.userName),

  activeAccount: (state) => {
    if (!state.activeAccountId) {
      return null;
    }

    let activeAccount = state.accounts.find(
      (acc) => acc.id === state.activeAccountId
    );

    if (activeAccount) {
      return activeAccount;
    }

    console.debugError(
      'No active account found #accounts, activeAccountId:',
      state.activeAccountId
    );

    return null;
  },
};

//todo: move to accounts module:
// const accounts = rootGetters['connectors/connects']
//   .map((connect) => {
//     let title = '';
//
//     if (connect.fiorinUserName) {
//       title = connect.fiorinUserName;
//     }
//
//     if (connect.provider === userProviders.handCash) {
//       title = (
//         ((connect.profile &&
//           connect.profile.publicProfile &&
//           connect.profile.publicProfile.handle) ||
//           '') + '@handcash'
//       ).toUpperCase();
//     }
//
//     if (connect.provider === userProviders.moneyButton) {
//       title = (
//         ((connect.serverInfo && connect.serverInfo.providerId) || '') +
//         '@mb'
//       ).toUpperCase();
//     }
//
//     if (connect.provider === userProviders.relayX) {
//       title = (
//         ((connect.serverInfo && connect.serverInfo.providerId) || '') +
//         '@rx'
//       ).toUpperCase();
//     }
//
//     if (connect.provider === userProviders.dotWallet) {
//       title = (
//         ((connect.userInfo && connect.userInfo.id) || '') + '@dw'
//       ).toUpperCase();
//     }
//
//     return {
//       ...connect,
//       title,
//       action: 'switchAccount',
//     };
//   })
//   .filter(
//     (acc) => acc.title && acc.title.length > 3 && acc.title !== userName
//   );

const mutations = {
  [types.ADD_NEW_ACCOUNT](state, account) {
    if (!account) {
      const error = 'No account provided #accounts #actions #addAccount';
      console.debugError(error, account);
      throw new Error(error);
    }

    if (!account.id) {
      const error = 'No account id provided #accounts #actions #addAccount';
      console.debugError(error, account);
      throw new Error(error);
    }

    if (!account.provider) {
      const error =
        'No account provider provided #accounts #actions #addAccount';
      console.debugError(error, account);
      throw new Error(error);
    }

    if (!account.userName) {
      const error =
        'No account userName provided #accounts #actions #addAccount';
      console.debugError(error, account);
      throw new Error(error);
    }

    if (!account.accessToken) {
      const error =
        'No account accessToken provided #accounts #actions #addAccount';
      console.debugError(error);
      throw new Error(error);
    }

    // merge the accounts by username and provider: replace if match exists or add new:
    const existingAccount = state.accounts.find(
      (acc) =>
        acc.userName === account.userName && acc.provider === account.provider
    );

    if (existingAccount) {
      state.accounts = state.accounts.map((acc) =>
        acc.userName === account.userName && acc.provider === account.provider
          ? account
          : acc
      );
      return;
    }

    state.accounts.push(account);
  },

  [types.REMOVE_ACCOUNT](state, id) {
    if (!id) {
      const error = 'No account id provided #accounts #actions #removeAccount';
      console.debugError(error);
      throw new Error(error);
    }
    state.accounts = state.accounts.filter((account) => account.id !== id);
  },

  [types.CLEAR_ACCOUNTS](state) {
    state.accounts = [];
    state.activeAccountId = null;
  },

  setActiveAccountId(state, value) {
    state.activeAccountId = value;
  },
};

let actions = {
  addAccount({ commit }, account) {
    console.debug('#addAccount #accounts #actions', account);
    commit(types.ADD_NEW_ACCOUNT, account);
  },

  removeAccount({ commit }, id) {
    console.debug('#removeAccount  #acounts #actions');
    commit(types.REMOVE_ACCOUNT, id);
  },

  clear({ commit }) {
    console.debug('#clearAccounts  #acounts #actions');
    commit(types.CLEAR_ACCOUNTS);
  },

  setActiveAccountId({ commit }, value) {
    commit('setActiveAccountId', value);
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
  cachedPaths: ['accounts.accounts', 'accounts.activeAccountId'],
};
