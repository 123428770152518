<template>
  <div :class="{ openCard }" class="session-info">
    <div v-if="!hideToggle" class="session-info__title" @click="toggleCard">
      {{ parseTitle($t('blocks.titles.tradingSession')) }}
      <Icon
        :class="{ openCard }"
        name="arrow_down"
        width="12"
        height="12"
        class="arrow-icon"
      />
    </div>
    <ItemLabelValue
      :label="$t('tradingSession.titles.insurancePool')"
      value=""
      :hint-options="{ top: '-15px', left: '65px' }"
      :hint-title="$t('tradingSession.hints.insurancePool')"
    >
      <span v-html="insurancePool" />
    </ItemLabelValue>
    <ItemLabelValue
      :label="$t('tradingSession.titles.liquidity')"
      value=""
      :hint-options="{ top: '-68px', left: '65px' }"
      :hint-title="$t('tradingSession.hints.liquidity')"
    >
      <span v-html="sessionLiquidity" />
    </ItemLabelValue>
    <item-label-value
      v-if="profitDeduction.includes('-')"
      :label="t('tradingSession.titles.profitDeduction')"
      :hint-options="{ top: '-90px', left: '65px' }"
      :hint-title="
        t('tradingSession.hints.profitDeduction').replace(
          '__percent__',
          profitDeduction
        )
      "
    >
      {{ profitDeduction }}
    </item-label-value>
    <ItemLabelValue
      :label="$t('tradingSession.titles.settlement')"
      :value="nextSettlement"
      :hint-options="{ top: '-65px', left: '65px' }"
      :hint-title="$t('tradingSession.hints.settlement')"
    />
    <!-- <ItemLabelValue
      v-if="$isLoggedIn"
      :label="$t('blocks.titles.loyaltyPool')"
      value=""
      :hint-options="{ top: '-50px', left: '100px' }"
      :hint-title="$t('blocks.titles.loyaltyPoolHint')"
    >
      <span v-html="loyaltyPoolBalance" />
    </ItemLabelValue> -->
  </div>
</template>
<script>
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import DateTime from 'luxon/src/datetime.js';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { toCurrencyFormat, formatAmount, formatMinutes } from '@/helpers/utils';
import Icon from '@/components/ui/Icon.vue';
import '@/assets/icons/arrow_down';
import useToggleCard from '@/compositions/useToggleCard';
import { getSleepInfo } from '@/helpers/sleep';
import { parseTitle } from '@/helpers/ui';
import authMixin from '@/mixins/auth.mixin';

export default {
  name: 'SessionInfo',
  mixins: [authMixin],
  components: { ItemLabelValue, Icon },
  props: {
    hideToggle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      nextSettlement: '0',
      nextSettlementInterval: null,
    };
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const updateSessionInfo = async () =>
      await store.dispatch('session/updateInfo');
    const { openCard, toggleCard } = useToggleCard();

    const sessionInfo = computed(() => store.getters['session/info']);

    const insurancePool = computed(() => {
      const pool = sessionInfo.value.insurancePool;

      if (!pool) {
        return 0;
      }
      return formatAmount(pool, '', true);
    });

    const sessionLiquidity = computed(() => {
      const profit = sessionInfo.value.maxProfit;

      if (!profit) {
        return 0;
      }
      return formatAmount(profit, '', true);
    });

    const profitDeduction = computed(() => {
      const rebate = sessionInfo.value.rebateRatio || 0;
      return `${rebate ? '-' : ''}${toCurrencyFormat(rebate * 100, null, 2)}%`;
    });

    // const loyaltyPoolBalance = computed(() =>
    //   formatAmount(store.getters['volumeLimits/loyaltyPoolBalance'])
    // );

    onMounted(async () => {
      await updateSessionInfo();
    });

    return {
      profitDeduction,
      sessionLiquidity,
      insurancePool,
      t,
      store,
      sessionInfo,
      openCard,
      // loyaltyPoolBalance,
      toCurrencyFormat,
      updateSessionInfo,
      toggleCard,
      parseTitle,
    };
  },
  watch: {
    'sessionInfo.sessionEnd'() {
      const profitsRebateRiskLSKey = 'truedex.profitsRebateModalShutDown'; // TODO import from resp. modal
      this.calcNextSettlement();
      // this.$store.dispatch('volumeLimits/fetchLoyaltyPoolBalance');
      sessionStorage.setItem(profitsRebateRiskLSKey, null); // see modal 'profitsRebateRisk'
    },
    async nextSettlement(val) {
      if (val.includes('-')) {
        await this.updateSessionInfo();
        this.calcNextSettlement();
      }
    },
  },
  created() {
    this.calcNextSettlement();
    this.nextSettlementInterval = setInterval(() => {
      if (!document[getSleepInfo().hidden]) {
        this.calcNextSettlement();
      }
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.nextSettlementInterval);
  },
  methods: {
    calcNextSettlement() {
      // this.$store.dispatch('volumeLimits/fetchLoyaltyPoolBalance');
      this.nextSettlement = formatMinutes(
        DateTime.fromISO(this.sessionInfo.sessionEnd).diffNow('minutes').minutes
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';
.session-info {
  padding: 18px 20px 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  height: 52px;
  transition: all 0.3s ease-in-out;

  &.openCard {
    min-height: auto;
    height: auto;
  }

  &__title {
    @include card-title;
  }
}
</style>
