import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import EventEmitter from 'events';
import config from '@/config';
import { v4 as uuidv4 } from 'uuid';
import { waitUntil } from '@/helpers/retry';

const RECONNECT_TIMEOUT = 1000;

// const dispatchCloseAndReload = () => {
//   window.document.dispatchEvent(
//     new CustomEvent('closeLogin', { bubbles: true })
//   );
//   window.location.reload();
// };

class SignalRConnection extends EventEmitter {
  constructor(host, withAuth) {
    super();

    this.host = host;
    this.withAuth = withAuth;
    this._provider = null;
    this._accessToken = null;

    this._connection = new HubConnectionBuilder()
      .withUrl(this._buildConnectionString(), {
        accessTokenFactory: withAuth ? this._accessTokenFactory : undefined,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => Math.random() * RECONNECT_TIMEOUT,
      })
      .configureLogging(LogLevel.Critical)
      .build();

    this._connection.onclose(this._onDisconnect);
    this._connection.onreconnected(this._onReconnecting);
    this._connection.onreconnecting((error) => {
      if (error) {
        console.log({ type: 'Reconnected', error });
      } else {
        this.emit('connect');
      }
    });

    // [vadim]: analyse it.
    // When signalR is stopped, it may collect calls, waiting for connect,
    // and invoke all the calls at once, what may lead to a lot of requests at once
    // document.addEventListener('visibilitychange', this._ensureInValidState);
    // window.addEventListener('focus', this._ensureInValidState);
  }

  _onDisconnect = () => {
    console.log('Socket connection has closed');

    // todo: [vadim] not needed if stopped
    console.log(`Will try again in ${RECONNECT_TIMEOUT}ms`);
  };

  _onReconnecting = () => {
    this._onDisconnect();
  };

  // todo: [Vadim] check if this is needed
  // _ensureInValidState = async () => {
  //   if (document.visibilityState !== 'visible') {
  //     return;
  //   }
  //
  //   // disabled while debugging access tokens
  //   // // todo: [Vadim] check if this is needed
  //   // if (!this._hasAccessToken()) {
  //   //   await this._connection.stop();
  //   //   console.debug('Stopping connection because no access token #SignalRConnection #_ensureInValidState #_hasAccessToken');
  //   //   return;
  //   // }
  //
  //   if (
  //     this._connection.state === HubConnectionState.Disconnected ||
  //     this._connection.state === HubConnectionState.Disconnecting
  //   ) {
  //     this.connected = false;
  //
  //     await this.start();
  //   }
  // };

  _buildConnectionString = () => {
    let uuid = localStorage.getItem('dxsUUID');

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('dxsUUID', uuid);
    }

    return this.host + `?browserId=${uuid}`;
  };

  setAccessData = (provider, accessToken) => {
    this._provider = provider;
    this._accessToken = accessToken;
  };

  _getAccessData = () => {
    if (!this.withAuth) {
      console.debugError(
        'Why access data is requested when no auth is needed? #SignalRConnection #getAccessData'
      );

      return {
        provider: null,
        accessToken: null,
      };
    }
    if (!this._accessToken || !this._provider) {
      console.debug(
        'No provider or accessToken provided #SignalRConnection #getAccessData',
        this.host
      );

      return {
        provider: null,
        accessToken: null,
      };
    }

    return {
      provider: this._provider,
      accessToken: this._accessToken,
    };
  };

  // _hasAccessToken = () => Boolean(this._getAccessData().accessToken);

  _accessTokenFactory = () => {
    if (!this.withAuth) {
      return;
    }

    const { provider, accessToken } = this._getAccessData();

    if (!accessToken) {
      return;
    }

    const token = `${provider}:${accessToken}`;
    return token;
  };

  _startInternal = async () => {
    try {
      const initialState = this._connection.state;
      if (
        initialState !== HubConnectionState.Connected &&
        initialState !== HubConnectionState.Connecting
      ) {
        let now = new Date();
        await this._connection.start();
        let elapsed = new Date() - now;

        console.debug(
          'Connection established (_startInternal) #signalR #start, elapsed:',
          elapsed,
          'host:',
          this.host
        );

        this.emit('connect');
      }
    } catch (err) {
      const lastState = this._connection.state;
      if (err.statusCode === 401) {
        // means accessToken is invalid, need to delete accessToken from store and relogin
        this.unauthorized = true;
        this.emit('unauthorized');

        return 'unauthorized';
      } else {
        console.error(
          'Error when start connection #signalR #start, error:',
          JSON.stringify(err),
          err,
          'initialState:',
          this._connection.state,
          'lastState:',
          lastState,
          'host:',
          this.host
        );
        throw err;
      }
    }
  };

  start = async () => {
    console.debug('#SignalRConnection #start host:', this.host);

    // [Vadim]  sometimes signalR throws an empty exception, without any details at all
    // do not delete this retry!

    const connected = await waitUntil(
      '#SignalRConnection #start',
      5 * 60_000, // 5 minutes,
      1000,
      async () => {
        // [Vadim] this logic is important, don't even dare to touch it without any significant reason! ;/

        if (this._connection.state === HubConnectionState.Connected) {
          console.debug(
            'Connection is already established #signalR #start host:',
            this.host
          );
          return true;
        }

        try {
          let error = await this._startInternal();
          // console.debug('after _startInternal #signalR #start r:', error);
          if (error === 'unauthorized') {
            console.debug(
              'Connection was unauthorized #signalR #start host:',
              this.host
            );
            return true;
          }
        } catch (error) {
          if (this._connection.state === HubConnectionState.Connected) {
            console.debug(
              'Connection was established despite an error #signalR #start, error:',
              error,
              'host:',
              this.host
            );
            return true;
          }

          // already logged internally
          console.error(
            'Error when start connection, will try again #signalR #start, error:',
            error,
            'host:',
            this.host
          );
          return false;
        }

        const lastState = this._connection.state;
        const connected = lastState === HubConnectionState.Connected;

        if (!connected) {
          console.debug(
            'Connection was not established, will try again #signalR #start',
            'lastState:',
            lastState,
            'host:',
            this.host
          );
          return false;
        } else {
          console.debug(
            'Connection was established #signalR #start',
            'host:',
            this.host
          );
          return true;
        }
      }
    );

    if (!connected) {
      alert('Socket issues, please reload the page');
      throw new Error('Connection was not established');
    }
  };

  // isSameUserConnected = (userId) => userId === this._userId;

  stop = async () => {
    console.debug('#SignalRConnection #start');
    if (
      this._connection.state !== HubConnectionState.Disconnected &&
      this._connection.state !== HubConnectionState.Disconnecting
    ) {
      try {
        await this._connection.stop();
      } catch {
        // error may happen when connection is not in 'Connected' state
        // even if we check it before, it still can happen
        // it's ok, just swallow it
      }
    }
  };

  restart = async () => {
    console.debug('#SignalRConnection #restart host:', this.host);

    await this.stop();
    await this.start();
  };

  subscribe = (methodName, callback) => {
    this._connection.off(methodName, callback);
    this._connection.on(methodName, callback);
  };

  invoke = async (methodName, ...args) => {
    // console.log({ type: 'invoke', methodName, args });

    if (this.unauthorized) {
      throw new Error(
        'Unable to invoke method because unauthorized. Method: ' + methodName
      );
    }

    const initialState = this._connection.state;
    if (initialState !== HubConnectionState.Connected) {
      console.debug(
        `Connection is not established, while trying to invoke method "${methodName}"`,
        ` state: ${initialState},`,
        `#SignalRConnection #invoke`,
        `host: ${this.host}`
      );

      const connected = await waitUntil(
        '#SignalRConnection #invoke #waitUntil',
        120_000, // 2min,
        100,
        () => this._connection.state === HubConnectionState.Connected
      );

      if (!connected) {
        console.error(
          `Connection is not established, while trying to invoke method "${methodName}", state: ${this._connection.state}, #SignalRConnection #invoke`,
          `host: ${this.host}`
        );
        throw new Error('Connection is not established');
      } else {
        console.debug(
          `Connection established after waiting, will try to invoke method ${methodName} again #SignalRConnection #invoke`,
          `host: ${this.host}`
        );
      }
    }

    let isResolved = false;

    try {
      const resultPromise = this._connection.invoke(methodName, ...args);

      const intervalId = setInterval(() => {
        if (!isResolved) {
          console.error(
            'Not error, but invocation promise still not resolved, 10 sec:',
            methodName,
            args,
            '#signalR'
          );
        } else {
          clearInterval(intervalId);
        }
      }, 10_000);

      const result = await resultPromise;
      isResolved = true;
      return result;
    } catch (e) {
      isResolved = true;
      console.error(
        'Error when invoke! #SignalRConnection #invoke #DXS',
        '\n',
        `state: ${this._connection.state}, initialState: "${initialState}"`,
        '\n,method "${methodName}"',
        '\n',
        'error:',
        JSON.stringify(e),
        '\n',
        'args:',
        JSON.stringify(args || 'null!'),
        '\n',
        `host: ${this.host}`
      );
      throw e;
    }
  };
}

export function getErrorDetails(err) {
  try {
    // parse SignalR hardcoded string
    var msgJson = (
      err.message.match(/HubException: (.*)/) || [err.message]
    ).pop();
    return JSON.parse(msgJson);
  } catch (e) {
    return { id: null, message: err.message };
  }
}

export const connMarkets = new SignalRConnection(config.wsUrl + 'markets');
export const connApp = new SignalRConnection(config.wsUrl + 'app', true);
