import { computed } from 'vue';
import { useStore } from 'vuex';

export const useFiorinBalanceNull = () => {
  const store = useStore();

  const accountBalance = computed(
    () => store.getters['user/getAccountBalance']
  );

  const isFiorin = computed(() => store.getters['auth/isFiorin']);

  const firstAppLoaded = computed(
    () => store.getters['localUiSettings/firstAppLoaded']
  );

  const isFiorinNullableBalance = computed(() => {
    return (
      firstAppLoaded.value &&
      isFiorin.value &&
      accountBalance.value.available == 0
    );
  });

  return { isFiorinNullableBalance };
};
