<template>
  <Modal @close="$emit('close')" stop-mobile-max-height>
    <div class="earned-liquidity">
      <Card :title="$t('earnedLiquidityTitle')" class="card kys-status__inner">
        <div class="earned-liquidity__content">
          {{ $t('earnedLiquidityText1') }}
        </div>
        <div class="earned-liquidity__content">
          {{ $t('earnedLiquidityText2') }}
        </div>
        <div class="earned-liquidity__content">
          {{ $t('earnedLiquidityText3') }}
        </div>
      </Card>
      <div class="earned-liquidity__btns">
        <Button
          type="secondary"
          width="full"
          :text="$t('earnedLiquidityBtn1')"
          @click="onClose"
        />
        <Button
          type="secondary"
          width="full"
          :text="$t('earnedLiquidityBtn2')"
          @click="openDashboard"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { useModals } from '@/modules/modals/api';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EarnedLiquidity',
  components: { Button, Modal, Card },
  props: {
    // todo: [Vadim] unused?
    installEvent: {
      required: true,
    },
  },
  setup() {
    const onClose = () => {
      window.open('https://faq.dxs.app/trading/liquidity-fees', '_blank');
    };

    const { modalsByName, showModal } = useModals();

    const openDashboard = () => {
      showModal(modalsByName.liquidityMain);
    };

    return {
      onClose,
      openDashboard,
    };
  },
});
</script>

<style lang="scss">
.earned-liquidity {
  &__content {
    margin: 10px 0;
    line-height: 1.2;
    position: relative;
    text-align: left;
    font-family: Gotham_Pro_Regular;
  }

  &__btns {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
  }
}
</style>
