export const createIframeController = (id, hostUrl) => {
  const frameElementId = `fiorinFrame_${id}`;
  let frame = frames[id];
  if (!frame) {
    console.debug(
      'creating frame #FiorinConnector #connect, id:',
      id,
      'hostUrl:',
      hostUrl
    );

    frame = loadIframe((frame) => {
      frame.setAttribute('id', frameElementId);
      frame.setAttribute('src', hostUrl);
      frame.setAttribute('name', 'fiorinFrameName' + id);
      frame.setAttribute('frameBorder', '0');
      frame.setAttribute('allow', 'clipboard-read; clipboard-write');
      frame.setAttribute('style', 'display: none;');
      frame.setAttribute('width', '0');
      frame.setAttribute('height', '0');
    });
  } else {
    console.debug(
      'frame already exists #FiorinConnector #connect, id:' + id,
      'hostUrl:',
      hostUrl
    );
  }

  const setFrameStyles = (frame, width, height, style) => {
    frame.setAttribute('width', width);
    frame.setAttribute('height', height);

    if (style || style === '') {
      frame.setAttribute('style', style);
    }
  };

  const closeFrame = () => {
    setFrameStyles(frame, '0', '0', 'display: none;');
  };

  const showFrame = () => {
    console.debug(`#showFrame #FiorinConnector`);
    setFrameStyles(
      frame,
      '100%',
      '100%',
      'position: fixed; bottom: 0px; left: 0; z-index: 100100;'
    );
  };

  function removeFiorinFrame() {
    const frame = document.getElementById(frameElementId);
    if (frame) {
      frame.parentNode.removeChild(frame);
      return true;
    }

    return false;
  }

  function loadIframe(init) {
    const iframe = document.createElement('iframe');
    init(iframe);

    // Create a timeout to handle loading failures
    const timeout = setInterval(() => {
      console.warn('Iframe is taking too long to load. #DXS #loadIframe');
    }, 10000);

    iframe.onload = () => {
      console.debug(
        'Iframe loaded successfully. #loadIframe #iframeController'
      );
      clearTimeout(timeout);
    };

    // Handle iframe error event (though it may not always fire)
    iframe.onerror = (error) => {
      clearTimeout(timeout);
      console.error(
        'Iframe failed to load (onerror event). #DXS #loadIframe, error:',
        error
      );
    };

    document.body.appendChild(iframe);
    return iframe;
  }

  return {
    frame,
    closeFrame,
    showFrame,
    removeFiorinFrame,
  };
};
