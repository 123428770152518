import Connector from './Connector';
import { waitUntil } from '@/helpers/retry';
import { setupIframeConnection } from '@/wallets/fiorin/iframeConnection';
import { createIframeController } from '@/wallets/fiorin/iframeController';
import { v4 as uuidv4 } from 'uuid';

const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

const userEvents = [
  'READY',
  'ERROR',
  'REFILL_COMPLETED',
  'WITHDRAWAL',
  'DEPOSIT',
  'BALANCES',
  'PENDING_BALANCE',
  'BOUNTY_BALANCE',
  'AUTHORIZED',
  'AUTHORIZED_COMPLETED',
  'LOGOUT_COMPLETED',
  'CLOSED',
];

const systemEvents = [
  'READY',
  'AUTH_REQUIRED',
  'LOGIN_REQUIRED',
  'OPEN_ME',
  'OPEN_LINK',
  'CLOSED',
  'DECLINED',
  'DISCONNECTED',
  'BALANCES_REFRESHED',
  'SUBSCRIBE_BALANCE_CHANGES',
  'STORAGE_RECOVERED',
];

const allEvents = [...userEvents, ...systemEvents];

export default class FiorinConnector extends Connector {
  constructor(eventHandlers, opts) {
    console.debug('#FiorinConnector #constructor');
    super(opts);

    this.id = opts.id || uuidv4();
    this.provider = 'Fiorin';
    this.eventHandlers = eventHandlers;
    this.balancesLog = [];
    this.prefix = 'fiorin_' + Math.floor(10000 * Math.random());
    this.packet = opts.packet;

    this.accessToken = opts.accessToken;
    this._frameConnection = null;
    this._frameController = null;
  }

  async viewDeposit() {
    await this._frameConnection.sendMessageAsync('VIEW:DEPOSIT', null);
  }

  async viewWallet() {
    await this._frameConnection.sendMessageAsync('VIEW:WALLET', null);
  }

  async refill(payload) {
    //todo: [vadim] validate payload

    console.debug('#FiorinConnector #refill', payload);

    if (!payload.type) {
      throw new Error('Refill type is required');
    }

    if (!payload.amount) {
      throw new Error('Refill amount is required');
    }

    if (!payload.message) {
      throw new Error('Refill message is required');
    }

    const response = await this._frameConnection.sendMessageAsync(
      'REFILL',
      payload
    );

    return response?.payload;
  }

  async logout() {
    await this._frameConnection.sendMessageAsync('LOGOUT', null);
  }

  async disconnect() {
    this._frameConnection?.disconnect();
    this._frameController?.removeFiorinFrame();

    this._frameConnection = null;
    this._frameController = null;

    await this.eventHandlers.DISCONNECTED(this.id);
  }

  async connect({ isMetamask, isTrustWallet, isWalletConnect, isSignup } = {}) {
    console.debug(
      `#connect #FiorinConnector 
      isMetamask: ${isMetamask} 
      isTrustWallet: ${isTrustWallet}
      isWalletConnect: ${isWalletConnect} 
      isSignup: ${isSignup}
      host: ${FIORIN_HOST}`
    );

    const eventHandlers = this.eventHandlers;
    validateEventHandlers(eventHandlers, userEvents, allEvents);

    const [, hostUrl] = [
      [isMetamask, `${FIORIN_HOST}/web3?wallet=metamask`],
      [isTrustWallet, `${FIORIN_HOST}/web3?wallet=trust`],
      [isWalletConnect, `${FIORIN_HOST}/web3`],
      [isSignup, `${FIORIN_HOST}/sign-up`],
      [true, FIORIN_HOST],
    ].find(([predicate]) => predicate);

    const id = this.id;
    const frameController = createIframeController(id, hostUrl);

    let anyMessageReceived = false;
    let authenticated = false;

    const frameConnection = setupIframeConnection(
      frameController.frame,
      FIORIN_HOST,
      '#FiorinConnector',
      async (event) => {
        const eventName = event.data.event;
        const payload = event.data.payload;

        anyMessageReceived = true;

        if (!allEvents.includes(eventName)) {
          console.error(
            `Unknown event: '${eventName}' #FiorinConnector #onMessageHandler`
          );
          return;
        }

        let sendMessageToFiorinAsync = frameConnection.sendMessageAsync;

        if (eventName === 'READY') {
          await eventHandlers.READY(id);
          return;
        }

        if (eventName === 'AUTH_REQUIRED') {
          if (
            !(await restoreIframeLocalStorageAndAuthenticate(
              this.packet,
              sendMessageToFiorinAsync
            ))
          ) {
            this.notifyHasAuthResult(false);
            frameController.showFrame();
          } else {
            this.notifyHasAuthResult(true);
          }

          return;
        }

        if (eventName === 'OPEN_ME') {
          frameController.showFrame();
          return;
        }

        if (eventName === 'LOGIN_REQUIRED') {
          if (
            !(await restoreIframeLocalStorageAndAuthenticate(
              this.packet,
              sendMessageToFiorinAsync
            ))
          ) {
            this.notifyHasAuthResult(false);
            frameController.showFrame();
          } else {
            this.notifyHasAuthResult(true);
          }
          return;
        }

        if (eventName === 'OPEN_LINK') {
          window.open(payload.url, payload.target, payload.features);
          return;
        }

        if (eventName === 'AUTHORIZED') {
          authenticated = true;
          frameController.closeFrame();

          await eventHandlers.AUTHORIZED(id, payload);
          // this place was for the case when we had to do something after AUTHORIZED but before AUTHORIZED_COMPLETED
          await eventHandlers.AUTHORIZED_COMPLETED(id, {
            payload,
            connector: this,
          });

          // should be called only after eventHandlers.AUTHORIZED
          this.notifyHasAuthResult(true);

          await sendMessageToFiorinAsync('SUBSCRIBE_BALANCE_CHANGES', null);
          return;
        }

        if (eventName === 'LOGOUT_COMPLETED') {
          const hadIframe = frameController.removeFiorinFrame();
          if (hadIframe) {
            frameConnection.disconnect();
            this.notifyHasAuthResult(false);
            await eventHandlers.LOGOUT_COMPLETED(id);
            return true;
          }

          this.notifyHasAuthResult(false);
          return false;
        }

        if (eventName === 'CLOSED' || eventName === 'DECLINED') {
          frameController.closeFrame();
          if (!authenticated) {
            frameConnection.disconnect();
            frameController.removeFiorinFrame();
            this.notifyHasAuthResult(false);
          }
          await eventHandlers.CLOSED(id);
          return;
        }

        if (eventName === 'ERROR') {
          await eventHandlers.ERROR(id, payload);
          return;
        }

        if (
          eventName === 'SUBSCRIBE_BALANCE_CHANGES' ||
          eventName === 'BALANCES_REFRESHED'
        ) {
          // nothing to do here
          return;
        }

        if (userEvents.includes(eventName)) {
          await eventHandlers[eventName](id, payload);
          return;
        } else {
          console.error(
            `Unhandled event: '${eventName}' #FiorinConnector #onMessageHandler`
          );
        }
      }
    );

    let fiorinStartedWorking = await waitUntil(
      '#fiorin #connector #connect',
      60_000,
      100,
      () => anyMessageReceived
    );

    if (!fiorinStartedWorking) {
      console.error(
        'Fiorin did not start working in 60 seconds #FiorinConnector #connect'
      );
    }

    this._frameConnection = frameConnection;
    this._frameController = frameController;
    console.debug(
      'exit #connect #FiorinConnector, frame:',
      frameController.frame
    );
    return this;
  }
}

async function restoreIframeLocalStorageAndAuthenticate(
  packet,
  sendMessageToFiorinAsync
) {
  // const packet = localStorage.getItem('_fp');
  if (packet) {
    console.debug('Sending packet to #FiorinConnector packet');
    try {
      const result = await sendMessageToFiorinAsync('RECOVER_STORAGE', {
        packet,
      });
      const authenticated = result.authenticated;
      if (typeof authenticated !== 'boolean') {
        console.error(
          'Invalid response from Fiorin: authenticated is not bool. #FiorinConnector #restoreIframeLocalStorageAndAuthenticate',
          result
        );
        return false;
      }
      return authenticated;
    } catch (e) {
      console.error(
        'Error when send packet to Fiorin. #FiorinConnector #restoreIframeLocalStorageAndAuthenticate',
        e
      );

      return false;
    }
  }

  return false;
}

function validateEventHandlers(eventHandlers, userEvents, allEvents) {
  {
    if (!eventHandlers) {
      throw new Error(
        'eventHandlers is required parameter #FiorinConnector #connect'
      );
    }

    if (typeof eventHandlers !== 'object') {
      throw new Error(
        'eventHandlers should be an object #FiorinConnector #connect'
      );
    }

    const missingHandlers = [];
    for (let handler of userEvents) {
      if (typeof eventHandlers[handler] !== 'function') {
        missingHandlers.push(handler);
      }
    }

    if (missingHandlers.length) {
      throw new Error(
        `Missing handlers: ${missingHandlers.join(
          ', '
        )} #FiorinConnector #connect`
      );
    }

    const unknownHandlers = [];
    for (let handler in eventHandlers) {
      if (!allEvents.includes(handler)) {
        unknownHandlers.push(handler);
      }
    }

    if (unknownHandlers.length) {
      throw new Error(
        `Unknown handlers: ${unknownHandlers.join(
          ', '
        )} #FiorinConnector #connect`
      );
    }
  }
}
