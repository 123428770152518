import { connMarkets } from '@/helpers/signalR.js';
import { createEntityFromMarket } from '@/modules/markets/helpers';
import { getSleepInfo } from '@/helpers/sleep';
import debounce from 'lodash/debounce';
import { CONNECTION_LOST_TIMEOUT, DOCUMENT_STATES } from '@/helpers/enums';
import { setLocalItem, getLocalItem } from '@/helpers/storage';

const DEPAY_BEFORE_FLUSH_MS = 1200;

let marketCache = {};
let shouldFlush = true;
let timeoutConnectionLost = null;
let connected = true;

const onTickerReceive = (store, marketUpdates) => {
  const fullMarket = {
    ...store.getters['markets/marketsById'][marketUpdates.id],
    ...marketUpdates,
  };
  const market = createEntityFromMarket(fullMarket);

  marketCache[market.id] = market;

  if (document[getSleepInfo().hidden]) {
    // store.dispatch('markets/setLoadingAfterSleep', true);
    // setLoadingStatus(store, true);
    return;
  }

  if (store.getters['markets/loadingAfterSleep']) {
    // store.dispatch('markets/setLoadingAfterSleep', false);
    // setTimeout(() => {
    //   setLoadingStatus(store, false);
    // }, 500);
  }

  const connectionTimeoutCheck = debounce(() => {
    if (timeoutConnectionLost) {
      clearTimeout(timeoutConnectionLost);
    }

    if (!connected) {
      store.commit('connectors/UPDATE_CONNECTION_STATUS', true);
    }

    connected = true;

    timeoutConnectionLost = connectionLostInit(store);
  }, 2000);

  if (shouldFlush) {
    const cachedMarkets = Object.values(marketCache);

    store.commit('markets/UPDATE_MARKETS', cachedMarkets);

    marketCache = {};

    shouldFlush = false;

    setTimeout(() => {
      shouldFlush = true;
    }, DEPAY_BEFORE_FLUSH_MS);
  }
  connectionTimeoutCheck();
};

const connectionLostInit = (store) => {
  return setTimeout(() => {
    if (getLocalItem('documentState') !== DOCUMENT_STATES.hidden) {
      connected = false;
      store.commit('connectors/UPDATE_CONNECTION_STATUS', connected);
    }
  }, CONNECTION_LOST_TIMEOUT);
};

function setLoadingStatus(store, value) {
  store.commit('connectors/UPDATE_LOADING_STATUS', value);
  store.commit('positions/UPDATE_LOADING_STATUS', value);
  store.commit('positions/UPDATE_LOADING_SLEEP_STATUS', value);
  store.commit('markets/UPDATE_LOADING_STATUS', value);
}

function onDeleteMarket(store, id) {
  store.commit('markets/DELETE_MARKET', id);
}

export default (store) => {
  document.addEventListener('visibilitychange', () => {
    const currentState = document.visibilityState;

    if (currentState === DOCUMENT_STATES.visible) {
      setTimeout(() => {
        setLocalItem('documentState', currentState);
      }, 1000);

      setTimeout(() => {
        setLoadingStatus(store, false);
      }, 10000);
    } else {
      setLoadingStatus(store, true);
      setLocalItem('documentState', currentState);
    }
  });
  timeoutConnectionLost = connectionLostInit(store);

  const onMarketDeleted = (data) => onDeleteMarket(store, data);
  const onTicker = (data) => {
    const [id, buy, sell, price24h, timeline] = data;
    onTickerReceive(store, { id, buy, sell, price24h, timeline });
  };

  connMarkets.subscribe('OnMarketDisabled', onMarketDeleted);
  connMarkets.subscribe('OnTickerSlim', onTicker);
};
