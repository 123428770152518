export const WalletNames = {
  demo: '',
  moneyButton: 'MoneyButton',
  relayX: 'RelayX',
  dotWallet: 'DotWallet',
  handCash: 'HandCash',
  fiorin: 'Fiorin',
};

export const WalletNamesList = Object.values(WalletNames);
