import store from '@/store';
import notify from '@/plugins/notify';
import { WalletNames } from '@/wallets/common';

function getFiorinEventHandlers() {
  return {
    READY: async () => {},

    AUTHORIZED: async () => {},

    AUTHORIZED_COMPLETED: async (id, { payload, connector }) => {
      await store.dispatch('fiorin/clear');

      if (!connector) {
        throw new Error('No connector in AUTHORIZED_COMPLETED event');
      }

      if (!payload) {
        throw new Error('No payload in AUTHORIZED_COMPLETED event');
      }

      const user = payload.user;
      if (!user.token) {
        throw new Error('No token in AUTHORIZED_COMPLETED event');
      }

      if (!payload.balances) {
        throw new Error('No balances in AUTHORIZED_COMPLETED event');
      }

      const userName = user.username;
      const paymail = user.bsvAccountAddress;
      const accessToken = user.token;
      const packet = user.packet;
      const targetWeb3Provider = user.provider;
      const email = user.email;

      //todo: [vadim] when we have targetProvider,what will be the paymail?

      await store.dispatch('fiorin/init', {
        payload: {
          paymail,
          userName,
          email,
          targetWeb3Provider,
        },
        connector,
      });

      await store.dispatch('fiorin/setBalances', payload.balances);

      await store.dispatch('auth/onAuthenticated', {
        id,
        userName,
        accessToken,
        packet,
        provider: WalletNames.fiorin,
        targetWeb3Provider,
      });
      await store.dispatch('localUiSettings/setShowConnectModal', false);
    },

    ERROR: (id, payload) => {
      notify({
        text: payload.message || payload.error,
        type: 'info',
      });
    },

    REFILL_COMPLETED: async (id, payload) => {
      console.debug(
        'REFILL_COMPLETED, calling fiorin/setFiorinLastTxId',
        payload
      );
      await store.dispatch('fiorin/setFiorinLastTxId', payload.txId);
    },

    WITHDRAWAL: () => {
      notify({
        text: 'Sending funds...',
        type: 'info',
      });
    },

    DEPOSIT: () => {
      notify({
        text: 'Receiving funds...',
        type: 'info',
      });
    },

    BALANCES: async (id, payload) => {
      await store.dispatch('fiorin/setBalances', payload);
    },

    PENDING_BALANCE: async (id, payload) => {
      await store.dispatch('fiorin/setBalances', payload);
    },

    BOUNTY_BALANCE: async (id, payload) => {
      await store.dispatch('fiorin/setBalances', payload);
      // [Vadim]: disabled since not needed
      // await store.dispatch('bounty/checkBountyUpdated');
    },

    DISCONNECTED: async () => {
      await store.dispatch('fiorin/reset');
    },

    LOGOUT_COMPLETED: async () => {
      await store.dispatch('auth/onLogout');
      await store.dispatch('localUiSettings/setShowConnectModal', false);
    },

    CLOSED: async () => {
      await store.dispatch('localUiSettings/setShowConnectModal', false);
    },
  };
}

export default getFiorinEventHandlers;
