<template>
  <div class="liquidity-table">
    <div class="liquidity-table__headers">
      <div
        v-for="(head, ndx) in tableHeaders"
        :key="ndx"
        class="liquidity-table__headers-item"
      >
        {{ $t(head.title) }}{{ head.postfix }}
      </div>
    </div>
    <div class="separator" />
    <div class="liquidity-table__body">
      <div
        v-for="round in roundsItems && roundsItems.length
          ? roundsItems
          : !userFundedFilter
          ? liquidityInfo.rounds
          : liquidityInfo.rounds.filter((it) => it.userFunded > 0)"
        :key="round.roundId"
        class="liquidity-table__row"
      >
        <div class="liquidity-table__item">{{ round.roundId }}</div>
        <div class="liquidity-table__item">
          {{
            $isFiorinMode
              ? numberWithSpaces(+round.userFunded.toFixed())
              : bsvFormat2(round.userFunded)
          }}<span class="text-grey">/</span><br />
          <div
            class="text-grey"
            v-html="
              round.roundId === 28
                ? infinitySpan
                : $isFiorinMode
                ? numberWithSpaces(+round.userLimit.toFixed())
                : bsvFormat2(round.userLimit)
            "
          />
        </div>
        <div class="liquidity-table__item">
          {{
            $isFiorinMode
              ? numberWithSpaces(+round.totalFunded.toFixed())
              : bsvFormat2(round.totalFunded)
          }}<span class="text-grey">/</span><br />
          <div
            class="text-grey"
            v-html="
              round.roundId === 28
                ? infinitySpan
                : $isFiorinMode
                ? numberWithSpaces(+round.totalLimit.toFixed())
                : bsvFormat2(round.totalLimit)
            "
          />
        </div>
        <div class="liquidity-table__item">{{ round.returnPct }} %</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue';
import notify from '@/plugins/notify';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import { bsvFormat2, toCurrencyFormat } from '@/helpers/utils';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default {
  mixins: [authMixin],
  props: {
    roundsItems: { default: [] },
    userFundedFilter: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const auth = useAuth();
    const liquidityInfo = ref({ rounds: [] });
    const userShare = ref(0);
    const liquidityTransactions = ref([]);
    const liquidityProvisions = ref([]);
    const infinitySpan = ref('<span class="inf">&infin;</span>');

    const currency = computed(() => (auth.isFiorinMode.value ? ' $' : ' Ƀ'));

    const tableHeaders = computed(() => [
      { title: 'popups.liquidityFundraiser.round' },
      {
        title: 'popups.liquidityFundraiser.yourLimit',
        postfix: currency.value,
      },
      {
        title: 'popups.liquidityFundraiser.totalLimit',
        postfix: currency.value,
      },
      { title: 'popups.liquidityFundraiser.return' },
    ]);

    const getDetails = async () => {
      let error;
      let result = await connApp
        .invoke('GetLiquidityProvidedPage', 0, 100)
        .catch((err) => {
          error = getErrorDetails(err);
        });
      if (!error) {
        liquidityInfo.value = result;
        liquidityProvisions.value = result.liquidityProvisions;

        userShare.value =
          toCurrencyFormat(liquidityInfo.value.userSharePct, null, 5) + '%';
        liquidityTransactions.value =
          liquidityInfo.value.provideLiquidityTransactions;

        emit('getUserShare', userShare.value);
        emit('getLiquidityTransactions', liquidityProvisions.value);
      } else {
        notify({ text: error.message, type: 'info' });
      }
    };

    const numberWithSpaces = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    onMounted(() => {
      getDetails();
    });

    return {
      numberWithSpaces,
      tableHeaders,
      liquidityInfo,
      bsvFormat2,
      infinitySpan,
    };
  },
};
</script>
<style lang="scss" scoped>
.liquidity-table {
  display: flex;
  flex-direction: column;

  &__headers {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr 1.2fr 1.1fr;
  }

  &__headers-item {
    font-size: 14px;
    color: grey;
    width: fit-content;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__row {
    min-height: 36px;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr 1.2fr 1.1fr;
    margin-bottom: 7px;

    &:first-child {
      margin-top: 7px;
    }
  }

  &__item {
    font-size: 1rem;
    font-family: Cantarell_Regular;
    width: 80px;

    .text-grey {
      color: grey;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background: grey;
  }
}
</style>
