import Connector from './Connector';
import config from '@/config';

export default class HandcashConnector extends Connector {
  constructor(opts) {
    super(opts);

    // [Vadim] just in case, I make it compatible with the old code
    if (opts.accessToken === 'undefined') {
      throw new Error('Invalid access token');
    }

    this.id = opts.id;
    this.connection = undefined;
    this.provider = 'HandCash';
    this.accessToken = opts.accessToken;
    this.appId = config.hcClientId;
  }

  async _getConnect() {
    if (this.connection) {
      return this.connection;
    }

    const { HandCashConnect, Environments } = await import(
      '@handcash/handcash-connect'
    );

    this.connection = new HandCashConnect({
      appId: this.appId,
      appSecret: 'hc-fake-secret', // set on the back-end
      env: {
        apiEndpoint: `${config.apiUrl}hc`, // proxy API requests through the back-end
        clientUrl: Environments.prod.clientUrl,
      },
    });

    return this.connection;
  }

  async _getAccount() {
    const connect = await this._getConnect();

    return connect.getAccountFromAuthToken(this.accessToken);
  }

  async fetchBalance() {
    const account = await this._getAccount();
    const { spendableSatoshiBalance, spendableFiatBalance } =
      await account.wallet.getSpendableBalance();

    return {
      amountBsv: spendableSatoshiBalance / 100000000,
      amountUsd: spendableFiatBalance,
    };
  }

  async fetchProfile() {
    const account = await this._getAccount();

    return await account.profile.getCurrentProfile();
  }

  async sendHandCashTx({ amount, description, to, msg }) {
    if (window.HC_PROCESSED) {
      return;
    }

    const paymentParameters = {
      description,
      appAction: 'Open position',
      payments: [{ to, currencyCode: 'BSV', amount }],
      attachment: {
        format: 'hexArray',
        value: msg,
      },
    };

    let paymentResult;

    window.HC_PROCESSED = true;

    const account = await this._getAccount();

    try {
      paymentResult = await account.wallet.pay(paymentParameters);
      window.HC_PROCESSED = false;
    } catch (err) {
      window.HC_PROCESSED = false;
      paymentResult = { error: err && err.response && err.response.data };
    }

    return paymentResult;
  }

  async connect() {
    console.debug('connect #HandCash');
    if (this.accessToken) {
      console.debug('Already connected to HandCash');
      return;
    }
    const connect = await this._getConnect();
    const redirectionLoginUrl = connect.getRedirectionUrl();
    window.location.href = redirectionLoginUrl;
  }

  async disconnect() {
    this.accessToken = null;
    this.connection = null;
  }
}
