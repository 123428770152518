<template>
  <div id="v-step-4" :class="{ openCard }" class="market-info">
    <div v-if="!hideToggle" class="market-info__title" @click="toggleCard">
      {{ parseTitle($t('blocks.titles.marketData')) }}
      <Icon
        :class="{ openCard }"
        name="arrow_down"
        width="12"
        height="12"
        class="arrow-icon"
      />
    </div>
    <ItemLabelValue :label="$t('marketData.titles.name')" :value="name" />
    <ItemLabelValue
      :label="$t('marketData.titles.holdingFee')"
      :value="decayRate + '%'"
      :hint-options="{ top: '7px', left: '65px' }"
      :hint-title="$t('marketData.hints.holdingFee')"
    />
    <ItemLabelValue :label="$t('marketData.titles.tradingFee')" value="0%" />

    <ItemLabelValue
      :label="$t('marketData.titles.semiSpread')"
      :value="`${spread}%`"
      :hint-options="{ top: '-15px', left: '65px' }"
      :hint-title="$t('marketData.hints.semiSpread')"
    />
    <ItemLabelValue
      :label="$t('marketData.titles.scalpingThreshold')"
      :hint-options="{ top: '8px', left: '65px' }"
      :hint-title="$t('marketData.titles.scalpingThresholdHint')"
      :value="`${scalpPrice}%`"
    />
    <ItemLabelValue
      :label="$t('marketData.titles.maxLeverage')"
      :value="`${(activeMarket && activeMarket.max_leverage) || 0}x`"
    />
    <ItemLabelValue
      :label="$t('marketData.titles.userAmount')"
      :is-html-value="true"
      :value="`$${toCurrencyFormat(userPositionsAmount)} <span>/</span> $${
        activeMarket && toCurrencyFormat(userMaxAmount, null, 2)
      }`"
    />
    <ItemLabelValue
      v-if="timeToTitle"
      :label="$t(timeToTitle)"
      :value="activeMarket && workHours(new Date(), activeMarket)"
    />
    <!-- <ItemLabelValue
      :label="$t('blocks.titles.volumeTier')"
      :hint-options="{ top: '-75px', left: '100px' }"
      :hint-title="$t('blocks.titles.volumeTierHint')"
      :is-html-value="true"
      :value="volumeCalculations"
    />-->
    <ItemLabelValue
      v-if="loyaltyFeeInfo?.profitTier"
      :label="$t('blocks.titles.profitTier')"
      :hint-options="{ top: '-85px', left: '90px' }"
      :hint-title="$t('blocks.titles.profitTierHint')"
      :is-html-value="true"
      :value="profitCalculations"
    />
    <ItemLabelValue
      v-if="+loyaltyFeeInfo?.loyaltyFeeRate"
      :label="$t('blocks.titles.loyaltyFee')"
      :hint-options="{ top: '-55px', left: '100px' }"
      :hint-title="$t('blocks.titles.loyaltyFeeHint')"
      :value="`${loyaltyFeeInfo?.loyaltyFeeRate?.toFixed(2)}%`"
    />
    <div
      class="description"
      :class="{ active: activeDescription }"
      v-if="description"
      @click="activeDescription = !activeDescription"
    >
      {{ description }}
    </div>
  </div>
</template>
<script>
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import Icon from '@/components/ui/Icon.vue';
import '@/assets/icons/arrow_down';
import useToggleCard from '@/compositions/useToggleCard';
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { marketLabel } from '@/config/wrapMarkets';
import { exchangeList, descriptionList } from '@/helpers/floatList';
import { toCurrencyFormat, workHours, usdFormat } from '@/helpers/utils';
import bsvPrice from '@/compositions/bsvPrice';
import config from '@/config';
// import sumBy from 'lodash/sumBy';
import { calcMarketSpread } from './helpers';
import { parseTitle } from '@/helpers/ui';
import { connApp } from '@/helpers/signalR';
import { PositionTypes } from '@/modules/positions/helpers';
import useAuth from '@/compositions/useAuth';

export default {
  name: 'MarketInfo',
  components: { ItemLabelValue, Icon },
  props: {
    hideToggle: Boolean,
  },
  setup() {
    const auth = useAuth();
    const store = useStore();

    const scalpPrice = ref(0);

    const { openCard, toggleCard } = useToggleCard();
    const activeMarket = computed(() => store.getters['markets/activeMarket']);
    const activeDescription = ref(false);

    const name = computed(() => {
      if (!activeMarket.value) {
        return '';
      }

      let name = marketLabel(activeMarket.value.name);

      if (!name) {
        return '';
      }

      let exchange =
        activeMarket.value.source || exchangeList[activeMarket.value.name];

      if (!exchange) {
        return name;
      }

      return `${name} (${exchange})`;
    });

    // const positions = computed(() => {
    //   const positionsByType = store.getters['positions/getPositionsByType'];

    //   return (
    //     (positionsByType &&
    //       positionsByType.open &&
    //       positionsByType.open.concat(positionsByType.pending || [])) ||
    //     []
    //   );
    // });

    const userPositionsAmount = computed(() => {
      if (!activeMarket.value) {
        return 0;
      }

      return store.getters['volumeLimits/groupActiveAmount'];

      // return sumBy(
      //   positions.value.filter((pos) => activeMarket.value.name === pos.market),
      //   (pos) => pos.amount
      // );
    });

    const decayRate = computed(() => {
      if (!activeMarket.value) {
        return 0;
      }

      return toCurrencyFormat(100 * activeMarket.value.decay_rate);
    });

    const userMaxAmount = computed(() => {
      const maxAmount = activeMarket.value.max_amount_usd;

      // const ceiledMaxAmount = maxAmount / config.maxAmountCeiling;

      return maxAmount > 0 ? maxAmount : 0;
    });

    const spread = computed(() => {
      if (!activeMarket.value) {
        return 0;
      }

      return calcMarketSpread(activeMarket.value);
    });

    const timeToTitle = computed(() => {
      if (!activeMarket.value) {
        return '';
      }

      if (activeMarket.value.until === '24/7') {
        return 'marketData.titles.workingTime';
      }

      if (activeMarket.value.is_work_time) {
        return 'marketData.titles.timeToClosing';
      }

      return 'marketData.titles.timeToOpening';
    });

    const description = computed(() => {
      if (!activeMarket.value) {
        return '';
      }

      return descriptionList[activeMarket.value.name];
    });

    watch(
      () => auth.isLoggedIn.value,
      async () => {
        if (activeMarket.value?.id && auth.isLoggedIn.value) {
          try {
            const scalpingPrice = await connApp.invoke(
              'GetMarketLimitInfo',
              activeMarket.value?.id
            );
            scalpPrice.value = scalpingPrice?.scalpingBalanceRatio || 0;
            console.log('GetMarketLimitInfo', scalpingPrice);
          } catch (err) {
            console.log('scalpingPrice err', err);
          }
          await store.dispatch(
            'volumeLimits/fetchLoyaltyFeeInfo',
            activeMarket.value.id
          );
          await store.dispatch(
            'volumeLimits/fetchGroupActiveAmount',
            activeMarket.value.id
          );
        }
      }
    );

    const openPositions = computed(
      () =>
        store.getters['positions/getPositionsByType'][PositionTypes.open]
          ?.length
    );

    watch(
      () => openPositions.value,
      async () => {
        if (auth.isLoggedIn.value && activeMarket.value?.id) {
          setTimeout(async () => {
            try {
              const scalpingPrice = await connApp.invoke(
                'GetMarketLimitInfo',
                activeMarket.value?.id
              );
              scalpPrice.value = scalpingPrice?.scalpingBalanceRatio || 0;
              console.log('GetMarketLimitInfo', scalpingPrice);
            } catch (err) {
              console.log('scalpingPrice err', err);
            }
          }, 2000);
        }
      }
    );

    watch(
      () => activeMarket.value?.id,
      async () => {
        if (auth.isLoggedIn.value && activeMarket.value?.id) {
          try {
            const scalpingPrice = await connApp.invoke(
              'GetMarketLimitInfo',
              activeMarket.value?.id
            );
            scalpPrice.value = scalpingPrice?.scalpingBalanceRatio || 0;
            console.log('GetMarketLimitInfo', scalpingPrice);
          } catch (err) {
            console.log('scalpingPrice err', err);
          }
          await store.dispatch(
            'volumeLimits/fetchLoyaltyFeeInfo',
            activeMarket.value.id
          );
          await store.dispatch(
            'volumeLimits/fetchLoyaltyFeeRate',
            activeMarket.value.id
          );
          await store.dispatch(
            'volumeLimits/fetchGroupActiveAmount',
            activeMarket.value.id
          );
        }
      }
    );

    onMounted(async () => {
      if (auth.isLoggedIn.value && activeMarket?.value?.id) {
        try {
          const scalpingPrice = await connApp.invoke(
            'GetMarketLimitInfo',
            activeMarket.value?.id
          );
          scalpPrice.value = scalpingPrice?.scalpingBalanceRatio || 0;
          console.log('GetMarketLimitInfo', scalpingPrice);
        } catch (err) {
          console.log('scalpingPrice err', err);
        }
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeInfo',
          activeMarket.value.id
        );
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeRate',
          activeMarket.value.id
        );
        await store.dispatch(
          'volumeLimits/fetchGroupActiveAmount',
          activeMarket.value.id
        );
      }
    });

    const loyaltyFeeInfo = computed(
      () => store.getters['volumeLimits/loyaltyFeeInfo']
    );

    const loyaltyFeeRate = computed(
      () => store.getters['volumeLimits/loyaltyFeeRate']
    );

    const volumeCalculations = computed(() => {
      return `${loyaltyFeeInfo.value?.volumeTier} (${loyaltyFeeInfo.value?.volumeTierProgress}%)`;
    });

    const profitCalculations = computed(() => {
      return `${loyaltyFeeInfo.value?.profitTier} (${loyaltyFeeInfo.value?.profitTierProgress}%)`;
    });

    return {
      volumeCalculations,
      profitCalculations,
      userPositionsAmount,
      toCurrencyFormat,
      timeToTitle,
      userMaxAmount,
      openCard,
      toggleCard,
      activeMarket,
      name,
      decayRate,
      spread,
      description,
      activeDescription,
      workHours,
      bsvPrice,
      config,
      loyaltyFeeRate,
      loyaltyFeeInfo,
      usdFormat,
      parseTitle,
      scalpPrice,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.market-info {
  padding: 18px 20px 5px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 57px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .description {
    font-size: 0.825rem;
    font-family: Gotham_Pro_Regular;
    line-height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    height: 30px;
    margin-bottom: 15px;
    color: $color-gray;

    &.active {
      height: auto;
    }
  }

  &.openCard {
    min-height: auto;
    height: auto;
  }

  &__title {
    @include card-title;
  }
}
</style>
