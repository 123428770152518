export const DEFAULT_MARKET_BSV_USD = 'BSV/USD';

export const PositionSides = {
  BUY: 'buy',
  SELL: 'sell',
  PROPOSED: 'proposed',
};

export const ANIMATION_DELAY_MS = 600;

const fiorinStables = ['bsv', 'btc', 'eth'];

export const isFiorinStable = (currency) => {
  if (!currency) {
    return false;
  }

  return fiorinStables.includes(currency.toLowerCase());
};
