<template>
  <div>
    <div v-if="isBounty">
      <BountyButton
        :loading="bountyTxLoading"
        :margin-btn-value="
          toUSDMarginFormat(+formData.amountBtc / +formData.leverage, 2)
        "
        :fiorin-mode="$isFiorinMode"
        :margin="marginBsv"
        @onClick="openBountyPosition"
      />
    </div>
    <div v-else>
      <div v-if="$isFiorin">
        <!-- FIORIN -->
        <HandCash
          :loading="walletTxLoading"
          :margin-btn-value="
            toUSDMarginFormat(+formData.amountBtc / +formData.leverage, 2)
          "
          :margin="marginBsv"
          :fiorin-currency="activeTokenName"
          is-usd
          @onClick="openFiorinPosition"
        />
      </div>
      <div v-if="$isHandCash">
        <HandCash
          :loading="walletTxLoading"
          :margin-btn-value="marginValueCustom"
          :margin="marginValueCustom"
          @onClick="openHandCashPosition"
        />
      </div>
      <div v-if="$isDotWallet" style="width: 210px">
        <DotwalletButton
          :address="to"
          :amount="+marginBsv"
          :opreturn="transactionKey.toHex()"
          product-name="Position"
          @payment="onPayment"
          @error="$emit('error')"
        />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-ignore */
import { mapGetters } from 'vuex';
import { defineComponent, computed } from 'vue';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import HandCash from '@/components/HandCashButton';
import BountyButton from '@/components/BountyButton';
import DotwalletButton from '@/components/DotwalletButton/DotwalletButton';
import { proposeBountyPosition } from '@/modules/bounty/api';
import authMixin from '@/mixins/auth.mixin';
import { useStore } from 'vuex';

import {
  paymentUID,
  toCurrencyFormat,
  toUSDMarginFormat,
  toSentenceCase,
  trim,
} from '@/helpers/utils';
import envConfig from '@/config';
import notify from '@/plugins/notify';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import useUserActions from '@/compositions/useUserActions';
import { NullDataBuilder } from 'dxs-stas-sdk/dist/script/build/null-data-builder';
import store from '@/store';

export default defineComponent({
  mixins: [authMixin],
  setup(_, { emit }) {
    const store = useStore();
    const userComposition = useUserActions();
    const activeTokenName = computed(
      () => store.getters['formPosition/collateralToken/name']
    );

    const onSuccess = () => {
      if (
        userComposition.isHandCashProfile.value &&
        !userComposition.isHasEverOpenedPosition.value
      ) {
        userComposition.notifyHandCashBalance();
      }
    };

    return { emit, onSuccess, toUSDMarginFormat, activeTokenName };
  },
  emits: ['error'],
  components: {
    HandCash,
    BountyButton,
    DotwalletButton,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: PositionSides.BUY,
    },
    to: {
      type: String,
      default: '',
    },
    amount: {
      type: [String, Number],
      default: 0.01,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    marginValueCustom: {
      required: true,
    },
    isBounty: Boolean,
  },
  data() {
    return {
      walletTxLoading: false,
      bountyTxLoading: false,
      defaultCurrency: 'BSV',
      marketId: null,
    };
  },
  computed: {
    ...mapGetters({
      activeMarketId: 'markets/activeMarketId',
      activeMarket: 'markets/activeMarket',
      marketsList: 'markets/markets',
      paymail: 'wallet/paymail',
      fiorinLastTx: 'fiorin/fiorinLastTx',
    }),

    // todo: [Vadim] rename: to activeTokenCurrency
    tokenIdCurrency() {
      if (this.$isFiorin) {
        return store.getters['formPosition/collateralToken/currency'];
      }

      return null;
    },

    tokenId() {
      if (this.$isFiorin) {
        return store.getters['formPosition/collateralToken/tokenId'];
      }

      return null;
    },

    marginBsvValue() {
      const margin =
        +this.formData.amountBtc.replace(/ /g, '') /
        bsvPrice() /
        `${this.formData.leverage}`.replace(/ /g, '');
      return margin;
    },
    marginFiorinValue() {
      const margin =
        +this.formData.amountBtc.replace(/ /g, '') /
        `${this.formData.leverage}`.replace(/ /g, '');
      return toUSDMarginFormat(margin, 2);
    },
    marginFromTokenId() {
      const findMarket =
        store.getters['formPosition/collateralToken/isStable'] &&
        this.marketsList.find(
          (item) =>
            item.name.toLowerCase() ===
            `${this.tokenIdCurrency?.toLowerCase()}/usd`
        );
      const priceMarket = findMarket?.usdPrice || 1;
      console.log('REFILL LOG market token id find price', priceMarket);
      return this.marginFiorinValue / priceMarket;
    },
    marginBsv() {
      return toCurrencyFormat(this.marginBsvValue);
    },
    msg() {
      let msg =
        paymentUID() +
        ',m' +
        this.marketId +
        ',t' +
        (this.type === PositionSides.BUY ? 'b' : 's') +
        ',a' +
        (this.formData.amountBtc && this.formData.amountBtc.replace(/ /g, ''));

      if (this.formData.entryPrice) {
        msg += ',e' + this.formData.entryPrice.replace(/ /g, '');
      }
      if (this.formData.stopLoss && +this.formData.stopLoss >= 0) {
        msg += ',s' + this.formData.stopLoss.replace(/ /g, '');
      }
      if (this.formData.takeProfit) {
        msg += ',p' + this.formData.takeProfit.replace(/ /g, '');
      }
      return msg;
    },
    transactionKey() {
      return new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(this.msg),
        Buffer.from('text'),
        Buffer.from('B'),
      ]);
    },
  },
  created() {
    this.marketId = this.activeMarketId;
  },

  methods: {
    async openBountyPosition() {
      if (this.bountyTxLoading) {
        return;
      }
      if (!this.paymail) {
        notify({ text: 'Paymail not found', type: 'info' });
        return;
      }

      this.bountyTxLoading = true;
      const msg = `${this.msg},d${this.paymail}`;
      const txMsg = new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(msg),
        Buffer.from('text'),
        Buffer.from('B'),
      ]).toHex();

      const result = await proposeBountyPosition(
        txMsg,
        this.marginFiorinValue
      ).catch((err) => {
        notify({ text: getErrorDetails(err).message, type: 'info' });
      });

      notify({
        text: this.$t('order.new.toasts.opening'),
        type: 'info',
        group: 'opened',
      });

      if (this.$isFiorinMode) {
        this.bountyTxLoading = false;
        this.onSuccess();
        this.emit('payment');
        return;
      }
      if (result.txId) {
        await this.onPayment({
          txid: result.txId,
          msg,
          isBounty: true,
        });
        this.bountyTxLoading = false;
      }
      if (result.error) {
        this.bountyTxLoading = false;
        notify({
          text: result.error.message || 'Bounty wallet: unknown pay error',
          type: 'info',
        });
      }
    },
    async openFiorinPosition() {
      if (!this.msg) {
        notify({
          text: 'Message error. Try again later.',
          type: 'info',
        });
        return;
      }
      const msg = `${envConfig.txPrefix} ${this.msg} text B`;

      if (this.walletTxLoading) {
        return;
      }

      this.walletTxLoading = true;

      console.log(
        'REFILL LOG',
        this.tokenIdCurrency,
        this.tokenId,
        this.marginFiorinValue,
        this.marginFromTokenId
      );

      try {
        const txId = await store.dispatch(
          'fiorin/refill',
          {
            tokenId: this.tokenId || '',
            amount: this.marginFromTokenId + '',
            message: msg,
            type: 'ACCOUNT',
          }
          // payload: { amount: +this.marginBsv, message: msg, type: 'ACCOUNT' },
        );

        if (txId) {
          await this.onPayment({ txid: txId, isFiorin: true });
          this.walletTxLoading = false;
        } else {
          notify({
            text: 'Wallet error',
            type: 'info',
          });
        }
      } catch (e) {
        notify({
          text: `Wallet error: ${e.message ?? e}`,
          type: 'info',
        });
      }
    },

    async openHandCashPosition() {
      if (!this.msg) {
        notify({
          text: 'Message error. Try again later.',
          type: 'info',
        });
        return;
      }
      if (this.walletTxLoading) {
        return;
      }
      this.walletTxLoading = true;

      const msg = `${envConfig.txPrefix} ${this.msg} text B`
        .split(' ')
        .map((item) => Buffer.from(item).toString('hex'));

      const form = this.formData;

      console.log('margin final save', +this.marginValueCustom);
      console.log('margin final actual', +this.marginBsvValue);

      const result = await store.dispatch('handcash/sendHandCashTx', {
        msg,
        to: this.to,
        amount: +this.marginValueCustom,
        description: trim(
          `${toSentenceCase(this.type)} $${form.amountBtc} of ${
            this.activeMarket.name
          }`,
          25
        ), // HC limit - 25 characters
      });

      if (!result) {
        console.error('Got null after sendHandCashTx');
      }

      if (result.transactionId) {
        await this.onPayment({
          txId: result.transactionId,
        });
        this.walletTxLoading = false;
      }

      if (result.error) {
        this.walletTxLoading = false;
        notify({
          text: result.error.message || 'Wallet error',
          type: 'info',
        });
      }
    },
    async onPayment(data) {
      try {
        const payload = {
          bsvPrice: bsvPrice(),
          tokenId: this.tokenId || '',
          TransactionId: data.txid || data.txId,
          TransactionMessage: data.msg || this.msg,
          TransactionAmount: data.isFiorin
            ? this.marginFromTokenId
            : this.marginValueCustom, // this.marginBsvValue,
          IsBounty: data.isBounty || false,
        };

        console.debug('calling #onPayment, payload:', payload);
        await connApp.invoke('ProposeUserPosition', payload);
      } catch (error) {
        console.error('onPayment error', JSON.stringify(error));
        this.emit('error');
        // notify({
        //   text: `Check pay parameters. ${e}`,
        //   type: 'info',
        // });
        return;
      }

      notify({
        text: this.$t('order.new.toasts.opening'),
        type: 'info',
        group: 'opened',
      });

      this.onSuccess();
      this.emit('payment');
    },

    onError() {
      this.emit('error');
    },
  },
});
</script>
