import {
  getUserBalance as dwGetUserBalance,
  refreshToken as dwRefreshToken,
} from './dotWallet';
import store from '@/store';
import { connApp } from '@/helpers/signalR';

// @ts-ignore
import { WalletNames } from '@/wallets/common';

export const userProviders = WalletNames;

export const refreshTokenUpfrontMs = 5 * 60000;

// todo: [Vadim] move out of here
export async function refreshToken(provider) {
  switch (provider) {
    case userProviders.dotWallet:
      await dwRefreshToken();
      break;
  }
}

// todo: [Vadim] move out of here
export async function getUserBalance() {
  const provider = store.getters['user/provider'];
  switch (provider) {
    case userProviders.moneyButton:
      // mbGetUserBalance();
      break;
    case userProviders.relayX:
      // rxGetUserBalance();
      break;
    case userProviders.dotWallet:
      dwGetUserBalance();
      break;
    case userProviders.handCash:
      // hcGetUserBalance()
      break;
    default:
      break;
  }
}

export function useLogout() {
  return {
    logout: () => {
      store.dispatch('auth/logout');
      console.log(8);
    },
  };
}

export async function updateConfig() {
  let config = await connApp.invoke('GetAddresses', 'BSV');
  console.log('config', config);
  store.commit('user/SET_WS_CONFIG', config);
}
