import { useStore } from 'vuex';

export default function useChangeFilter() {
  const store = useStore();

  const fetchWalletBalance = async () => {
    let isLoggedIn = store.getters['auth/isLoggedIn'];
    if (isLoggedIn) {
      store.dispatch('wallet/fetchBalance', null, { root: true });
      store.dispatch('wallet/fetchBountyBalance', null, { root: true });
    }
  };

  return { fetchWalletBalance };
}
