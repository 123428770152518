import { refreshToken as dwRefreshToken } from './dotWallet';

// @ts-ignore
import { WalletNames } from '@/wallets/common';

export const refreshTokenUpfrontMs = 5 * 60000;

// todo: [Vadim] move out of here
export async function refreshToken(provider) {
  switch (provider) {
    case WalletNames.dotWallet:
      await dwRefreshToken();
      break;
  }
}
