import store from '@/store';
import FiorinConnector from '@/wallets/Fiorin';
import HandcashConnector from '@/wallets/Handcash';
import { WalletNames, WalletNamesList } from '@/wallets/common';
import router from '@/router';
import MoneybuttonConnector from '@/wallets/Moneybutton';
import { waitUntil } from '@/helpers/retry';

const getInitialState = () => ({});

const getters = {
  isLoggedIn: (state, getters, rootState, rootGetters) => {
    const activeConnect = rootGetters['connectors/activeConnect'];
    const isLoggedIn = activeConnect?.provider && activeConnect?.accessToken;
    return isLoggedIn;
  },
};

const mutations = {};

let actions = {
  async initAuth({ dispatch }) {
    const activeConnect = store.getters['connectors/activeConnect'];
    // alert('activeConnect.provider: ' + activeConnect.provider);

    if (activeConnect.provider === 'Fiorin') {
      const fiorin = new FiorinConnector({ stopRedirect: true });
      // console.debug('#initConnectors #useInitialize, fiorin:', fiorin);
      await fiorin.connect();

      try {
        await fiorin.waitForAuthStatus(20_000);
        return fiorin;
      } catch (e) {
        console.debugError(
          'Error when waitForAuthStatus for #initAuth #Fiorin',
          e
        );

        // need to clear activeConnect to let log out
        await dispatch('connectors/initActiveConnect', null, { root: true });
        // todo [Vadim]: it fixes the disabled scrolling after the splash screen. Need to research the reason
        document.body.style.overflow = '';

        return null;
      }
    }

    if (activeConnect.provider === 'HandCash') {
      let accessToken = activeConnect.accessToken;

      if (!accessToken) {
        let params = new URLSearchParams(window.location.search);
        accessToken = params.get('authToken');

        if (accessToken === 'undefined') {
          accessToken = null;
        }
      }

      if (accessToken) {
        try {
          const handCashWallet = new HandcashConnector({
            accessToken: accessToken,
          });

          await Promise.all([
            handCashWallet.fetchProfile(),
            handCashWallet.fetchBalance(),
          ]);

          await store.dispatch('connectors/onAuthenticated', handCashWallet, {
            root: true,
          });

          return handCashWallet;
        } catch (e) {
          console.error('Error initializing HandCash', e);

          await dispatch('connectors/initActiveConnect', null, { root: true });

          return null;
        }
      } else {
        //alert('HandCash: no activeConnect.accessToken');
        console.debug('HandCash: no activeConnect.accessToken #auth #initAuth');
        await dispatch('connectors/initActiveConnect', null, { root: true });
      }
    }

    if (
      activeConnect.provider === 'DotWallet' &&
      !localStorage.getItem('showDWUsers')
    ) {
      // showDWUsers = true;
      // todo: test
      // if (dwAuthCode) {
      //   const dotwalletWallet = new DotwalletConnector({ code: dwAuthCode });
      //   store.dispatch('connectors/setActiveConnect', dotwalletWallet);
      // }

      // const dotwalletWallet = new DotwalletConnector(activeConnect);
      // await store.dispatch('connectors/setActiveConnect', dotwalletWallet);
      // dotwalletWallet.connect()

      if (activeConnect.authorizeCode) {
        await activeConnect.authorizeCode(activeConnect.code);
      }
    }

    if (activeConnect.accessToken && activeConnect.provider === 'MoneyButton') {
      const moneybuttonWallet = new MoneybuttonConnector({
        accessToken: activeConnect.accessToken,
        refreshToken: activeConnect.refreshToken,
        code: activeConnect.code,
      });

      return moneybuttonWallet;
    }
  },

  async startAuth({ dispatch }, { provider, data }) {
    console.debug('#auth  startAuth', provider, data);

    await switchAuth(provider);
    let connector = getConnector(provider);
    await dispatch('connectors/initActiveConnect', provider, {
      root: true,
    });
    await connector.connect(data);
  },

  async switchAccount({ dispatch, rootGetters }, login) {
    console.debug('#switchAccount #auth login:', login);

    function getProviderName(login) {
      const lv = login.toLowerCase();

      if (lv.includes('@hc') || lv.includes('@handcash')) {
        return WalletNames.handCash;
      }

      if (lv.includes('@mb')) {
        return WalletNames.moneyButton;
      }

      if (lv.includes('@rx')) {
        return WalletNames.relayX;
      }

      if (lv.includes('@dw')) {
        return WalletNames.dotWallet;
      }

      if (lv.includes('@fiorin') || lv.includes('@dxs')) {
        return WalletNames.fiorin;
      }
    }

    let providerName = getProviderName(login);
    if (!providerName) {
      console.debugError(
        'Provider not found #switchAccount #auth, value: ',
        login
      );
      return;
    }

    const foundAccount = rootGetters['connectors/connects'].find(
      (acc) => acc.provider === providerName
    );

    if (foundAccount) {
      // it will be used later:
      // await dispatch('connectors/onAuthenticated', foundAccount, {
      //   root: true,
      // });

      // [Vadim]: without it, the iframe from previous account will fire events
      // /that affect active connection show wrong data

      // [Vadim] temporary solution, will use onAuthenticated later
      await dispatch('connectors/setActiveConnectUnsafe', foundAccount, {
        root: true,
      });

      window.location.reload();
    } else {
      console.debugError(
        'Account not found #switchAccount #auth, value: ',
        login
      );
    }
  },

  async logout({ dispatch, rootGetters }) {
    const activeConnect = rootGetters['connectors/activeConnect'];
    if (activeConnect.provider === 'Fiorin') {
      if (frames.fiorin) {
        // [Vadim] I commented this line because it's not clear why it's needed
        // dispatch('session/updateInfo', 'USD', { root: true });

        const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;
        frames.fiorin.postMessage({ event: 'LOGOUT' }, FIORIN_HOST);

        let fiorinLoggedOut = await waitUntil(
          '#auth #logout wait fiorin logout',
          5000,
          100,
          () => !frames.fiorin
        );

        if (!fiorinLoggedOut) {
          console.debugError('Fiorin did not logout #auth #logout');
          await dispatch('connectors/onLogout', null, { root: true });
        }
      } else {
        await dispatch('connectors/onLogout', null, { root: true });
      }
    } else {
      await dispatch('connectors/onLogout', null, { root: true });
    }
  },

  async onAuthenticated(_, providerName) {
    console.debug('#onAuthenticated #auth #store');

    if (providerName !== WalletNames.fiorin) {
      closeSplash();
    }

    closeLoginModal();
  },

  async onLogout() {
    console.debug('#onLogout #auth #store');

    await customRedirect();
    window.location.reload();
  },
};

function switchAuth(provider) {
  // todo: [Vadim] move to the user store?
  if (
    WalletNamesList.includes(provider) &&
    provider !== store.getters['user/provider']
  ) {
    store.commit('user/SET_PROVIDER', provider);
    store.commit('user/SET_SATOSHI_BALANCE', 0);
  }
}

const customRedirect = async () => {
  console.debug('#customRedirect #connectors');
  const href = router.currentRoute.value?.href || '/';

  if (['index?code', 'authToken'].some((part) => href.includes(part))) {
    await router.push('/');
  } else {
    await router.push(href);
  }
};

function closeLoginModal() {
  // [Vadim] not sure why, it's works without it (at least for fiorin)
  window.document.dispatchEvent(
    new CustomEvent('closeLogin', { bubbles: true })
  );
}

function closeSplash() {
  const el = document.querySelector('.static-splash');
  if (el) {
    console.debug('removing static-splash in #refreshUserData');
    el.style.display = 'none';
  }

  // recover scrolling after splash screen?
  document.body.style.overflow = '';
}

function getConnector(provider) {
  switch (provider) {
    case WalletNames.fiorin:
      return new FiorinConnector({ stopRedirect: true });

    case WalletNames.handCash:
      return new HandcashConnector({});

    // case WalletNames.dotWallet:
    //   return new DotwalletConnector({});

    // case WalletNames.moneyButton:
    //   return new MoneybuttonConnector({});
    //
    // case WalletNames.relayX:
    //   return new RelayXConnector({});

    default:
      throw new Error('Unsupported provider: ' + provider);
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
