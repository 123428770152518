<template>
  <div
    id="v-step-2"
    :class="{
      standalone:
        wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
      demo: !isLoggedIn,
      grouped: filterBy === '_star',
      isFiorinNullableBalance,
    }"
    class="markets-table"
  >
    <!-- <loader class="markets-table__loader" v-if="loading && !sleepLoading" /> -->
    <TableHeader
      v-if="header"
      :columns="marketsColumns"
      class="markets-table__header"
      @sort="onSortMarketsList"
    />
    <SkeletonMarkets v-if="loading && !sleepLoading" />
    <template v-else>
      <div
        v-if="actualMarkets.nested"
        id="v-step-mobile-2"
        :class="{ mobile: isMobile }"
        class="markets-table__list"
      >
        <MarketGroup
          v-for="(group, groupName) in groupedMarkets"
          :key="groupName"
          :dataset="marketsColumns"
          :group-list="group"
          :group-name="groupName"
        >
          <template v-slot:markets>
            <MarketList
              is-group
              :filter-by="filterBy"
              :actual-markets="{ value: group }"
              :header="false"
            />
          </template>
        </MarketGroup>
      </div>
      <div
        v-else
        id="v-step-mobile-2"
        :class="{ grouped: isGroup }"
        class="markets-table__list"
      >
        <MarketListItem
          v-for="(market, index) in sortList(actualMarkets.value, sortField)"
          :key="index"
          :market="market"
          :dataset="marketsColumns"
          :is-hold="marketHold(market)"
          :is-active="isMarketActive(market)"
          :is-favourite="favourite(market)"
          :sleep-loading="sleepLoading"
          :class="{ 'box-active': isActiveMarket(market) }"
          class="markets-table__list-row"
          @select-market="
            (data) =>
              $tour_goToNextStep(
                $data.$tour_mobile,
                () => onSelectRow(data, market),
                2
              )
          "
        />
      </div>
      <ModalConnectionLost
        v-if="showConnectionLostModal"
        @close="showConnectionLostModal = false"
      />
      <ModalMarketOpenClose
        v-if="showMarketOpenClose"
        :value="workHour"
        @dontShowAgainMarket="dontShowAgainMarket"
        @close="onMarkerOpenClose"
      />
    </template>
  </div>
</template>
<script>
import '@/assets/icons/starfill';
import '@/assets/icons/star';
import { sortList } from '@/helpers/sortList';

import TableHeader from '@/components/ui/TableHeader';
import MarketList from './MarketList';
import MarketListItem from './MarketListItem.vue';
import MarketGroup from './MarketListGroup';

import { ref, computed, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import useMarketCoin from '@/compositions/useMarketCoin';
import { workHours } from '@/helpers/utils';

import ModalConnectionLost from '@/components/modals/ModalConnectionLost';
import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';

import { scope } from '@/breakpoints';
import tourMixin from '@/mixins/tour.mixin';
import { wrapMarket } from '@/config/wrapMarkets';
import { setRouteFromActiveMarket } from '@/helpers/activeMarketRoute';
import { useMarketsTable } from '@/compositions/useMarketsTable';
import SkeletonMarkets from './SkeletonMarkets';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';

export default {
  components: {
    TableHeader,
    MarketGroup,
    MarketList,
    MarketListItem,
    ModalConnectionLost,
    ModalMarketOpenClose,
    SkeletonMarkets,
  },
  mixins: [tourMixin],
  props: {
    isGroup: {
      type: Boolean,
      default: false,
    },
    actualMarkets: {
      type: Object,
      required: true,
    },
    filterBy: {
      type: String,
      required: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isFiorinNullableBalance } = useFiorinBalanceNull();
    const showConnectionLostModal = ref(false);
    const showMarketOpenClose = ref(false);
    const workHour = ref('');

    const sortField = ref(null);
    const store = useStore();

    const { marketsColumns } = useMarketsTable();

    const {
      getClassesOfDynamic,
      getChangeValue24,
      getTickerName,
      marketHold,
      isMarketActive,
      calcMarketUSDPrice,
    } = useMarketCoin();
    const isActiveMarket = (market) =>
      store.getters['markets/activeMarketId'] === market.id;

    const onMarkerOpenClose = () => {
      showMarketOpenClose.value = false;
      workHour.value = '';
    };

    const favourite = computed(() => {
      return (market) =>
        !!store.getters['settings/isFavouriteMarket'](getTickerName(market));
    });

    const groupedMarkets = computed(() => {
      if (!props.actualMarkets.nested) {
        return [];
      }
      const marketGroups = {};
      props.actualMarkets.value.forEach((market) => {
        if (!marketGroups[market.group]) {
          marketGroups[market.group] = [market];
        } else {
          marketGroups[market.group].push(market);
        }
      });
      return marketGroups;
    });

    const authInfo = computed(() => store.getters['user/truedexAuth']);
    const activeMarket = computed(() => store.getters['markets/activeMarket']);
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const sleepLoading = computed(
      () => store.getters['positions/sleepLoading']
    );

    const dontShowAgainMarkets = computed(
      () => store.getters['markets/dontShowAgain']
    );
    // const activeMarketId = computed(() => store.getters['markets/activeMarketId'])

    const onSortMarketsList = (field) => {
      sortField.value = field;
    };

    const onSelectRow = async (data, market) => {
      if (marketHold(market) && isMarketActive(market)) {
        showConnectionLostModal.value = true;

        // for mobile popup
        if (scope.noMatch) {
          localStorage.setItem('mobileShowConnectionLost', true);
        }
      }

      // inactive and not in cache
      if (!isMarketActive(market) && !dontShowAgainMarkets.value) {
        showMarketOpenClose.value = true;
        workHour.value = workHours(new Date(), market);

        // for mobile popup
        if (scope.noMatch) {
          localStorage.setItem('mobileMarketOpenCloseWorkhour', workHour.value);
        }
      }

      if (data.prop === 'favourite') {
        return;
      } // do not switch market on star/fav click

      store.commit('markets/UPDATE_ACTIVE_MARKET', market);
      setRouteFromActiveMarket({ stopDefault: true });

      if (activeMarket.value?.id && authInfo.value?.userId) {
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeInfo',
          activeMarket.value.id
        );
      }
    };

    let timer = null;
    const cacheScrollTop = ref(null);

    const onScroll = () => {
      const element = document.querySelector('.market-tabs-filter');
      if (!element) {
        return;
      }
      if (timer !== null) {
        if (!cacheScrollTop.value) {
          cacheScrollTop.value = document.documentElement.scrollTop;
        }
        clearTimeout(timer);

        if (document.documentElement.scrollTop > cacheScrollTop.value) {
          element.style.opacity = 0.4;
          store.dispatch('localUiSettings/setScrollingListFlag', true);
          element.style.zIndex = 1;
        } else {
          store.dispatch('localUiSettings/setScrollingListFlag', false);
          element.style.opacity = 1;
          element.style.zIndex = 1;
        }
      }
      timer = setTimeout(function () {
        // stop scrolling
        store.dispatch('localUiSettings/setScrollingListFlag', false);
        element.style.opacity = 1;
        element.style.zIndex = 1;
        cacheScrollTop.value = null;
      }, 500);
    };

    onMounted(() => {
      if (window.document.body.clientWidth < 1024) {
        window.addEventListener('scroll', onScroll);
      }
    });

    onBeforeMount(() => {
      window.removeEventListener('scroll', onScroll);
    });

    const dontShowAgainMarket = () => {
      store.dispatch('markets/setDontShowAgainMarket');
      showMarketOpenClose.value = false;
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      isFiorinNullableBalance,
      isLoggedIn,
      onMarkerOpenClose,
      wrapMarket,
      onSelectRow,
      marketHold,
      isMarketActive,
      isActiveMarket,
      marketsColumns,
      getChangeValue24,
      getClassesOfDynamic,
      getTickerName,
      calcMarketUSDPrice,
      favourite,
      store,
      onSortMarketsList,
      sortField,
      sortList,
      showConnectionLostModal,
      showMarketOpenClose,
      groupedMarkets,
      workHour,
      dontShowAgainMarket,
      sleepLoading,
      wd: window,
      isRunningStandaloneAndroid,
      isTelegram,
    };
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

@keyframes pulsate {
  50% {
    opacity: 0.5;
  }
}

.markets-table {
  height: 100%;

  &.mobile {
    overflow: hidden;
  }

  &__loader {
    top: 50%;
    left: 50%;
    margin-left: -18px;
  }

  &__list {
    &.mobile {
      overflow: auto;
      height: calc(100% - 45px);
    }

    @media screen and (max-width: 1024px) {
      overflow-y: hidden;
    }
  }

  &__list-row {
    border-bottom-width: 1px;
  }

  &__field-fav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__field-name {
    font-family: Gotham_Pro_Regular;
    font-size: 0.9375rem;
    display: flex;
    align-items: center;
  }

  &__field-name-ticker {
    width: 105px;
    line-height: 15px;
    padding-top: 3px;
  }

  &__field-status {
    border-radius: 7px;
    width: 7px;
    height: 7px;
    margin-left: 3px;

    @media screen and (min-width: 1024px) {
      margin-left: 0;
    }
  }

  &__field-status-inactive {
    background-color: #ccc;
  }

  &__field-change {
    position: relative;
    font-family: Cantarell_Bold;
    font-size: 0.9375rem;

    &.inSleep {
      opacity: 0.1;
      -webkit-animation: pulsate 1s linear infinite;
      animation: pulsate 1s linear infinite;
    }
  }

  &__field-price {
    position: relative;
    font-family: Cantarell_Regular;
    font-size: 0.9375rem;

    &.inSleep {
      opacity: 0.1;
      -webkit-animation: pulsate 1s linear infinite;
      animation: pulsate 1s linear infinite;
    }
  }

  &__field-change,
  &__field-price {
    transition: background-color 0.3s ease-in-out;

    &.decrease {
      background-color: #de5e5638;
    }

    &.increase {
      background-color: #509f9642;
    }
  }
}

@media screen and (max-width: 1024px) {
  .markets > .markets-table {
    height: unset;
    padding-bottom: 134px;
    overflow: initial;

    &.isFiorinNullableBalance {
      padding-bottom: 194px;

      &.standalone {
        padding-bottom: 212px;
      }
    }

    &.demo {
      padding-bottom: 214px; // for block demo bottom
    }

    &.standalone {
      padding-bottom: 162px;

      &.demo {
        padding-bottom: 242px; // for block demo bottom
      }

      &.grouped {
        padding-bottom: 182px;

        &.demo {
          padding-bottom: 254px; // for block demo bottom
        }
      }
    }
  }
}

// @media screen and (max-width: 480px) {
//   .markets-table__list {
//     height: 100%;
//     margin-bottom: 85px;

//     &.grouped {
//       margin-bottom: 0;
//     }
//     // max-height: calc(100vh - 305px);
//     // overflow-y: auto;
//   }
// }

// @media screen and (max-width: 418px) {
//   .markets-table__list {
//     // margin-bottom: 80px;
//     height: 100%;
//     margin-bottom: 115px;

//     // max-height: calc(100vh - 340px);
//     // overflow-y: auto;
//   }
// }
</style>
