<template>
  <Modal @close="onClose" stop-mobile-max-height>
    <Card
      :title="$t('popups.liquidityFundraiser.confirmTitle').toUpperCase()"
      class="card"
    >
      <div class="confirm-transfer">
        <div class="confirm-transfer__content">
          {{
            $t('popups.liquidityFundraiser.confirmText', {
              user: info.address,
              amount: toCurrencyFormat(amount),
            })
          }}
        </div>
      </div>
    </Card>
    <div class="confirm-transfer__btns">
      <template v-if="!showPayBtn">
        <Button type="secondary" width="full" text="CANCEL" @click="onClose" />
        <Button
          :loading="confirmLoading"
          type="secondary"
          width="full"
          text="CONFIRM"
          @click="onConfirm"
        />
      </template>
      <template v-else>
        <HandCash
          v-if="$isHandCash"
          :loading="loading"
          :margin-btn-value="amountBsv"
          custom-label="SIGN TRANSFER"
          :margin="amountBsv"
          @onClick="openHandCashPosition"
        />
        <div v-if="$isDotWallet" style="width: 210px; margin: 0 0 20px 10px">
          <DotwalletButton
            :address="config.liquidity_pool_account"
            :amount="amountBsv"
            :opreturn="transactionKey.toHex()"
            product-name="Transfer"
            @error="onError"
            @payment="onPayment"
          />
        </div>
      </template>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { toCurrencyFormat } from '@/helpers/utils';

import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';

import HandCash from '@/components/HandCashButton';
import DotwalletButton from '@/components/DotwalletButton/DotwalletButton';
import envConfig from '@/config';
import { paymentUID } from '@/helpers/utils';
import notify from '@/plugins/notify';
import { NullDataBuilder } from 'dxs-stas-sdk/dist/script/build/null-data-builder';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default defineComponent({
  mixins: [authMixin],
  components: {
    Button,
    Modal,
    Card,
    HandCash,
    DotwalletButton,
  },
  emits: ['confirmTransfer', 'close'],
  props: {
    info: {
      required: true,
    },
    amount: {
      required: true,
    },
    confirmLoading: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const auth = useAuth();
    const store = useStore();
    const showPayBtn = ref(false);
    const loading = ref(false);
    const amountBsv = ref(0.0000025);

    if (auth.isDotWallet.value) {
      //todo: [Vadim] magic number?
      amountBsv.value = 0.0000546;
    }

    const config = computed(() => store.getters['user/wsConfig']);

    const msg = computed(() => {
      return `${paymentUID()} ${props.info.address}`;
    });

    const transactionKey = computed(() => {
      return new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(msg.value),
        Buffer.from('text'),
        Buffer.from('T'),
      ]);
    });

    const onClose = () => {
      emit('close');
    };

    const onConfirm = () => {
      showPayBtn.value = true;
    };

    const onPayment = () => {
      emit('confirmTransfer');
    };

    const onError = (data) => {
      notify({
        text: data.error || 'Moneybutton: unknown pay error',
        type: 'info',
      });
    };

    const openHandCashPosition = async () => {
      if (loading.value) {
        return;
      }

      loading.value = true;

      const msgHC = `${envConfig.txPrefix} ${msg.value} text T`
        .split(' ')
        .map((item) => Buffer.from(item).toString('hex'));

      const result = await store.dispatch('handcash/sendHandCashTx', {
        msg: msgHC,
        to: config.value.liquidity_pool_account,
        amount: amountBsv.value,
        description: ``,
      });

      if (result && result.transactionId) {
        loading.value = false;
        emit('confirmTransfer');
      }

      if (result && result.error) {
        loading.value = false;
        notify({
          text: result.error.message || 'Handcash: unknown pay error',
          type: 'info',
        });
      }
    };

    return {
      showPayBtn,
      loading,
      config,
      transactionKey,
      amountBsv,
      onClose,
      onConfirm,
      toCurrencyFormat,
      openHandCashPosition,
      onPayment,
      onError,
    };
  },
});
</script>

<style lang="scss">
.confirm-transfer {
  width: 100%;
  position: relative;
  min-height: 130px;
  overflow-y: auto;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;

  &__content {
    margin: 10px 0;
    line-height: 1.2;
    position: relative;
    text-align: left;
    font-family: Gotham_Pro_Regular;
  }

  &__btns {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
  }
}
</style>
