import Connector from './Connector';
import store from '@/store';
import notify from '@/plugins/notify';
import { waitUntil } from '@/helpers/retry';

const FIORIN_HOST = process.env.VUE_APP_FIORIN_HOST;

let alreadyConnected = false;

export default class FiorinConnector extends Connector {
  constructor(opts) {
    console.debug('#FiorinConnector #constructor');

    super(opts);
    this.stopRedirect = opts.stopRedirect;
    this.provider = 'Fiorin';
    this.accessToken = opts.accessToken;
    this.satoshiBalance = 0;
    this.fiorinUserName = '';
    this.paymailAddress = '';
    this.serverInfo = {};
    this.isOtherWeb3 = '';
    this.pendingUsd = 0;
    this.bountyBalance = 0;
    this.balancesLog = [];
  }

  async fetchBalance() {}

  async fetchProfile() {}

  async sendFiorinTx() {}

  async connect({ isMetamask, isTrustWallet, isWalletConnect, isSignup } = {}) {
    console.debug(
      `#connect #FiorinConnector 
      isMetamask: ${isMetamask} 
      isTrustWallet: ${isTrustWallet}
      isWalletConnect: ${isWalletConnect} 
      isSignup: ${isSignup}
      host: ${FIORIN_HOST}`
    );

    if (alreadyConnected) {
      // alert('FiorinConnector already had called "connect" method');
      console.debug(
        '#connect #FiorinConnector already had called "connect" method'
      );
    }

    alreadyConnected = true;
    let loginRequired = false;

    let frame = frames.fiorin;
    if (!frame) {
      frame = loadIframe((frame) => {
        const [, hostUrl] = [
          [isMetamask, `${FIORIN_HOST}/ethereum?wallet=metamask`],
          [isTrustWallet, `${FIORIN_HOST}/ethereum?wallet=trust`],
          [isWalletConnect, `${FIORIN_HOST}/ethereum`],
          [isSignup, `${FIORIN_HOST}/sign-up`],
          [true, FIORIN_HOST],
        ].find(([predicate]) => predicate);

        frame.setAttribute('src', hostUrl); //'https://wqeewqewq')
        frame.setAttribute('name', 'fiorin');
        frame.setAttribute('frameBorder', '0');
        frame.setAttribute('allow', 'clipboard-read; clipboard-write');
        frame.setAttribute('id', 'fiorinFrame');
      });
    } else {
      //why is that?
      console.debug('frame already exists #FiorinConnector #connect');
      // alert(
      //   'frame already exists'
      // );
    }

    const closeFrame = () => {
      setFrameStyles(frame, '0', '0', 'display: none;');
    };
    closeFrame();

    const showFrame = () => {
      console.debug(`#showFrame #FiorinConnector`);
      setFrameStyles(
        frame,
        '100%',
        '100%',
        'position: fixed; bottom: 0px; left: 0; z-index: 100100;'
      );
    };

    function removeFiorinFrame() {
      const frame = document.getElementById('fiorinFrame');
      if (frame) {
        window.removeEventListener('message', onMessageHandler);
        frame.parentNode.removeChild(frame);
        return true;
      }

      return false;
    }

    async function restoreIframeLocalStorageAndAuthenticate() {
      const packet = localStorage.getItem('_fp');
      if (packet) {
        console.debug('Sending packet to #FiorinConnector packet');
        const result = await sendMessageToFiorinAsync('STORE', { packet });
        const authenticated = result.authenticated;
        if (typeof authenticated !== 'boolean') {
          console.error(
            'Invalid response from Fiorin: authenticated is not bool. #FiorinConnector #restoreIframeLocalStorageAndAuthenticate',
            result
          );
          return false;
        }
        return authenticated;
      }
    }

    let lastRequestId = Math.floor(Math.random() * 1000);

    const waitingForEvents = {};

    const sendMessageToFiorinAsync = (eventName, payload) => {
      if (!eventName) {
        throw new Error('eventName is required');
      }

      const requestId = ++lastRequestId;

      let promise = new Promise((resolve, reject) => {
        waitingForEvents[requestId] = { resolve, reject, eventName };
        let data = { requestId, event: eventName, payload };
        console.debug(`#sendMessageToFiorin #FiorinConnector data:`, data);
        frames.fiorin.postMessage(data, FIORIN_HOST);
      });

      setTimeout(() => {
        let obj = waitingForEvents[requestId];
        if (obj) {
          console.debugError(
            `unresolved event promise: #sendMessageToFiorinAndWait #FiorinConnector #timeout eventName: `,
            obj
          );
          obj.reject('timeout');
          delete waitingForEvents[requestId];
        } else {
          console.debug(
            `from timout check: resolved event promise: #sendMessageToFiorinAndWait #FiorinConnector #timeout eventName: ${requestId}`
          );
        }
      }, 60_000);

      return promise;
    };

    const onEventReceived = (eventObj) => {
      if (!eventObj) {
        console.debug('Empty eventPayload, #onEventReceived #FiorinConnector');
        return;
      }

      const requestId = eventObj.requestId;
      if (!requestId) {
        return;
      }

      const obj = waitingForEvents[requestId];
      if (obj) {
        // console.debug(`onEventReceived found for requestId: ${requestId}, #FiorinConnector promiseData:`, obj);
        obj.resolve(eventObj.payload);
        delete waitingForEvents[requestId];
      } else {
        console.debugError(
          `#FiorinConnector #onEventReceived, requestId is not awaited or already resolved, requestId:`,
          requestId
        );
      }
    };

    let anyMessageReceived = false;

    const onMessageHandler = async (event) => {
      if (event.origin !== FIORIN_HOST) {
        return;
      }

      console.debug(
        '#EVENT #onMessageHandler #FiorinConnector',
        'data.event:',
        event.data.event,
        'event.data:',
        event.data,
        'event:',
        event
      );

      anyMessageReceived = true;

      if (event.data.event === 'READY') {
        // nothing to do
      }

      if (event.data.event === 'AUTH_REQUIRED') {
        if (!(await restoreIframeLocalStorageAndAuthenticate())) {
          this.notifyHasAuthResult(false);
          showFrame();
        }
      }

      if (event.data.event === 'OPEN_ME') {
        showFrame();
      }

      if (event.data.event === 'LOGIN_REQUIRED') {
        if (!(await restoreIframeLocalStorageAndAuthenticate())) {
          loginRequired = true;
          this.notifyHasAuthResult(false);
          showFrame();
        }
      }

      if (event.data.event === 'ADDRESS') {
        this.paymailAddress = event.data.payload.address;
        localStorage.setItem(
          'fiorinPaymail',
          `${+new Date()} ${JSON.stringify(event.data)}`
        );
      }

      if (event.data.event === 'AUTHORIZED') {
        loginRequired = false;

        closeFrame();

        this.balancesLog.push(`|${+new Date()}|`);
        this.fiorinUserName = event.data.payload.email;
        this.accessToken = event.data.payload.token;
        this.isOtherWeb3 = event.data.payload.provider;

        await store.dispatch('connectors/onAuthenticated', { ...this });
        this.notifyHasAuthResult(true);

        await sendMessageToFiorinAsync('SUBSCRIBE_BALANCE_CHANGES', null);
        await sendMessageToFiorinAsync('REFRESH_BALANCES', null);
        await sendMessageToFiorinAsync('GET_PENDING_BALANCE', null);
      }

      if (event.data.event === 'LOGOUT_COMPLETED') {
        const hadIframe = removeFiorinFrame();
        if (hadIframe) {
          await store.dispatch('connectors/onLogout');
        }

        this.notifyHasAuthResult(false);
      }

      if (['DECLINED', 'CLOSED'].includes(event.data.event)) {
        closeFrame();

        if (loginRequired) {
          removeFiorinFrame();
          this.notifyHasAuthResult(false);
        }
      }

      if (event.data.event === 'ERROR') {
        notify({
          text: event.data.payload.message || event.data.payload.error,
          type: 'info',
        });
      }

      // USER EVENT TRIGER
      if (event.data.event === 'REFILL_COMPLETED') {
        await store.dispatch('fiorin/setFiorinLastTx', {
          ...event.data.payload.txs.reverse()[0],
        });
      }

      if (event.data.event === 'TOKEN') {
        this.accessToken = event.data.payload.token;
        this.pendingUsd = event.data.payload.pendingBalance;
        await store.dispatch('connectors/setActiveConnectionInfo', { ...this });
        // this.satoshiBalanceUsd = '0';
      }

      if (event.data.event === 'WITHDRAWAL') {
        notify({
          text: 'Sending funds...',
          type: 'info',
        });
      }

      if (event.data.event === 'DEPOSIT') {
        notify({
          text: 'Receiving funds...',
          type: 'info',
        });
      }

      if (event.data.event === 'PENDING_BALANCE') {
        this.pendingUsd = event.data.payload.pendingBalance;
        await store.dispatch('connectors/setActiveConnectionInfo', { ...this });
      }

      if (event.data.event === 'BOUNTY_BALANCE') {
        this.balancesLog.push(`b${event.data.payload.amount}`);
        this.bountyBalance = event.data.payload.amount;

        await store.dispatch('connectors/setActiveConnectionInfo', { ...this });
        await store.dispatch('bounty/setBountyBalance', this.bountyBalance);
      }

      if (event.data.event === 'ACTIVE_TOKENS') {
        this.activeTokens = event.data.payload.tokens;

        const totalAmountUsd = event.data.payload.tokens.reduce(
          (prev, item) => {
            return prev + item.amountUsd;
          },
          0
        );
        console.log('totalAmountUsd', totalAmountUsd);

        this.totalAssetsUsd = totalAmountUsd;
        this.activeTokens = event.data.payload.tokens;

        await store.dispatch('connectors/setActiveConnectionInfo', {
          ...this,
        });
      }

      if (event.data.event === 'BALANCE') {
        this.balancesLog.push(`a${event.data.payload.amount}`);
        const amount =
          event.data.payload.amount == event.data.payload.amountUsd
            ? event.data.payload.amount
            : event.data.payload.amountUsd;
        this.satoshiBalanceUsd = amount;
        console.log('amount available', amount);
        this.tokenId = event.data.payload.tokenId;
        this.tokenIdCurrency = event.data.payload.currency;
        this.tokenIdName = event.data.payload.name;

        await store.dispatch('connectors/setActiveConnectionInfo', { ...this });

        const el = document.querySelector('.static-splash');
        if (el) {
          setTimeout(() => {
            el.style.display = 'none';
            document.body.style.overflow = '';
          }, 1500);
        }

        if (localStorage.getItem('needAuthReload')) {
          localStorage.removeItem('needAuthReload');
          // todo: [Vadim]  check how it works without reload
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }

        if (!this.stopRedirect) {
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        }
      }

      if (event.data.event === 'STORE') {
        localStorage.setItem('_fp', event.data.payload);
      }

      if (event.data.event === 'OPEN_LINK') {
        window.open(
          event.data.payload.url,
          event.data.payload.target,
          event.data.payload.features
        );
      }

      onEventReceived(event.data);
    };

    window.addEventListener('message', onMessageHandler);

    let fiorinStartedWorking = await waitUntil(
      '#fiorin #connector #connect',
      20_000,
      100,
      () => anyMessageReceived
    );

    if (!fiorinStartedWorking) {
      console.error('Fiorin did not start working #FiorinConnector #connect');

      await store.dispatch('connectors/initActiveConnect', null, {
        root: true,
      });
    }

    console.debug('exit #connect #FiorinConnector, frame:', frame);
    return this;
  }
}

function loadIframe(init, timeoutDuration = 10_000) {
  const iframe = document.createElement('iframe');
  init(iframe);

  // Create a timeout to handle loading failures
  const timeout = setTimeout(() => {
    console.error(
      'Iframe failed to load within the time limit. #DXS #loadIframe'
    );
  }, timeoutDuration);

  iframe.onload = () => {
    clearTimeout(timeout);
  };

  // Handle iframe error event (though it may not always fire)
  iframe.onerror = (error) => {
    clearTimeout(timeout);
    console.error(
      'Iframe failed to load (onerror event). #DXS #loadIframe, error:',
      error
    );
  };

  document.body.appendChild(iframe);
  return iframe;
}

const setFrameStyles = (frame, width, height, style) => {
  frame.setAttribute('width', width);
  frame.setAttribute('height', height);

  if (style || style === '') {
    frame.setAttribute('style', style);
  }
};
