/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'useracc': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.96c.486 0 3.554-.222 3.554-3.793C11.054 2.072 9.463.374 7.5.374c-1.963 0-3.554 1.698-3.554 3.793 0 3.571 3.068 3.792 3.554 3.792zm5.322 3.118c.885.721 1.594 1.677 2.045 2.888-.403.47-.874.878-1.398 1.211-.328-1.271-.96-2.162-1.72-2.782-1.152-.937-2.706-1.337-4.25-1.337-1.543 0-3.097.4-4.249 1.337-.76.62-1.391 1.51-1.72 2.782a6.298 6.298 0 01-1.397-1.212c.45-1.21 1.16-2.166 2.045-2.887C3.72 9.822 5.697 9.36 7.5 9.36c1.802 0 3.779.463 5.322 1.72z" _fill="#fff"/>'
  }
})
