export default class Connector {
  constructor() {}

  connectLabelUserId() {
    if (!this.serverInfo) {
      return '';
    }
    return `${this.serverInfo.providerId.toUpperCase()}@${this.serverInfo.provider.toUpperCase()}`;
  }

  static staticMethod() {
    return 'Static';
  }

  _hasAuthStatus = false;
  _userIsAuthenticated = false;
  _authPromiseRequired = false;
  _resolveWaitForAuth = null;
  _rejectWaitForAuth = null;

  notifyHasAuthResult(userIsAuthenticated) {
    if (typeof userIsAuthenticated !== 'boolean') {
      throw new Error('Invalid auth status');
    }

    this._userIsAuthenticated = userIsAuthenticated;
    this._hasAuthStatus = true;

    if (this._authPromiseRequired) {
      this._resolveWaitForAuth(userIsAuthenticated);
      this._clearPromiseHandlers();
    }

    this._authPromiseRequired = false;
  }

  _clearPromiseHandlers() {
    this._resolveWaitForAuth = null;
    this._rejectWaitForAuth = null;
  }
  async waitForAuthStatus(timeout = 15_000) {
    const promise = new Promise((resolve, reject) => {
      if (this._hasAuthStatus) {
        resolve(this._userIsAuthenticated);
        return;
      }

      this._authPromiseRequired = true;

      this._resolveWaitForAuth = resolve;
      this._rejectWaitForAuth = reject;
    });

    setTimeout(() => {
      if (this._authPromiseRequired) {
        this._rejectWaitForAuth('timeout');
        this._authPromiseRequired = false;
        this._clearPromiseHandlers();
      }
    }, timeout);

    return promise;
  }
}
