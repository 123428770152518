<template>
  <div class="markets-table__field-fav" @click.stop="toggleFav">
    <icon
      v-if="isFavourite"
      name="starfill"
      width="24"
      height="24"
      :strokeColor="'none'"
      :fill-color="'#ccc'"
    />
    <icon
      v-else
      name="star"
      width="24"
      height="24"
      :strokeColor="'none'"
      :fillColor="'rgba(167, 169, 172, 1)'"
    />
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import useMarketCoin from '@/compositions/useMarketCoin';
import notify from '@/plugins/notify';

export default {
  props: {
    activeMarket: {
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { getTickerName } = useMarketCoin();

    const toggleFav = () => {
      if (!isFavourite.value) {
        notify({
          text: 'Added to watchlist',
          type: 'info',
        });
      }

      store.dispatch(
        'settings/toggleFavouriteMarket',
        props.activeMarket?.name
      );
    };

    const isFavourite = computed(
      () =>
        !!store.getters['settings/isFavouriteMarket'](
          getTickerName(props.activeMarket)
        )
    );

    return {
      toggleFav,
      isFavourite,
    };
  },
};
</script>
<style lang="scss" scoped>
.markets-table__field-fav {
  opacity: 0.7;
}
</style>
