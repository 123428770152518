const types = {
  SET_ACTIVE_CONNECT: 'SET_ACTIVE_CONNECT',
  SET_USER_NAME: 'SET_USER_NAME',
  ADD_NEW_CONNECT: 'ADD_NEW_CONNECT',
  UPDATE_CONNECTS: 'UPDATE_CONNECTS',
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  UPDATE_CONNECTION_STATUS: 'UPDATE_CONNECTION_STATUS',
  SET_AUTH_LOADER: 'SET_AUTH_LOADER',
  SET_RECONNECT_FLAG: 'SET_RECONNECT_FLAG',
};

const getInitialState = () => ({
  walletLoading: true,
  connectionStatus: true,
  //todo: delete
  userName: '',
  authLoader: false,
  isReconnect: false,
});

const getters = {
  //todo: [Vadim] move out these flags to appropriate modules
  isReconnect: (state) => state.isReconnect,
  // todo: [Vadim] check if it is used and make sure it's updated correctly on connApp disconnect, and make sure it's for this purpose
  authLoader: (state) => state.authLoader,
  connectStatus: (state) => state.connectionStatus,
  getWalletLoadingStatus: (state) => state.walletLoading,
  userName: (state) => state.userName,
};

const mutations = {
  [types.SET_RECONNECT_FLAG](state, value) {
    state.isReconnect = value;
  },

  [types.SET_AUTH_LOADER](state, value) {
    state.authLoader = value;
  },

  [types.SET_USER_NAME](state, value) {
    state.userName = value;
  },

  [types.UPDATE_LOADING_STATUS](state, value) {
    state.walletLoading = value;
  },
  [types.UPDATE_CONNECTION_STATUS](state, value) {
    state.connectionStatus = value;
  },
};

let actions = {
  setReconnectFlag({ commit }, value) {
    commit(types.SET_RECONNECT_FLAG, value);
  },

  setAuthLoader({ commit }, value) {
    console.debug(
      'Setting auth loader #connectors #actions #setAuthLoader',
      value
    );
    commit(types.SET_AUTH_LOADER, value);
  },

  setUserName({ commit }, name) {
    commit(types.SET_USER_NAME, name);
  },

  // async onLogout({ commit, dispatch, state }) {
  //   console.debug('#logout #connectors #actions');
  //
  //   const activeConnect = { ...state.activeConnect };
  //
  //   localStorage.removeItem('dxsLoggedIn');
  //   // localStorage.removeItem('_fp');
  //
  //   await dispatch('setUserName', '');
  //   await dispatch('setAuthLoader', false);
  //   await dispatch('burger/onLogout', null, { root: true });
  //   await dispatch('session/onLogout', null, { root: true });
  //   await dispatch('settings/onLogout', null, { root: true });
  //   await dispatch('user/onLogout', null, { root: true });
  //   await dispatch('stopSocket');
  //
  //   commit(types.SET_ACTIVE_CONNECT, {});
  //
  //   await dispatch('auth/onLogout', activeConnect.provider, { root: true });
  // },
};
export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
