import { toCurrencyFormat, getFormatedAmount } from '@/helpers/utils';

export const formatNetProfitLoss = (net_pl, base_currency, hideCurrency) => {
  const sign = net_pl > 0 ? '+' : '';
  const value =
    base_currency === 'USD'
      ? getFormatedAmount(net_pl, true)
      : toCurrencyFormat(net_pl);

  return `${sign}${value} ${hideCurrency ? '' : base_currency}`;
};
