import { connApp } from '@/helpers/signalR';
import { useModals } from '@/modules/modals/api';

const types = {
  SET_LOYALTY_FEE_INFO: 'SET_LOYALTY_FEE_INFO',
  SET_LOYALTY_FEE_RATE: 'SET_LOYALTY_FEE_RATE',

  SET_POSITION_LOYALTY_FEE_VALUE: 'SET_POSITION_LOYALTY_FEE_VALUE',
  SET_POSITION_LOYALTY_FEE_RATE: 'SET_POSITION_LOYALTY_FEE_RATE',

  SET_LOYALTY_POOL_BALANCE: 'SET_LOYALTY_POOL_BALANCE',
  SET_GROUP_ACTIVE_AMOUNT: 'SET_GROUP_ACTIVE_AMOUNT',
};

const getInitialState = () => ({
  loyaltyFeeInfo: {
    profitTier: 0,
    profitTierProgress: 0,
    volumeTier: 0,
    volumeTierProgress: 0,
    loyaltyFeeRate: 0,
  },
  loyaltyFeeRate: 0,
  loyaltyPoolBalance: 0,

  positionLoyaltyFeeValue: 0,
  positionLoyaltyFeeRate: 0,
  groupActiveAmount: 0,
  ...useModals(),
});

const getters = {
  // market
  loyaltyFeeInfo: (state) => state.loyaltyFeeInfo,
  loyaltyFeeRate: (state) => state.loyaltyFeeRate,
  groupActiveAmount: (state) => state.groupActiveAmount,
  // position
  positionLoyaltyFeeValue: (state) => state.positionLoyaltyFeeValue,
  positionLoyaltyFeeRate: (state) => state.positionLoyaltyFeeRate,

  loyaltyPoolBalance: (state) => state.loyaltyPoolBalance,
};

const mutations = {
  // market
  [types.SET_LOYALTY_FEE_INFO](state, value) {
    state.loyaltyFeeInfo = value;
  },
  [types.SET_LOYALTY_FEE_RATE](state, value) {
    state.loyaltyFeeRate = value;
  },
  [types.SET_GROUP_ACTIVE_AMOUNT](state, value) {
    state.groupActiveAmount = value;
  },
  // position
  [types.SET_POSITION_LOYALTY_FEE_VALUE](state, value) {
    state.positionLoyaltyFeeValue = value;
  },
  [types.SET_POSITION_LOYALTY_FEE_RATE](state, value) {
    state.positionLoyaltyFeeRate = value;
  },

  [types.SET_LOYALTY_POOL_BALANCE](state, value) {
    state.loyaltyPoolBalance = value;
  },
};

const actions = {
  // market
  async fetchGroupActiveAmount({ commit, rootGetters }, marketId) {
    const isLoggedIn = rootGetters['auth/isLoggedIn'];
    if (isLoggedIn) {
      const info = await connApp
        .invoke('GetActiveAmountUsd', marketId)
        .catch((err) => {
          console.log(err);
        });

      commit(types.SET_GROUP_ACTIVE_AMOUNT, info || 0);
    }
  },

  //todo: [Vadim] why it's called multiple times while loading?
  // market
  async fetchLoyaltyFeeInfo({ commit, rootGetters }, marketId) {
    const isLoggedIn = rootGetters['auth/isLoggedIn'];
    if (isLoggedIn) {
      const info = await connApp
        .invoke('GetLoyaltyFeeInfo', { marketId })
        .catch((err) => {
          console.log(err);
        });

      if (info) {
        commit(types.SET_LOYALTY_FEE_INFO, info);
      }
    }
  },

  async fetchLoyaltyFeeRate({ commit, rootGetters }, marketId) {
    const isLoggedIn = rootGetters['auth/isLoggedIn'];
    if (isLoggedIn) {
      const rate = await connApp
        .invoke('GetCurrentLoyaltyFeeRate', { marketId })
        .catch((err) => {
          console.log(err);
          // window.location.reload();
        });
      commit(types.SET_LOYALTY_FEE_RATE, rate);
    }
  },

  // position
  async fetchPositionLoyaltyFeeValue({ commit, rootGetters }, positionId) {
    const isLoggedIn = rootGetters['auth/isLoggedIn'];
    if (isLoggedIn) {
      const feeValue = await connApp
        .invoke('GetPositionLoyaltyFeeUsdValue', { positionId })
        .catch((err) => {
          console.log(err);
        });

      commit(types.SET_POSITION_LOYALTY_FEE_VALUE, feeValue);
    }
  },

  async fetchPositionLoyaltyFeeRate({ commit, rootGetters }, positionId) {
    const isLoggedIn = rootGetters['auth/isLoggedIn'];
    if (isLoggedIn) {
      const feeRate = await connApp
        .invoke('GetPositionLoyaltyFeeRate', { positionId })
        .catch((err) => {
          console.log(err);
        });

      commit(types.SET_POSITION_LOYALTY_FEE_RATE, feeRate);
    }
  },

  async fetchLoyaltyPoolBalance({ commit }) {
    const loyaltyPoolBalance = await connApp
      .invoke('GetLoyaltyPoolBalance')
      .catch((err) => {
        console.log(err);
      });

    commit(types.SET_LOYALTY_POOL_BALANCE, loyaltyPoolBalance);
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
