import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import createPersistedState from 'vuex-persistedstate';
import VueTour from 'v3-tour';
import { cachedPaths } from '@/store';
import 'v3-tour/dist/vue-tour.css';
import { widget } from '../public/charting_library_v28/charting_library/charting_library.esm';
import Icon from '@/components/ui/Icon.vue';
import VueClickAway from 'vue3-click-away';

import registerModule from './registerModule';
import settings from '@/modules/settings';
import modals from '@/modules/modals';
import markets from '@/modules/markets';
import user from '@/modules/user';
import burger from '@/modules/burger';
import features from '@/modules/features';
import session from '@/modules/session';
import positions from '@/modules/positions';
import formPosition from '@/modules/formPosition';
import bounty from '@/modules/bounty';
import i18nFactory from '@/i18n';
import { connMarkets, connApp } from '@/helpers/signalR';
import { initConsole } from '@/console';

import '@/assets/styles/main.scss';
import useDemopositions from '@/compositions/useDemopositions';

initConsole();

sessionStorage.setItem('appReadyStatus', 'init');

window.showState = function () {
  if (process.env.NODE_ENV === 'development') {
    console.log(store.state);
  }
};

window.showVuex = function () {
  if (process.env.NODE_ENV === 'development') {
    let s = JSON.parse(localStorage['vuex']);
    console.log(s);
  }
};

let options = {
  cachedPaths,
};
options = registerModule(modals, { store, options });
options = registerModule(burger, { store, options });
options = registerModule(user, { store, router, options });
options = registerModule(settings, { store, options });
options = registerModule(markets, { store, options });
options = registerModule(session, { store, options });
options = registerModule(positions, { store, options });
options = registerModule(features, { store, options });
options = registerModule(bounty, { store, options });
options = registerModule(formPosition, { store, options });

// multiple calls of createCachedPaths lead to bad behaviour, hence passing options in/out above
createPersistedState({ paths: options.cachedPaths })(store);

async function startVue() {
  const app = createApp(App);
  app.provide('tv_widget', widget);
  const i18n = await i18nFactory();
  app
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueTour)
    .use(VueClickAway)
    .component('icon', Icon)
    .mount('#app');
}

async function run() {
  // [Vadim] do not await connMarkets, it significantly slows down the launch
  // it will await internally in case if invoke happens before it finished connecting
  connMarkets.start().catch((e) => {
    console.error('Error when starting connMarkets #signalR #dxs', e);
  });

  // if user is logged in, then connApp will be started by the auth module
  await store.dispatch('auth/initAuth', null, { root: true });

  if (!store.getters['auth/isLoggedIn']) {
    console.debug('Not logged in, initializing connApp');
    const { initialDemoPositions } = useDemopositions(store);
    initialDemoPositions();

    // [Vadim] do not await connApp.start, it significantly slows down the launch
    // it will await internally in case if invoke happens before it finished connecting
    connApp.start().catch((e) => {
      console.error('Error when starting connApp #signalR #dxs', e);
    });
  }

  await startVue();
}

run().catch((err) => {
  console.error('Error starting Vue', err);
});
