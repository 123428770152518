const quotes = [
  {
    title: '“Cut your losses short and let your winners run.”',
    author: 'Reminiscences of a Stock Operator',
    by: 'by Edwin Lefèvre',
  },
  {
    title: '“Do more of what works and less of what doesn’t.”',
    author: 'Trading for a Living',
    by: 'by Dr. Alexander Elder',
  },
  {
    title: '“The trend is your friend until the end when it bends.”',
    author: 'Technical Analysis of Financial Markets',
    by: 'by John J. Murphy',
  },
  {
    title: '“The market can stay irrational longer than you can stay solvent.”',
    author: 'A Random Walk Down Wall Street',
    by: 'by Burton Malkiel',
  },
  {
    title:
      '“There is nothing new on Wall Street. Whatever happens today has happened before and will happen again.”',
    author: 'Reminiscences of a Stock Operator',
    by: 'by Edwin Lefèvre',
  },
  {
    title:
      '“It’s not the news that matters, but how the market reacts to the news.”',
    author: 'Trading in the Zone',
    by: 'by Edwin Lefèvre',
  },
  {
    title:
      '“The best traders have no ego. You have to swallow your pride and get out of the losses.”',
    author: 'Market Wizards',
    by: 'by Jack D. Schwager',
  },
  {
    title: '“Always understand the risk/reward relationship.”',
    author: 'The New Trading for a Living',
    by: 'by Jack D. Schwager',
  },
  {
    title:
      '“If most traders would learn to sit on their hands 50 percent of the time, they would make a lot more money.”',
    author: 'The Disciplined Trader',
    by: 'by Jack D. Schwager',
  },
];

const showSplash = (mode) => {
  if (!mode) {
    mode = 'dark';
  } else if (mode !== 'dark' && mode !== 'light') {
    console.error('Invalid mode for splash screen:', mode);
    mode = 'dark';
  }

  const len = quotes.length - 1;
  const ndx = Math.round(Math.random() * len);
  const quote = quotes[ndx];

  document.querySelector('.static-splash__title').innerHTML = quote.title;
  document.querySelector('.static-splash__description').innerHTML =
    quote.author;
  document.querySelector('.static-splash__description-by').innerHTML = quote.by;

  document.body.style.overflow = 'hidden';
  document.querySelector('.static-splash').classList.add(mode);
  document.querySelector('.static-splash').style.display = '';
};

const hideSplash = () => {
  const intervalId = setInterval(() => {
    const state = sessionStorage.getItem('appReadyStatus');
    if (state === 'mountedDashboard') {
      clearInterval(intervalId);
      document.querySelector('.static-splash').classList.add('hide');

      setTimeout(() => {
        document.querySelector('.static-splash').style.display = 'none';
        document.body.style.overflow = '';
      }, 1000);
    }
  }, 1000);
};

export { showSplash, hideSplash };
