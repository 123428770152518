import store from '@/store';
import { isFiorinStable } from '@/config/constants';

const assertFiorin = () => {
  if (!store.getters['auth/isFiorin']) {
    console.debugError(
      'Accessing the fiorin-related fields when not authenticated  or using other wallet #wallet #getters #ifFiorin',
      'provider:',
      store.getters['auth/provider']
    );

    throw new Error('Not Fiorin');
  }
};

export default {
  namespaced: true,

  state: {
    marginAmountUsd: 0,
  },

  getters: {
    fitTokens: (state, getters, rootState, rootGetters) => {
      assertFiorin();

      const tokens = rootGetters['fiorin/activeTokens'];
      if (!tokens) {
        return [];
      }

      const result = tokens
        .filter((t) => t.amountUsd >= state.marginAmountUsd)
        .sort((a, b) => b.amountUsd - a.amountUsd);

      return result;
    },
  },

  mutations: {
    setMarginAmountUsd(state, amountUsd) {
      const type = typeof amountUsd;
      if (type !== 'number') {
        console.debugError(
          'Invalid margin amount #fiorin #mutations #setMarginAmount',
          'amount:',
          amountUsd,
          'type:',
          type
        );
        throw new Error('Invalid margin amount');
      }

      state.marginAmountUsd = amountUsd;
    },
  },

  modules: {
    collateralToken: {
      namespaced: true,

      state: {
        selectedTokenId: null,
      },

      mutations: {
        setSelectedTokenId(state, tokenId) {
          state.selectedTokenId = tokenId;
        },
      },

      getters: {
        _currentTokenId: (state, getters, rootState, rootGetters) => {
          assertFiorin();

          const fitTokens = rootGetters['formPosition/fitTokens'];
          if (!fitTokens.length) {
            return null;
          }

          const selectedTokenId = state.selectedTokenId;
          if (!selectedTokenId) {
            return fitTokens[0].tokenId;
          }

          if (fitTokens.some((t) => t.tokenId === selectedTokenId)) {
            return selectedTokenId;
          }

          return fitTokens[0].tokenId;
        },

        token: (state, getters, rootState, rootGetters) => {
          const tokenId = getters._currentTokenId;
          const token = rootGetters['formPosition/fitTokens'].find(
            (t) => t.tokenId === tokenId
          );

          return token;
        },
        tokenId: (state, getters) => getters.token?.tokenId,
        amount: (state, getters) => getters.token?.amount,
        amountUsd: (state, getters) => getters.token?.amountUsd,
        currency: (state, getters) => getters.token?.currency,
        name: (state, getters) => getters.token?.name,
        isStable: (state, getters) => isFiorinStable(getters.currency),
      },

      actions: {
        setTokenId({ commit, rootGetters }, tokenId) {
          assertFiorin();
          if (!tokenId) {
            console.debugError(
              'Empty tokenId #fiorin #mutations #setSelectedTokenId'
            );
            throw new Error('Empty tokenId');
          }

          const fitTokens = rootGetters['formPosition/fitTokens'];
          if (!fitTokens.some((t) => t.tokenId === tokenId)) {
            console.debugError(
              'Token does not exist in the fit tokens list #fiorin #mutations #setSelectedTokenId'
            );
            throw new Error('Token does not exist in the fit tokens list');
          }

          commit('setSelectedTokenId', tokenId);
        },
      },
    },
  },
};
