import { useScope } from '@/compositions/useScope';
import { saveMobileScrollPositions, setLocalItem } from '@/helpers/storage';
import { getAuthParamsFromRoute } from '@/helpers/utils';

export default ({ router }) => {
  const scopeComposition = useScope();

  router.beforeEach(async (to, from, next) => {
    // todo: [Vadim] maybe do not use isNotAuthPage?
    // auth checks
    const { isNotAuthPage } = getAuthParamsFromRoute(to);

    if (scopeComposition.isNotDesktop && isNotAuthPage) {
      if (!from.name) {
        ['Account', 'Markets', 'Positions'].forEach((routeName) => {
          setLocalItem(`mobileScrollPositions.${routeName}`, {
            top: 0,
            left: 0,
          });
        });
      }
      saveMobileScrollPositions(from.name);
    }
    next();
  });
};
