<template>
  <div :class="{ clicked, large: largeValue }" class="panel-info-label-value">
    <div class="panel-info-label-value__info">
      <div class="panel-info-label-value__info-label">
        {{ title }}
      </div>
      <div class="panel-info-label-value__info-value" :class="{ largeValue }">
        {{ value }}
      </div>
    </div>
    <div v-if="clicked" class="panel-info-label-value__controls">
      <div class="panel-info-label-value__arrow">
        <Icon name="arrow_down" width="12" height="12" class="arrow-icon" />
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/icons/arrow_down';
import Icon from '@/components/ui/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    title: {
      required: true,
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    largeValue: {
      type: Boolean,
      default: false,
    },
    clicked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/panels.scss';

.panel-info-label-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $backgroundPanelColorDark;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  box-sizing: border-box;

  &.large {
    height: 69px;
  }

  .arrow-icon {
    margin-left: 10px;
    margin-top: 8px;
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);
    color: $colorActiveBlue;
  }

  &.clicked {
    cursor: pointer;

    &:hover {
      background: $backgroundHoverColorDark;

      .panel-info-label-value__info-value {
        color: $colorActiveBlue;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Gotham_Pro_Regular;
  }

  &__info-label {
    font-size: 15px;
    color: $colorLabelDark;
  }

  &__info-value {
    font-size: 15px;
    color: $colorTextDark;
    font-family: Cantarell_Bold;
    margin-top: 5px;

    &.largeValue {
      font-size: 30px;
      margin-top: 0;
      margin-bottom: -10px;
    }
  }
}

body.light {
  .panel-info-label-value {
    background: $backgroundPanelColorLight;

    &.clicked {
      &:hover {
        background: $backgroundHoverColorLight;
      }
    }

    &__info-label {
      color: $colorLabelLight;
    }

    &__info-value {
      color: $colorTextLight;
    }
  }
}
</style>
