<template>
  <div
    :class="{ withHint: hintTitle, isRed, isGreen }"
    class="label-value-item"
  >
    <div
      :class="{ bold: boldLabel, underline }"
      class="label-value-item__label decoration--underlined text-secondary"
      @mouseover="showHint = true"
      @touchend="showHint = true"
      @mouseleave="showHint = false"
    >
      <slot name="label-icon" />
      {{ label }}
    </div>
    <div
      v-if="isHtmlValue"
      :class="{ bold: boldValue }"
      class="label-value-item__value"
      v-html="value"
    />
    <div
      v-else
      :class="{ bold: boldValue, inSleep: sleepLoading }"
      class="label-value-item__value"
    >
      {{ value }}
      <slot />
    </div>
    <div v-if="info" class="label-value-item__info">
      {{ info }}
    </div>
    <Hint
      v-if="hintTitle && showHint"
      ref="hintElement"
      :title="hintTitle"
      :width="widthHint"
      :hint-options="hintOptions"
    />
  </div>
</template>

<script>
import Hint from '@/components/ui/Hint';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

export default {
  name: 'LabelValueItem',
  components: {
    Hint,
  },
  props: {
    widthHint: {
      type: String,
      default: '',
    },
    isHtmlValue: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    boldLabel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    boldValue: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
    hintTitle: {
      type: String,
      default: '',
    },
    hintOptions: {
      type: Object,
    },
    sleepLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const showHint = ref(false);
    const hintElement = ref(null);

    onClickOutside(hintElement, () => (showHint.value = false));

    return {
      showHint,
      hintElement,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';
@keyframes pulsate {
  50% {
    opacity: 0.5;
  }
}

.label-value-item {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 18px;
  margin-bottom: 1rem;

  &__label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 0.9375rem;
    text-align: left;
    font-family: Gotham_Pro_Regular;

    &.underline {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
      cursor: pointer;
    }

    &.bold {
      font-family: Gotham_Pro_Bold;
      font-weight: 600;
      color: $color-gray2;
    }
  }

  &__value {
    font-size: 0.9375rem;
    text-align: right;
    margin-top: -2px;
    font-family: Cantarell_Regular;

    &.bold {
      font-family: Cantarell_Bold;
    }

    &.inSleep {
      opacity: 0.1;
      -webkit-animation: pulsate 1s linear infinite;
      animation: pulsate 1s linear infinite;
    }
  }
}

.label-value-item.withHint {
  .label-value-item__label:not(.underline) {
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    cursor: pointer;
  }
}
</style>
