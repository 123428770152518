<template>
  <div class="info-block-vert-panel">
    <div class="col-wrap">
      <div class="info-block-vert-panel__title">{{ title }}</div>
      <div :class="{ isGreen, isRed }" class="info-block-vert-panel__value">
        {{ value }}
        <div v-if="withArrow">
          <Icon
            name="arrow_down"
            width="12"
            height="12"
            class="arrow-icon openCard"
          />
        </div>
      </div>
    </div>
    <div
      v-if="description"
      class="info-block-vert-panel__description"
      v-html="description"
      @click="$emit('descriptionMore')"
    />
  </div>
</template>
<script>
import '@/assets/icons/arrow_down';
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'InfoBlockVertPanel',
  components: {
    Icon,
  },
  props: {
    withArrow: {
      type: Boolean,
      default: false,
    },
    title: {
      required: true,
    },
    value: {
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.info-block-vert-panel {
  display: flex;
  justify-content: space-between;
  // flex-direction: column;
  align-items: center;
  min-height: 69px;
  background: rgb(220, 218, 218);
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  font-family: Gotham_Pro_Regular;

  .arrow-icon {
    margin-left: 10px;
    margin-top: 0;
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);

    &.openCard {
      transform: rotate(0);
    }
  }

  .col-wrap {
    display: flex;
    flex-direction: column;
  }

  &__description {
    font-size: 11px;
    line-height: 14px;
    color: #444;
    cursor: pointer;
  }

  &__title {
    // font-size: 11px;
    font-family: Gotham_Pro_Regular;
    line-height: 14px;
    font-size: 0.9375rem;
    color: #888;
  }

  &__value {
    margin-top: 3px;
    font-size: 30px;
    font-family: Cantarell_Bold;
    line-height: 30px;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    div {
      margin-top: -8px;
    }

    &.isGreen {
      color: #509f96;
    }

    &.isRed {
      color: #de5e56;
    }
  }
}

body.dark {
  .info-block-vert-panel {
    background: #262c3c;

    &__description {
      color: #fff;
    }
  }
}
</style>
