<template>
  <PositionsCardTabs
    v-if="['opened', 'proposed'].includes(currentPosition.state)"
    :active-index="activeTab"
    :is-proposed="proposedPosition"
    @changeTab="onChangeTab"
  />
  <div
    :class="{
      'first-tab':
        activeTab === 0 &&
        ['opened', 'proposed'].includes(currentPosition.state),
      [`${currentPosition.state}`]: true,
      standalone:
        wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
    }"
    class="positions-card__body"
  >
    <template
      v-if="['history', 'canceled', 'closed'].includes(currentPosition.state)"
    >
      <div v-if="currentPosition.open_tx" class="positions-card__link">
        <a
          class="decoration--underlined"
          target="_blank"
          rel="noopener noreferrer"
          :href="`https://whatsonchain.com/tx/${currentPosition.open_tx}`"
          >{{ $t('order.card.transactions.opening') }}</a
        >
      </div>
      <div
        v-if="!currentPosition.marginAt && !currentPosition.marginTx"
        class="positions-card__link"
      >
        {{ $t('order.card.transactions.closingPending') }}
      </div>
      <div v-else-if="!currentPosition.marginTx" class="positions-card__link">
        {{ $t('order.card.transactions.nomargin') }}
      </div>
      <div
        v-else-if="currentPosition.marginTx.txId"
        class="positions-card__link"
      >
        <a
          class="decoration--underlined"
          target="_blank"
          rel="noopener noreferrer"
          :href="`https://whatsonchain.com/tx/${currentPosition.marginTx.txId}`"
          >{{ $t('order.card.transactions.closing') }}</a
        >
      </div>
      <div
        v-if="currentPosition.profitTx && currentPosition.profitTx.txId"
        class="positions-card__link mb-40"
      >
        <a
          class="decoration--underlined"
          target="_blank"
          rel="noopener noreferrer"
          :href="`https://whatsonchain.com/tx/${currentPosition.profitTx.txId}`"
          >{{ $t('order.card.transactions.profit') }}</a
        >
      </div>
      <div
        v-if="
          !currentPosition.isProfitVoided &&
          !currentPosition.profitTx &&
          currentPosition.state !== 'canceled' &&
          currentPosition.net_pl - positionLoyaltyFeeValue > 0
        "
        class="positions-card__link mb-40"
      >
        <a
          class="decoration notborder"
          rel="noopener noreferrer"
          target="_blank"
        >
          {{ $t('order.card.transactions.profitPending') }}
          {{
            currentPosition.closed_session_id ? '' : `(${nextSettlement || 0})`
          }}
        </a>
      </div>
    </template>
    <PositionDetailsOpened
      v-if="activeTab === 0 && openedPosition"
      @setTakeProfit="setTakeProfit"
      @setStopLoss="setStopLoss"
      :position-loyalty-fee-value="positionLoyaltyFeeValue"
      :position="currentPosition"
    />
    <PositionDetailsProposed
      v-if="activeTab === 0 && proposedPosition"
      :position="currentPosition"
      @setEntryPrice="setEntryPrice"
      @setTakeProfit="setTakeProfit"
      @setStopLoss="setStopLoss"
    />
    <PositionDetailsClosed
      v-if="['history', 'canceled', 'closed'].includes(currentPosition.state)"
      :position-loyalty-fee-value="positionLoyaltyFeeValue"
      :position="currentPosition"
    />
    <PositionUpdate
      v-if="activeTab === 1"
      ref="positionUpdate"
      :position="currentPosition"
      @changePosition="onUpdateRiskPosition"
      @changePositionInfo="onChangeRiskPositionMetaInfo"
    />
    <PositionSplit
      v-if="activeTab === 2 && openedPosition"
      :position="currentPosition"
      @changePosition="onChangePositionSplit"
      @changeSplitValues="onChangeSplitValues"
    />
    <PositionUpdateProposed
      v-if="activeTab === 2 && proposedPosition"
      :position="currentPosition"
      @changePosition="onChangePositionProposed"
      @error="setEntryError"
    />
  </div>
  <div
    :class="{
      standalone:
        wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
    }"
    class="row-btns"
  >
    <Button
      v-if="activeTab === 0 && !hideTradeButton"
      :loading="splitBtnLoading"
      :text="$t('order.card.buttons.trade')"
      type="secondary"
      class="half-btn first"
      @click="handleTradeClick"
    />
    <Button
      v-if="
        activeConnect.provider &&
        activeTab === 0 &&
        [
          PositionTypes.history,
          PositionTypes.opened,
          PositionTypes.closed,
        ].includes(currentPosition.state)
      "
      :text="$t(copyButtonKey)"
      :loading="loadingCopy"
      :disabled="loadingCopy"
      type="secondary"
      class="half-btn"
      @click="copyLink"
    />
    <Button
      v-if="activeTab === 0 && openedPosition && activeConnect.provider"
      :loading="splitBtnLoading && loadingPosition"
      :text="$t('popups.buttons.close')"
      type="secondary"
      :disabled="splitBtnLoading && loadingPosition"
      class="half-btn last"
      @click="confirmClosePosition"
    />
    <Button
      v-if="activeTab === 0 && proposedPosition"
      :loading="splitBtnLoading"
      :text="$t('order.card.buttons.cancelPosition')"
      type="secondary"
      class="half-btn last"
      @click="confirmClosePosition"
    />
    <Button
      v-if="activeTab === 2 && openedPosition"
      :loading="splitBtnLoading"
      :text="$t('order.card.buttons.splitPosition')"
      :disabled="
        !splitValue1 || !splitValue2 || 0 >= splitValue1 || 0 >= splitValue2
      "
      type="secondary"
      class="split-btn"
      @click="splitPosition"
    />
    <Button
      v-if="activeTab === 2 && proposedPosition"
      :loading="splitBtnLoading"
      :text="$t('order.card.buttons.updatePrice')"
      :disabled="entryError"
      type="secondary"
      class="split-btn"
      @click="updatePendingPosition"
    />
    <Button
      v-if="activeTab === 1"
      :loading="loadingPosition"
      :text="$t('order.card.buttons.updatePosition')"
      :disabled="loadingPosition"
      type="secondary"
      class="split-btn"
      @click="updatePosition"
    />
  </div>
  <ModalMarketOpenClose
    v-if="showMarketOpenClose"
    :hide-again-btn="true"
    :value="workHour"
    @close="
      showMarketOpenClose = false;
      // eslint-disable-next-line prettier/prettier
      workHour = '';
    "
  />
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import Button from '@/components/ui/Button';
import PositionsCardTabs from './PositionsCardTabs';
import PositionDetailsOpened from './positionsDetails/PositionDetailsOpened';
import PositionDetailsProposed from './positionsDetails/PositionDetailsProposed';
import PositionDetailsClosed from './positionsDetails/PositionDetailsClosed';

import PositionSplit from './PositionSplit';
import PositionUpdate from './PositionUpdate';
import PositionUpdateProposed from './PositionUpdateProposed';

import notify from '@/plugins/notify';
import { copyText } from 'vue3-clipboard';

import { formatMinutes, isToOpen } from '@/helpers/utils';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { PositionTypes } from '@/modules/positions/helpers';
import { useI18n } from 'vue-i18n';
import axios from 'axios';

import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';
import { workHours } from '@/helpers/utils';
import useMarketCoin from '@/compositions/useMarketCoin';
import { PositionSides } from '@/config/constants';
import config from '@/config';
import DateTime from 'luxon/src/datetime';
import { SETTLEMENT_INTERVAL } from '@/helpers/enums';
import { useModals } from '@/modules/modals/api';
import { setRouteFromActiveMarket } from '@/helpers/activeMarketRoute';
import { scope } from '@/breakpoints';
import { userProviders } from '@/modules/user/api';
import useClosePosition from '@/compositions/useClosePosition';

export default {
  name: 'PositionsCard',
  components: {
    Button,
    PositionsCardTabs,
    PositionDetailsOpened,
    PositionDetailsProposed,
    PositionDetailsClosed,
    PositionSplit,
    PositionUpdate,
    PositionUpdateProposed,
    ModalMarketOpenClose,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { showModal, modalsByName } = useModals();
    const { closePositionJustNow } = useClosePosition();
    const loadingCopy = ref(false);
    const loadingPosition = ref(false);
    const entryPriceProposed = ref('');
    const splitPositionData = ref({});
    const riskPositionData = ref({});
    const riskPositionDataMetaInfo = ref({});
    const nextSettlement = ref('0');
    const nextSettlementInterval = ref(null);
    const positionUpdate = ref({});

    const hideTradeButton = ref(localStorage.getItem('hideTradeButton'));
    localStorage.removeItem('hideTradeButton');

    const splitBtnLoading = ref(false);
    const store = useStore();

    const splitValue1 = ref('');
    const splitValue2 = ref('');

    const sessionInfo = computed(() => store.getters['session/info']);
    const bountyTasks = computed(() => store.getters['bounty/bountyTasks']);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const currentPosition = computed(
      () => store.getters['positions/getCurrentPosition']
    );
    console.log('currentPosition', currentPosition.value);

    const { isMarketActive } = useMarketCoin();
    const marketsById = computed(() => store.getters['markets/marketsById']);
    const proposedPosition = computed(
      () => currentPosition.value.state === 'proposed'
    );
    const openedPosition = computed(
      () => currentPosition.value.state === 'opened'
    );

    if (scope.isLarge || scope.isXlarge) {
      setRouteFromActiveMarket();
    }

    const activeTab = ref(0);
    const onChangeTab = (tab) => {
      activeTab.value = tab;
      localStorage.setItem('positionCardTab', tab);
    };

    const workHour = ref('');
    const showMarketOpenClose = ref(false);

    const confirmClosePosition = async () => {
      const market =
        store.getters['markets/marketsById'][currentPosition.value.market_id];

      if (market && openedPosition.value && !isMarketActive(market)) {
        workHour.value = workHours(new Date(), market);
        showMarketOpenClose.value = true;
        return;
      }

      if (currentPosition.value.is_bounty) {
        const phoneConfirmed =
          store.getters['settings/settings']?.phoneNumberConfirmed;

        if (!phoneConfirmed) {
          return store.dispatch('burger/phoneVerification', { auto: false });
        }
      }

      // cancel now! stop confirm modal
      if (currentPosition.value.state === 'proposed') {
        await closePositionJustNow();
        return;
      }

      store.dispatch('positions/onPositionCloseRequest');
    };

    const splitPosition = async () => {
      await store.dispatch(
        'positions/changeUserPosition',
        splitPositionData.value
      );
      splitValue1.value = '';
      splitValue2.value = '';
      emit('close');
    };

    const onChangePositionSplit = (splitInfo) => {
      splitPositionData.value = splitInfo;
    };

    const onChangeSplitValues = (splitValues) => {
      splitValue1.value = splitValues.val1[0];
      splitValue2.value = splitValues.val1[1];
    };

    const entryError = ref(false);

    const setEntryError = (error) => {
      entryError.value = error;
    };

    const onChangePositionProposed = (entry) => {
      entryPriceProposed.value = entry;
    };

    const onUpdateRiskPosition = (riskInfo) => {
      riskPositionData.value = riskInfo;
    };

    const onChangeRiskPositionMetaInfo = (metaInfo) => {
      riskPositionDataMetaInfo.value = metaInfo;
    };

    const updatePosition = async () => {
      if (positionUpdate?.value?.tpError) {
        return;
      }

      const market = marketsById.value[currentPosition.value.market_id];

      if (!market) {
        notify({ text: t('order.history.waitAndTryAgain'), type: 'info' });
      }

      // checks
      if (currentPosition.value.side === PositionSides.BUY) {
        if (proposedPosition.value) {
          if (
            riskPositionDataMetaInfo.value.lossAmount > market.usdPrice &&
            riskPositionDataMetaInfo.value.lossIndexMode === 2
          ) {
            notify({
              text: t('order.card.tpsl.toasts.stopLossBelowBid'),
              type: 'info',
            });
            return;
          }
          if (riskPositionDataMetaInfo.value.slValue1 < 0) {
            notify({
              text: t('order.card.tpsl.toasts.notNegative'),
              type: 'info',
            });
            return;
          }
        }

        if (openedPosition.value) {
          if (
            riskPositionDataMetaInfo.value.lossAmount &&
            currentPosition.value.usdPrice <
              riskPositionDataMetaInfo.value.calculateStopLossPrice()
          ) {
            notify({
              text: t('order.card.tpsl.reasons.stopLossGreaterCurrent'),
              type: 'info',
            });
            return;
          }

          if (
            riskPositionDataMetaInfo.value.profitAmount &&
            market.usdPrice >
              riskPositionDataMetaInfo.value.calculateTakeProfitPrice()
          ) {
            notify({
              text: t('order.card.tpsl.reasons.takeProfitLessCurrent'),
              type: 'info',
            });

            return;
          }
        }
      } else {
        if (proposedPosition.value) {
          if (
            riskPositionDataMetaInfo.value.lossAmount &&
            riskPositionDataMetaInfo.value.lossAmount < market.usdPrice &&
            riskPositionDataMetaInfo.value.lossIndexMode === 2
          ) {
            notify({
              text: t('order.card.tpsl.toasts.stopLossAboveAsk'),
              type: 'info',
            });
            return;
          }
          if (
            riskPositionDataMetaInfo.value.tpValue1 < 0 ||
            riskPositionDataMetaInfo.value.tpValue2 < 0 ||
            riskPositionDataMetaInfo.value.profitAmount < 0
          ) {
            notify({
              text: t('order.card.tpsl.toasts.notNegative'),
              type: 'info',
            });
            return;
          }
        }

        if (openedPosition.value) {
          if (
            riskPositionDataMetaInfo.value.lossAmount &&
            market.usdPrice >
              riskPositionDataMetaInfo.value.calculateStopLossPrice()
          ) {
            notify({
              text: t('order.card.tpsl.reasons.stopLossLessCurrent'),
              type: 'info',
            });
            return;
          }

          if (
            riskPositionDataMetaInfo.value.profitAmount &&
            market.usdPrice <
              riskPositionDataMetaInfo.value.calculateTakeProfitPrice()
          ) {
            notify({
              text: t('order.card.tpsl.reasons.takeProfitGreaterCurrent'),
              type: 'info',
            });
            return;
          }
        }
      }
      await store.dispatch(
        'positions/changeUserPosition',
        riskPositionData.value
      );
      if (!riskPositionData.value.position_id) {
        notify({
          text: t('order.card.tpsl.toasts.positionUpdated', {
            market: market.name,
          }),
          type: 'info',
        });
      }
      emit('close');
    };

    const updatePendingPosition = async () => {
      loadingPosition.value = true;
      const market = marketsById.value[currentPosition.value.market_id];

      if (market) {
        if (isToOpen(new Date(), market)) {
          await store.dispatch('positions/changeUserPosition', {
            position_id: currentPosition.value.id,
            entry_price: entryPriceProposed.value,
            market: currentPosition.value.market,
          });

          loadingPosition.value.false = true;

          notify({
            text: t('order.card.tpsl.toasts.positionUpdated', {
              market: market.name,
            }),
            type: 'info',
          });

          emit('close');
        }
      }
    };

    const setEntryPrice = () => {
      onChangeTab(2);
      setTimeout(() => {
        document.querySelector('.entry-price-input input').focus();
      }, 300);
    };

    const setTakeProfit = () => {
      onChangeTab(1);
      setTimeout(() => {
        document.querySelector('.takeprofit-input input').focus();
      }, 300);
    };

    const setStopLoss = () => {
      onChangeTab(1);
      setTimeout(() => {
        document.querySelector('.stoploss-input input').focus();
      }, 300);
    };

    const positionLoyaltyFeeValue = computed(
      () => store.getters['volumeLimits/positionLoyaltyFeeValue']
    );

    onMounted(() => {
      if (currentPosition.value) {
        store.dispatch(
          'volumeLimits/fetchPositionLoyaltyFeeValue',
          currentPosition.value.id
        );
      }
    });

    onMounted(async () => {
      // focus
      if (localStorage.getItem('positionBehavior') === 'profit') {
        setTakeProfit();
      }
      if (localStorage.getItem('positionBehavior') === 'loss') {
        setStopLoss();
      }
      if (['open', 'pending'].includes(currentPosition.value.type)) {
        localStorage.removeItem('positionBehavior');

        // set tab from memory
        if (
          !localStorage.getItem('positionBehavior') &&
          localStorage.getItem('positionCardTab')
        ) {
          onChangeTab(+localStorage.getItem('positionCardTab'));
        }
      }

      if (activeConnect.value.provider === userProviders.fiorin) {
        await store.dispatch('bounty/updateBountyTasks');
      }
    });

    const copyButtonKey = computed(() => {
      if (
        navigator &&
        navigator.share &&
        navigator.canShare &&
        navigator.canShare({ url: 'https://dxs.app' })
      ) {
        return 'order.card.buttons.share';
      } else if (
        navigator &&
        navigator.clipboard &&
        navigator.clipboard.writeText
      ) {
        return 'order.card.buttons.copy';
      }
      return '';
    });

    const copyLink = async () => {
      loadingCopy.value = true;
      let shareLink = '';
      try {
        const response = await axios.post(
          `${config.apiUrl}share/position/${currentPosition.value.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${activeConnect.value.accessToken}`,
            },
          }
        );

        shareLink = response.data;

        if (navigator.share && navigator.canShare) {
          navigator.share({
            title: 'DXS: trade crypto and stocks with leverage',
            text:
              'Look at my #' +
              (currentPosition.value.side === PositionSides.BUY
                ? 'long'
                : 'short') +
              currentPosition.value.market.replace('/', '') +
              ' position! Trade it here @DXSapp #DXStrade',
            url: shareLink,
          });
        } else {
          copyText(shareLink);
          notify({
            text: t('order.new.toasts.linkCopied'),
            type: 'info',
          });
        }
        loadingCopy.value = false;

        if (
          activeConnect.value.provider === userProviders.fiorin &&
          !bountyTasks.value.bountySharing &&
          store.getters['settings/settings']?.bountyProgramAvailable
        ) {
          showModal(modalsByName.bountySharing);
        }
      } catch (error) {
        console.error(error);
        shareLink = '';
        loadingCopy.value = false;
      }
    };

    const marketsList = computed(() => store.getters['markets/markets']);

    const handleTradeClick = () => {
      const market = marketsList.value.find(
        (m) => m.id === currentPosition.value.market_id
      );
      store.commit('markets/UPDATE_ACTIVE_MARKET', market);
      setRouteFromActiveMarket();
      emit('close');
    };

    const calcNextSettlement = () => {
      nextSettlement.value = formatMinutes(
        DateTime.fromISO(sessionInfo.value.sessionEnd).diffNow('minutes')
          .minutes
      );
    };

    const createTimer = () => {
      nextSettlementInterval.value = setInterval(
        calcNextSettlement,
        SETTLEMENT_INTERVAL
      );
    };

    calcNextSettlement();
    createTimer();

    watch(
      () => currentPosition?.value?.state,
      (newV) => {
        if (!newV) {
          emit('close');
        }
        onChangeTab(0);
      }
    );

    watch(
      () => sessionInfo.value.sessionEnd,
      () => {
        calcNextSettlement();
        if (nextSettlementInterval.value) {
          clearInterval(nextSettlementInterval.value);
        }
        createTimer();
      }
    );

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      currentPosition,
      showModal,
      modalsByName,
      proposedPosition,
      openedPosition,
      onChangeRiskPositionMetaInfo,
      splitValue1,
      splitValue2,
      onChangeSplitValues,
      copyButtonKey,
      copyLink,
      handleTradeClick,
      loadingCopy,
      loadingPosition,
      positionUpdate,
      onChangeTab,
      setEntryPrice,
      setStopLoss,
      setEntryError,
      entryError,
      setTakeProfit,
      updatePosition,
      updatePendingPosition,
      onChangePositionSplit,
      onChangePositionProposed,
      activeTab,
      splitBtnLoading,
      splitPosition,
      onUpdateRiskPosition,
      hideTradeButton,
      confirmClosePosition,
      nextSettlement,
      nextSettlementInterval,
      showMarketOpenClose,
      workHour,
      PositionTypes,
      positionLoyaltyFeeValue,
      activeConnect,
      wd: window,
      isRunningStandaloneAndroid,
      isTelegram,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.positions-card {
  &__body {
    padding-left: $px20;
    padding-right: $px20;
    padding-bottom: 15px;
    overflow: auto;

    max-height: calc(100% - 117px);

    @media screen and (min-width: 1024px) {
      margin-bottom: 60px;
      padding-left: 40px;
      padding-right: 40px;
    }

    &.first-tab {
      padding-top: 20px;
    }

    &.opened,
    &.proposed {
      max-height: calc(100% - 152px);

      &.standalone {
        max-height: calc(100% - 180px);
      }
    }

    &.canceled,
    &.history {
      max-height: calc(100% - 117px);

      &.standalone {
        max-height: calc(100% - 160px);
      }
    }

    @media screen and (min-width: 1024px) {
      max-height: calc(100vh - 320px) !important;
    }
  }

  &__link {
    font-family: Gotham_Pro_Regular;
    text-align: center;
    margin-bottom: 20px;

    a {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
    }

    &.mb-40 {
      margin-bottom: 40px;
    }
  }
}

.row-btns {
  position: absolute;
  bottom: 0;

  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }

  &.standalone {
    bottom: 82px;
  }
}

.half-btn {
  width: 50%;
  margin: 0 1px;

  &.first {
    margin: 0;
  }

  &.last {
    margin: 0;
  }
}

.decoration.notborder {
  text-decoration: none;
}

.split-btn {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
