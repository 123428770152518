import { watch } from 'vue';
import { demoPositions, demoFavourites } from '@/config/demoPositions';

export default function useDemopositions(store) {
  const initialDemoPositions = async () => {
    if (store.getters['auth/isLoggedIn']) {
      console.error('Do not invoke initialDemoPositions when logged in!');
      return;
    }

    store.commit('settings/SET_UISETTING', {
      k: 'marketFavourites',
      v: demoFavourites,
    });

    store
      .dispatch(
        'positions/setAllPositions',
        demoPositions.map((positionInfo) => {
          return {
            ...positionInfo[1],
            market: positionInfo[0],
          };
        })
      )
      .then(() => {
        store.dispatch('positions/setReady');
      });

    watch(
      () => store.getters['markets/marketsLoading'],
      async (value) => {
        if (value) {
          // [Vadim] need this call after markets are loaded, because we need to UPDATE_TOTALS on positions ( markets are used there)
          await store.dispatch('positions/setPositionsStructure');
        }
      }
    );
  };

  return { initialDemoPositions };
}
