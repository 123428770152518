import { PositionTypes } from '@/modules/positions/helpers';

async function refreshUserData(store, connApp) {
  if (!store) {
    throw new Error('No store provided #refreshUserData');
  }

  if (!connApp) {
    throw new Error('No connApp provided #refreshUserData');
  }

  console.debug('#refreshUserData');

  setTimeout(() => {
    // need to recover scrolling, which was disabled by splash screen
    // but why here?
    document.body.style.overflow = '';
  }, 1500);

  const activePositionsPromise = connApp.invoke('FilterUserPositions', {
    state: 'Open,Proposed',
    take: 200,
  });
  const getHistoryPositionsPromise = connApp.invoke('FilterUserPositions', {
    state: 'History',
    take: 200,
  });
  const getClosedPositionStatsPromise = connApp.invoke(
    'GetClosedPositionsStats'
  );
  const getTradingStatsPromise = connApp.invoke('GetTradingStats');

  await store.dispatch(
    'positions/setAllPositions',
    (await activePositionsPromise) ?? []
  );

  const openPositions =
    store.getters['positions/getPositionsByType'][PositionTypes.open];

  const pendingPositions =
    store.getters['positions/getPositionsByType'][PositionTypes.pending];

  const historyPositions = (await getHistoryPositionsPromise) ?? [];

  await store.dispatch('positions/setAllPositions', [
    ...openPositions,
    ...pendingPositions,
    ...historyPositions,
  ]);

  await store.commit(
    'session/SET_USER_TRADE_INFO_HISTORY',
    await getClosedPositionStatsPromise
  );

  store.commit('session/SET_USER_TRADE_INFO', await getTradingStatsPromise);
}

export { refreshUserData };
