import { useStore } from 'vuex';
import { connMarkets } from '@/helpers/signalR';
import {
  createEntityFromMarket,
  createEntityFromStatus,
} from '@/modules/markets/helpers';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import pRetry from 'p-retry';
import useTheme, { Theme } from './useTheme';
import { TelegramMiniApp } from '@/tgApp/TelegramMiniApp';

export const useInitialize = () => {
  const store = useStore();
  const { storeTheme } = useTheme();

  const initMarkets = async () => {
    const markets = await pRetry(
      async () => {
        return await connMarkets.invoke('GetMarkets');
      },
      { retries: 3 }
    );
    const statuses = await pRetry(
      async () => {
        return await connMarkets.invoke('GetMarketStatuses');
      },
      { retries: 3 }
    );

    // const [markets, statuses] = await Promise.all([
    //   connMarkets.invoke('GetMarkets'),
    //   connMarkets.invoke('GetMarketStatuses'),
    // ]);

    if (isEmpty(markets) || isEmpty(statuses)) {
      console.warn(
        `Unable to init markers.\n statuses=${JSON.stringify(
          statuses
        )}\n markets=${JSON.stringify(markets)}`
      );

      return;
    }

    const mergedEntities = markets
      .filter((market) => market.name)
      .map((market) => {
        const status = find(statuses, ['marketId', market.id]);
        const marketEntity = createEntityFromMarket(market);
        const statusEntity = createEntityFromStatus(status);

        return { ...marketEntity, ...statusEntity };
      });

    store.commit('markets/SET_MARKETS', mergedEntities);
  };

  const woofHandler = () => {
    store.commit('settings/SET_UISETTING', {
      k: 'theme',
      v: storeTheme.value === Theme.light ? Theme.dark : Theme.light,
    });
    if (storeTheme.value === Theme.light) {
      document.body.classList.remove(Theme.dark);
      document.body.classList.add(Theme.dark);
    } else {
      document.body.classList.remove(Theme.dark);
      document.body.classList.add(Theme.dark);
    }
  };

  const initTelegram = () => {
    const instance = TelegramMiniApp.createIfInContext(window.location.hash);

    store.dispatch('telegram/setInstance', instance);
  };

  return { initMarkets, woofHandler, initTelegram };
};
