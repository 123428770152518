import { Theme } from '@/compositions/useTheme';
import { PositionSides } from '@/config/constants';

export const graphTypes = {
  BID: 'BID',
  MID: 'MID',
  ASK: 'ASK',
};

export const chartTheme = {
  light: 'Light',
  dark: 'Dark',
};

export const mapTheme = (theme) => {
  return (
    {
      light: chartTheme.light,
      dark: chartTheme.dark,
    }[theme] ?? chartTheme.dark
  );
};

export const colorMap = {
  [Theme.dark]: {
    buy: '#509f96',
    sell: '#de5e56',
  },
  [Theme.light]: {
    buy: '#509f96',
    sell: '#de5e56',
  },
};

export const mapPositionToColors = (theme = Theme.dark, position) => {
  const colors = {
    buy: colorMap[theme].buy,
    sell: colorMap[theme].sell,
    proposed: '#888',
    light: '#fff',
    dark: '#000',
  };
  const mainColor = {
    [Theme.light]: colors.light,
    [Theme.dark]: colors.dark,
  }[theme];
  const [secondColor] = [
    [colors.proposed, () => position.state === PositionSides.PROPOSED],
    [colors.buy, () => position.side === PositionSides.BUY],
    [colors.sell, () => position.side === PositionSides.SELL],
  ].find(([, predicate]) => predicate()) ?? [colors.proposed];

  return {
    mainColor,
    secondColor,
  };
};
