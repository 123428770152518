<template>
  <Modal
    @close="onClose"
    :close-outside="false"
    :modal-class="isTelegram ? 'chart-modal telegram' : 'chart-modal'"
    :active-market="activeMarket"
    :in-chart="true"
    hide-back-in-mini
    full-width
    :teleport="false"
  >
    <Card
      id="v-step-mobile-3"
      :is-toggle="false"
      :title-class="'card__title--p15 underline'"
      :title="parseTitle($t('mobile.ordersModal.orderTitle'))"
      description-left
      :description="isTelegram ? '' : marketDescriptionName"
      emit-click-event="showMarketData"
      @showMarketData="showModal(modalsByName.marketData)"
    >
      <!-- <template #title>
        <span
          id="v-step-mobile-4"
          @click="
            $tour_goToNextStep(
              $data.$tour_mobile,
              () => showModal(modalsByName.marketData),
              4
            )
          "
        >
          {{ parseTitle($t('mobile.ordersModal.orderTitle')) }}
        </span>
      </template> -->
      <Chart mobile @onGraphClick="toggleFormPosition" />
      <FormPosition @close="onClose" />
    </Card>
    <ModalConnectionLost
      v-if="showConnectionLostModal"
      @close="showConnectionLostModal = false"
    />
    <ModalMarketOpenClose
      v-if="workHourModal"
      :value="workHourModal"
      @dontShowAgainMarket="dontShowAgainMarket"
      @close="workHourModal = ''"
    />
    <ModalMarketData
      v-if="modalStatus(modalsByName.marketData)"
      @close-modal="
        $tour_goToNextStep(
          $data.$tour_mobile,
          () => hideModal(modalsByName.marketData),
          5
        )
      "
    />
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Chart from '@/components/ui/Chart';
import ModalMarketData from '@/modules/marketInfo/components/MarketInfoModal';
import ModalConnectionLost from '@/components/modals/ModalConnectionLost';
import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';
import Card from '@/components/ui/Card';
import FormPosition from '@/modules/formPosition/FormPosition';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';
import router from '@/router';
import tourMixin from '@/mixins/tour.mixin';
import {
  setActiveMarketFromRoute,
  setRouteFromActiveMarket,
} from '@/helpers/activeMarketRoute';
import { parseTitle } from '@/helpers/ui';
import { exchangeList } from '@/helpers/floatList';

export default defineComponent({
  name: 'MobileChart',
  components: {
    Modal,
    ModalMarketData,
    ModalConnectionLost,
    ModalMarketOpenClose,
    Chart,
    Card,
    FormPosition,
  },
  emits: ['close'],
  mixins: [tourMixin],
  props: {
    visible: Boolean,
  },
  setup(_, { emit }) {
    const store = useStore();

    // reset mobile flags
    localStorage.removeItem('mobileShowConnectionLost');
    localStorage.removeItem('mobileMarketOpenCloseWorkhour');

    const showConnectionLostModal = ref(
      !!localStorage.getItem('mobileShowConnectionLost')
    );
    const workHourModal = ref(
      localStorage.getItem('mobileMarketOpenCloseWorkhour')
    );

    const toggleFormPosition = () => {
      store.dispatch('positions/toggleFormPosition', true);
    };

    const onClose = () => {
      const prevRoutePath = router.options.history.state?.back;
      router.push(prevRoutePath === '/positions' ? prevRoutePath : '/markets');
      toggleFormPosition();
      emit('close');
    };

    const dontShowAgainMarket = () => {
      store.dispatch('markets/setDontShowAgainMarket');
      workHourModal.value = '';
    };

    const activeMarket = computed(() => store.getters['markets/activeMarket']);

    const marketsByName = computed(
      () => store.getters['markets/marketsByName']
    );

    const marketDescriptionName = computed(() => {
      if (!activeMarket.value) {
        return '';
      }

      const name = activeMarket.value.name; // marketLabel(activeMarket.value.name);

      if (!name) {
        return '';
      }

      const exchange =
        activeMarket.value.source || exchangeList[activeMarket.value.name];

      if (!exchange) {
        return name;
      }

      let formattedExchange = exchange.split('');
      // formattedExchange[0] = formattedExchange[0]; // .toUpperCase();

      return `${name} (${formattedExchange.join('')})`;
    });

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      isTelegram,
      parseTitle,
      router,
      store,
      activeMarket,
      toggleFormPosition,
      dontShowAgainMarket,
      onClose,
      marketsByName,
      showConnectionLostModal,
      workHourModal,
      marketDescriptionName,
      ...useModals(),
    };
  },
  watch: {
    marketsByName(markets) {
      if (!markets || !this.visible) {
        return;
      }

      setActiveMarketFromRoute();
      setRouteFromActiveMarket();
    },
  },
});
</script>

<style lang="scss" scoped>
.card {
  padding: 0;
}

.underline {
  text-decoration: underline;
}

.form-position {
  position: absolute;
  bottom: 2px;
  z-index: 1000;
  width: 100%;

  &.isTelegram {
    top: 0;
    bottom: initial;
  }

  &.standalone {
    bottom: 30px;

    &.showBackControls {
      bottom: 0;
    }
  }

  &.showBackControls {
    bottom: 0;
  }
}
</style>
