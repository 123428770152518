const types = {
  SET_PROFIT_OPTION: 'SET_PROFIT_OPTION',
  SET_LOSS_OPTION: 'SET_LOSS_OPTION',
  TOGGLE_POSITION_GROUP: 'TOGGLE_POSITION_GROUP',
  SET_SCROLLING_LIST_FLAG: 'SET_SCROLLING_LIST_FLAG',
  SET_SHOW_BACK_CONTROLS: 'SET_SHOW_BACK_CONTROLS:',
  SET_REMOVE_ALL_POSITIONS_STATUS: 'SET_REMOVE_ALL_POSITIONS_STATUS',
  SET_FIRST_APP_LOADED: 'SET_FIRST_APP_LOADED',
  SET_SHOW_CONNECT_MODAL: 'SET_SHOW_CONNECT_MODAL',
  SET_ACTIVITY_POINTS_BALANCE: 'SET_ACTIVITY_POINTS_BALANCE',
  SET_MARKETS_SEARCH_HISTORY: 'SET_MARKETS_SEARCH_HISTORY',
};

const getInitialState = () => ({
  defaultProfitBtnIndex: 2,
  defaultLossBtnIndex: 2,
  showPositionGroup: false,
  scrollingList: false,
  showBackControls: true,
  removeAllPositionsAStatus: false,
  firstAppLoaded: true,
  showConnectModal: false,
  activityPointsForTimer: null,
  marketsSearchHistory: [],
  activityPointsBalance: {
    balance: 0,
    nextBalance: 0,
  },
});

const getters = {
  marketsSearchHistory: (state) => state.marketsSearchHistory,
  activityPointsBalance: (state) => state.activityPointsBalance,
  activityPointsForTimer: (state) => state.activityPointsForTimer,
  showConnectModal: (state) => state.showConnectModal,
  firstAppLoaded: (state) => state.firstAppLoaded,
  removeAllPositionsAStatus: (state) => state.removeAllPositionsAStatus,
  showPositionGroup: (state, getters, rootState, rootGetters) => {
    const allPositions = rootGetters['positions/getPositions'].filter((item) =>
      ['open', 'pending'].includes(item.type)
    ).length;
    if (allPositions >= 200) {
      return false;
    }

    return state.showPositionGroup;
  },
  defaultProfitBtnIndex: (state) => state.defaultProfitBtnIndex,
  defaultLossBtnIndex: (state) => state.defaultLossBtnIndex,
  scrollingListFlag: (state) => state.scrollingList,
  showBackControls: (state) => state.showBackControls,
};

const mutations = {
  [types.SET_MARKETS_SEARCH_HISTORY](state, val) {
    if (state.marketsSearchHistory.includes(val)) {
      state.marketsSearchHistory = state.marketsSearchHistory.filter(
        (item) => item !== val
      );
    }
    state.marketsSearchHistory.unshift(val);

    if (state.marketsSearchHistory.length > 20) {
      state.marketsSearchHistory = state.marketsSearchHistory.slice(0, 20);
    }
  },
  [types.SET_ACTIVITY_POINTS_BALANCE](state, val) {
    if (val) {
      state.activityPointsBalance = val;
    }
    state.activityPointsForTimer = val;
  },
  [types.SET_SHOW_CONNECT_MODAL](state, val) {
    state.showConnectModal = val;
  },
  [types.SET_FIRST_APP_LOADED](state, val) {
    state.firstAppLoaded = val;
  },
  [types.SET_REMOVE_ALL_POSITIONS_STATUS](state, val) {
    state.removeAllPositionsAStatus = val;
  },
  [types.SET_SHOW_BACK_CONTROLS](state, val) {
    state.showBackControls = val;
  },
  [types.SET_SCROLLING_LIST_FLAG](state, val) {
    state.scrollingList = val;
  },
  [types.TOGGLE_POSITION_GROUP](state, val) {
    if (val === null) {
      state.showPositionGroup = !state.showPositionGroup;
      return;
    }
    state.showPositionGroup = val;
  },
  [types.SET_PROFIT_OPTION](state, index) {
    state.defaultProfitBtnIndex = index;
  },
  [types.SET_LOSS_OPTION](state, index) {
    state.defaultLossBtnIndex = index;
  },
};

export const actions = {
  setMarketsSearchHistory({ commit }, val) {
    commit(types.SET_MARKETS_SEARCH_HISTORY, val);
  },
  setActivityPointsBalance({ commit }, val) {
    commit(types.SET_ACTIVITY_POINTS_BALANCE, val);
  },
  setShowConnectModal({ commit }, val) {
    commit(types.SET_SHOW_CONNECT_MODAL, val);
  },
  setFirstAppLoaded({ commit }, val) {
    commit(types.SET_FIRST_APP_LOADED, val);
  },
  setRemoveAllPositionsStatus({ commit }, val) {
    commit(types.SET_REMOVE_ALL_POSITIONS_STATUS, val);
  },
  setShowBackControls({ commit }, val) {
    if (document.body.clientWidth < 1024) {
      commit(types.SET_SHOW_BACK_CONTROLS, val);
    }
  },
  setScrollingListFlag({ commit }, val) {
    commit(types.SET_SCROLLING_LIST_FLAG, val);
  },
  togglePositionGroup({ commit }, val) {
    commit(types.TOGGLE_POSITION_GROUP, val);
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};
