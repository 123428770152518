import store from '@/store';
import { WalletNames } from '@/wallets/common';
import router from '@/router';
import { waitUntil } from '@/helpers/retry';
import { connApp } from '@/helpers/signalR';
import { hideSplash, showSplash } from '@/helpers/splash';

const getInitialState = () => ({
  isLoggingOut: false,
  pendingProviderName: null,
  isLoggedIn: false,
  dxsAuthData: null,
  authFinished: false,
});

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  isAuthFinished: (state) => state.authFinished,

  pendingProviderName: (state) => state.pendingProviderName,

  provider: (state, getters) =>
    getters['isLoggedIn'] ? getters['account']?.provider : null,

  isFiorin: (state, getters) => getters['provider'] === WalletNames.fiorin,

  isFiorinMode: (state, getters) =>
    !getters['isLoggedIn'] || getters['isFiorin'],

  isFiorinAsProxy: (state, getters, rootState, rootGetters) =>
    getters['isFiorin'] && rootGetters['fiorin/isFiorinAsProxy'],

  isHandCash: (state, getters) => getters['provider'] === WalletNames.handCash,

  isDotWallet: (state, getters) =>
    getters['provider'] === WalletNames.dotWallet,

  // do not check isLoggedIn here
  dxsAuthData: (state) => state.dxsAuthData,

  account: (state, getters, rootState, rootGetters) => {
    if (!getters['isLoggedIn']) {
      return null;
    }

    let activeAccount = rootGetters['accounts/activeAccount'];
    return activeAccount;
  },

  userId: (state, getters) =>
    getters['isLoggedIn'] ? state.dxsAuthData?.userId : null,
};

const mutations = {
  SET_PENDING_PROVIDER_NAME(state, value) {
    state.pendingProviderName = value;
  },

  setLoggedIn(state, value) {
    state.isLoggedIn = value;
  },

  setDxsAuthData(state, value) {
    state.dxsAuthData = value;
  },

  setLoggingOut(state, value) {
    state.isLoggingOut = value;
  },
  SetAuthFinished(state, value) {
    state.authFinished = value;
  },
};

let actions = {
  async initAuth({ state, getters, commit, dispatch, rootGetters }) {
    if (state.isLoggingOut) {
      await dispatch('clear');
      commit('setLoggingOut', false);
      return;
    }

    const theme = rootGetters['settings/theme'];
    console.debug('#initAuth #auth, state:', state, 'theme:', theme);
    window.showState();

    const connected = await connectPendingProvider(
      state.pendingProviderName,
      theme
    );

    commit('SET_PENDING_PROVIDER_NAME', null);
    if (connected) {
      return;
    }

    // account should be taken before the cleanup
    const account = getters['account'];
    if (!state.isLoggedIn) {
      await dispatch('clear');
      return;
    }

    if (!account) {
      console.debugError(
        'No active account found #initAuth #auth, activeAccountId:',
        state.activeAccountId
      );

      await dispatch('clear');

      return;
    }

    if (
      account.provider === WalletNames.fiorin ||
      account.provider === WalletNames.handCash
    ) {
      try {
        showSplash(theme);
        if (account.provider === WalletNames.fiorin) {
          await dispatch('fiorin/initAuth', account, { root: true });
        }
        if (account.provider === WalletNames.handCash) {
          await dispatch('handcash/initAuth', account, { root: true });
        }
      } catch (e) {
        console.debugError(
          'Error when waitForAuthStatus for #initAuth #auth',
          e
        );

        await dispatch('clear');

        hideSplash();

        return;
      }

      return;
    }

    if (
      account.provider === WalletNames.dotWallet
      //&& !localStorage.getItem('showDWUsers')
    ) {
      // showDWUsers = true;
      // todo: test
      // if (dwAuthCode) {
      //   const dotwalletWallet = new DotwalletConnector({ code: dwAuthCode });
      //   store.dispatch('connectors/setActiveConnect', dotwalletWallet);
      // }
      // const dotwalletWallet = new DotwalletConnector(activeConnect);
      // await store.dispatch('connectors/setActiveConnect', dotwalletWallet);
      // dotwalletWallet.connect()
      // if (activeAccount.authorizeCode) {
      //   await activeConnect.authorizeCode(activeConnect.code);
      // }

      return;
    }

    await dispatch('clear');

    console.debugError(
      'Unsupported provider #initAuth',
      account.provider,
      'account:',
      account
    );
  },

  async startLogin({ commit }, { provider, data }) {
    console.debug('#auth  startAuth', provider, data);

    if (!provider) {
      throw new Error('No provider passed to startAuth');
    }

    commit('SET_PENDING_PROVIDER_NAME', provider);

    if (provider === WalletNames.fiorin) {
      await store.dispatch(
        'fiorin/startLogin',
        {
          options: data,
        },
        {
          root: true,
        }
      );
    } else if (provider === WalletNames.handCash) {
      await store.dispatch('handcash/startLogin', data, {
        root: true,
      });
    } else {
      console.debugError('Unsupported provider #startAuth', provider);
    }
  },

  async switchAccount({ dispatch, rootGetters }, login) {
    console.debug('#switchAccount #auth login:', login);

    const accounts = rootGetters['accounts/accounts'];

    const foundAccount = accounts.find((acc) => acc.userName.startsWith(login));
    if (!foundAccount) {
      console.debugError(
        `Account not found #switchAccount #auth, value: ${login}, accounts: ${JSON.stringify(
          accounts
        )}`
      );
      return null;
    } else {
      console.debug(
        'Account found #switchAccount #auth, value: ',
        foundAccount
      );
    }

    let moduleName;
    switch (foundAccount.provider) {
      case WalletNames.fiorin:
        moduleName = 'fiorin';
        break;
      case WalletNames.handCash:
        moduleName = 'handcash';
        break;
      default:
        console.debugError(
          `Unsupported provider #switchAccount #auth, value: ${foundAccount.provider}, login: ${login}`
        );
        return;
    }

    console.debug('#switchAccount #auth  getWalletClient', moduleName);

    await dispatch(moduleName + '/switchAccount', foundAccount, {
      root: true,
    });
  },

  async onAuthenticated({ commit, dispatch }, account) {
    console.debug('#onAuthenticated  #auth #actions');

    if (!account) {
      const error = 'No account provided #auth #actions #authenticated';
      console.debugError(error);
      throw new Error(error);
    }

    await dispatch('accounts/addAccount', account, { root: true });

    // it passes token and provider to socket and restart it
    await updateSocket(account);

    // set dxsAuthData

    const settings = await connApp.invoke('GetUser');

    const authInfo = {
      provider: settings?.auth?.provider,
      providerId: settings?.auth?.providerId,
      userName: settings?.auth?.userName,
      userId: settings?.user?.id,
    };

    if (authInfo.userId) {
      commit('setDxsAuthData', authInfo);
    } else {
      console.debugError(
        'No userId returned from GetUser #connectors #actions #authenticated'
      );
    }

    // this block should go after the dxsAuthData is set
    // the custom module handlers should be used after this block, since they use atLeast isFiorinMode
    {
      await dispatch('accounts/setActiveAccountId', account.id, { root: true });
      commit('SET_PENDING_PROVIDER_NAME', null);
      commit('setLoggedIn', true);
    }

    try {
      await Promise.all([
        dispatch('settings/onAuthenticated', settings, {
          root: true,
        }),
        //dxsAuthData is used here:
        dispatch('user/onAuthenticated', null, { root: true }),
        dispatch('session/onAuthenticated', null, { root: true }),
      ]);
    } catch (e) {
      console.debugError('Error when onAuthenticated', e);
    }

    hideSplash();
    closeLoginModal();

    commit('SetAuthFinished', true);

    // only for the js scripts to work when app is not loaded,
    // not to deserialized whole vuex
    localStorage.setItem('dxsLoggedIn', 'true');
    localStorage.setItem('dxsEverLoggedIn', 'true');
  },

  async logout({ dispatch, getters, commit }) {
    commit('setLoggingOut', true);
    let executed = false;

    await dispatch('wallet/logout', null, { root: true })
      .then(() => (executed = true))
      .catch((e) => {
        console.debugError(
          '#logout error when wallet/logout',
          JSON.stringify(e)
        );
        executed = true;
      });

    await waitUntil('#logout wait for isLoggedOut', 5_000, 100, () => executed);

    if (getters['isLoggedIn']) {
      console.debugError('Logout failed, but we clear everything #logout');
      // await dispatch('onLogout');
    }
    // [Vadim] I commented this line because it's not clear why it's needed
    // dispatch('session/updateInfo', 'USD', { root: true });
  },

  async onLogout({ dispatch, commit }) {
    console.debug('#onLogout #auth #store');

    await dispatch('clear');
    commit('setLoggingOut', false);

    await customRedirect();
    window.location.reload();
  },

  async clear({ commit, dispatch }) {
    commit('setLoggedIn', false);
    localStorage.removeItem('dxsLoggedIn');
    commit('setDxsAuthData', null);
    await dispatch('wallet/clear', null, { root: true });
    await dispatch('accounts/clear', null, { root: true });
  },
};

const customRedirect = async () => {
  console.debug('#customRedirect #connectors');
  const href = router.currentRoute.value?.href || '/';

  if (['index?code', 'authToken'].some((part) => href.includes(part))) {
    await router.push('/');
  } else {
    await router.push(href);
  }
};

function closeLoginModal() {
  // [Vadim] not sure why, it's works without it (at least for fiorin)
  window.document.dispatchEvent(
    new CustomEvent('closeLogin', { bubbles: true })
  );
}

async function updateSocket(account) {
  console.debug('Changing auth #auth #actions #changeAuth');

  connApp.setAccessData(account.provider, account.accessToken);

  await connApp.restart();
}

async function connectPendingProvider(pendingProviderName, theme) {
  if (!pendingProviderName) {
    return false;
  }
  if (pendingProviderName === WalletNames.fiorin) {
    // nothing to do, just ignore
    return false;
  }
  if (pendingProviderName === WalletNames.handCash) {
    console.debug('#auth  connectPendingProvider #handCash');

    try {
      showSplash(theme);
      return await store.dispatch('handcash/completeConnect', null, {
        root: true,
      });
    } catch (e) {
      console.debugError('Error when connect HandCash', e);
      // even in case of error, the already connected account will be used, if any
      return false;
    }
  } else {
    console.debug('Unsupported pendingProviderName #auth', pendingProviderName);
  }

  return false;
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
  cachedPaths: [
    'auth.isLoggedIn',
    'auth.isLoggingOut',
    'auth.pendingProviderName',
    'auth.dxsAuthData',
  ],
};
