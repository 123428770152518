import userStore, { cachedPaths as cachedPathsUser } from './store';
import relayXStore, { cachedPaths as cachedPathsRelayX } from './store/relayX';
import dotWalletStore from './store/dotWallet';
import applyRouter from './router';

export default function module({ store, router, options }) {
  if (!store || !router) {
    return;
  }

  store.registerModule('relayx', relayXStore);
  store.registerModule('dotwallet', dotWalletStore);
  store.registerModule('user', userStore);
  options.cachedPaths = options.cachedPaths.concat(
    [...cachedPathsUser].map((item) => 'user.' + item),
    [...cachedPathsRelayX].map((item) => 'relayx.' + item)
  );

  applyRouter({ store, router });
  return options;
}
